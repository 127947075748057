import React from 'react';
import {
  Cluster,
  ImageWrapper,
  Notification,
  Paragraph,
  Spinner,
  Status,
  Table,
  TdLink,
  Template,
} from '@pluto-tv/assemble';
import {IVodCollection} from 'models/vodCollections';
import vodCollectionRoutes from 'routes/programming.routes';

interface IVodCollectionTableProps {
  id: string;
  rows: Partial<IVodCollection>[];
  handleReorderOnDrop?: (_from: string, _to: string, fromIndex: number[], toIndex: number) => void;
  selectedRows?: Partial<IVodCollection>[];
  setSelectedRows?: (rows: Partial<IVodCollection>[]) => void;
}

const VodCollectionTable = React.memo(
  ({id, rows, handleReorderOnDrop, selectedRows, setSelectedRows}: IVodCollectionTableProps) => {
    const reorderTableProps = React.useMemo(
      () => ({
        onDrop: (fromKey: string, toKey: string, fromIndexes: number[], dropIndex: number) =>
          handleReorderOnDrop?.(fromKey, toKey, fromIndexes, dropIndex),
        draggable: true,
        dragKey: 'reorderVodCollectionList',
        dropKeys: ['reorderVodCollectionList'],
      }),
      [handleReorderOnDrop],
    );

    const multiselectTableProps = React.useMemo(
      () => ({
        selectable: 'multiple' as const,
        predicate: 'id' as keyof IVodCollection,
        selected: selectedRows,
        onSelect: row => setSelectedRows?.(row),
      }),
      [selectedRows, setSelectedRows],
    );

    const tableProps = React.useMemo(
      () =>
        ({
          // loading={loadingData}
          fixedHeader: true,
          wrapContent: true,
          id: id,
          rows: rows,
          ...(handleReorderOnDrop ? reorderTableProps : {}),
          ...(setSelectedRows ? multiselectTableProps : {}),
          cols: [
            {
              label: 'Collection Name',
              sortable: false,
              colMinWidth: '20rem',
              transform: row => (
                <Cluster wrap={false} space='small' align='center'>
                  <ImageWrapper width='3rem' height='3rem' src={row.imageFeatured?.path} alt={row.name} />
                  <TdLink
                    row={row}
                    title={row.name || ''}
                    url={vodCollectionRoutes.paths.vodCollectionEditProgramPage.replace(':id', row?.id || '')}
                  />
                </Cluster>
              ),
            },
            {
              label: 'Display Name',
              transform: row => row?.displayName,
              sortable: false,
              colMinWidth: '10rem',
            },
            handleReorderOnDrop
              ? {}
              : {
                  label: 'Published',
                  colMinWidth: '9.5rem',
                  transform: row => (
                    <Status
                      label={row.enabled ? 'Published' : 'Unpublished'}
                      state={row.enabled ? 'success' : 'neutral'}
                    />
                  ),
                },
            {
              label: 'Kids',
              sortable: false,
              colMinWidth: '6rem',
              transform: row => (
                <Status label={row.kidsMode ? 'Yes' : 'No'} state={row.kidsMode ? 'success' : 'neutral'} />
              ),
            },
            {
              label: 'Office Only',
              sortable: false,
              colMinWidth: '6rem',
              transform: row => (
                <Status
                  label={row.plutoOfficeOnly ? 'Yes' : 'No'}
                  state={row.plutoOfficeOnly ? 'success' : 'neutral'}
                />
              ),
            },
          ],
        } as any),
      [handleReorderOnDrop, id, multiselectTableProps, reorderTableProps, rows, setSelectedRows],
    );

    return (
      <Table<Partial<IVodCollection>> {...tableProps}>
        <Template label='loading'>
          <Cluster space='small' align='center'>
            <Spinner />
            <Paragraph>Loading VOD Collection List</Paragraph>
          </Cluster>
        </Template>
        <Template label='empty'>
          <Notification type='warning'>There are no VOD Collections currently available.</Notification>
        </Template>
      </Table>
    );
  },
);

export default VodCollectionTable;
