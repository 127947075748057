import React from 'react';
import {IListPayload} from 'models/generic';

export interface ISearchCarouselData<T, U> {
  data: IListPayload<T>;
  currentData: IListPayload<T>;
  isFetching: boolean;
  isError: boolean;
  error: Error;
  isLoading: boolean;
  search: (params: U) => any;
}

const useSearch = <T, U extends Record<string, unknown>>(
  useLazyFindQueryHook: () => [
    (params: U) => any,
    {
      data: IListPayload<T>;
      currentData: IListPayload<T>;
      isLoading: boolean;
      isFetching: boolean;
      isError: boolean;
      error: Error;
      isSuccess: boolean;
    },
  ],
): ISearchCarouselData<T, U> => {
  const [trigger, {currentData, isLoading, isFetching, isError, error}] = useLazyFindQueryHook();

  const doSearch = React.useCallback(
    (params: U) => {
      trigger({...params, time: new Date().getTime()}).unwrap();
    },
    [trigger],
  );

  return {
    data: currentData,
    currentData,
    isLoading,
    isFetching,
    isError,
    error,
    search: doSearch as any,
  };
};

export default useSearch;
