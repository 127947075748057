import * as React from 'react';
import {useDeleteMutation} from 'features/mainCategories/mainCategoriesApi';
import {
  MainCategoriesDeleteError,
  MainCategoriesHasAssociatedEntitiesDeleteError,
} from 'components/mainCategoriesList/hooks/customErrors';

export const useDelete = (): {onDelete: (id: string) => Promise<void>} => {
  const [deleteMainCategory] = useDeleteMutation();

  const onDelete = React.useCallback(
    async (id: string) => {
      try {
        await deleteMainCategory(id).unwrap();
      } catch (e: any) {
        if (
          e.status === 400 &&
          e.data === 'To delete a main category, you must first un-assign its channels and VOD categories.'
        ) {
          throw new MainCategoriesHasAssociatedEntitiesDeleteError();
        }
        throw new MainCategoriesDeleteError();
      }
    },
    [deleteMainCategory],
  );

  return {onDelete};
};
