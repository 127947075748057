import * as React from 'react';
import {Box, Button, Cluster, Dialog, ITableCol, Table, Template, TSize} from '@pluto-tv/assemble';

interface IReorderPanelProps<T> {
  items: T[];
  onClose: () => void;
  onApplyOrder: (hubCarousels: T[]) => void;
  onDrop: (fromIndex: number[], toIndex: number, items: T[]) => void;
  columns: ITableCol<T>[];
  header: React.ReactNode;
  width?: TSize;
  actions?: boolean;
  customBodyComponent?: React.ReactNode;
  useCustomBodyComponent?: boolean;
  contentId?: string;
  isLoading?: boolean;
  isSaveDisabled?: boolean;
  scrollTrigger?: string;
}

const ReorderPanel = <T extends {id: string}>({
  items,
  columns,
  onClose,
  onApplyOrder,
  onDrop,
  header,
  contentId = 'mainContent',
  width = '650px',
  customBodyComponent,
  useCustomBodyComponent = false,
  isLoading,
  isSaveDisabled,
  scrollTrigger,
}: IReorderPanelProps<T>): JSX.Element => {
  const [selectedRows, setSelectedRows] = React.useState<T[]>([]);
  const tableWrapperRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (tableWrapperRef.current) {
      requestAnimationFrame(() => {
        tableWrapperRef.current?.firstElementChild?.scrollTo(0, 0);
      });
    }
  }, [scrollTrigger]);

  const handleApplyOrder = () => {
    onApplyOrder(items);
    onClose();
  };

  const handleDrop = (_from: string, _to: string, fromIndex: number[], toIndex: number) => {
    onDrop(fromIndex, toIndex, items);
  };

  const handleSelect = React.useCallback((rows: T[]) => {
    setSelectedRows(rows);
  }, []);

  return (
    <Dialog isOpen={true} onClose={onClose} height='50rem' width={width} id='reorderPanel'>
      <Template label='header'>{header}</Template>
      <Template label='body'>
        {useCustomBodyComponent && customBodyComponent ? (
          customBodyComponent
        ) : (
          <Box id={contentId} height='100%' ref={tableWrapperRef}>
            <Table<T>
              virtual
              onDrop={handleDrop}
              draggable={true}
              predicate='id'
              loading={isLoading}
              onSelect={handleSelect}
              dragKey='listReorder'
              dropKeys={['listReorder']}
              id='listReorder'
              selectable='multiple'
              wrapContent={true}
              cols={columns}
              rows={items}
              selected={selectedRows}
            />
          </Box>
        )}
      </Template>
      <Template label='footer'>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button id='cancelBtn' ghost={true} size='small' onClick={onClose}>
              Cancel
            </Button>
            <Button
              id='saveOrderBtn'
              type='primary'
              size='small'
              onClick={handleApplyOrder}
              state={isSaveDisabled ? 'disabled' : ''}
            >
              Save Order
            </Button>
          </Cluster>
        </Cluster>
      </Template>
    </Dialog>
  );
};

export default ReorderPanel;
