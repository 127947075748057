import {ITableCol, Status, Table, TdLink, TSize} from '@pluto-tv/assemble';
import {TableActions} from 'components/tableActions';
import * as React from 'react';
import routes from 'routes/programming.routes';
import {TSortDirection} from 'models/generic';
import {INIT_PARAMS} from 'components/mainCategoriesList/components/MainCategoriesList';
import {IMainCategory, IMainCategorySearch} from 'models/mainCategories';
import {useAppPermissions} from 'app/permissions';

interface IMainCategoriesTableProps {
  isSearchActive: boolean;
  items?: IMainCategory[];
  isLazyLoading: boolean;
  isError: boolean;
  isLoading: boolean;
  searchParams?: IMainCategorySearch;
  onSearch: (params: IMainCategorySearch) => void;
  lazyLoad: () => void;
  onEdit: (id: string) => void;
  onDelete: (mainCategory: IMainCategory) => void;
}

const TABLE_COLUMN_NAME = {
  'Display Name': 'name',
  'Active Region': 'activeRegion',
  'Office Only': 'plutoOfficeOnly',
  Hidden: 'hideMainCategory',
} as const;

const TABLE_COLUMN_VALUE = {
  name: 'Display Name',
  activeRegion: 'Active Region',
  plutoOfficeOnly: 'Office Only',
  hideMainCategory: 'Hidden',
} as const;

const MainCategoriesTable = ({
  items,
  isLazyLoading,
  isError,
  isLoading,
  searchParams,
  onSearch,
  lazyLoad,
  onEdit,
  onDelete,
}: IMainCategoriesTableProps): JSX.Element => {
  const {ableTo} = useAppPermissions();

  const sortParams = searchParams?.sort || INIT_PARAMS.sort;

  const sortColumn = sortParams[0].split(':')[0] as keyof typeof TABLE_COLUMN_NAME;
  const sortDirection = sortParams[0].split(':')[1] as TSortDirection;

  const columns = React.useMemo(
    () => [
      {
        label: 'Display Name',
        colWidth: '30rem' as TSize,
        sortable: true,
        transform: row => (
          <TdLink row={row} title={row.name} url={routes.paths.mainCategoriesEditDetailsPage.replace(':id', row.id)} />
        ),
      },
      {
        label: 'Active Region',
        sortable: true,
        transform: row => row.activeRegion?.toUpperCase() || 'N/A',
        colMinWidth: '9.6875rem' as TSize,
      },
      {
        sortable: true,
        label: 'Office Only',
        transform: (row: IMainCategory) => (
          <Status label={row.plutoOfficeOnly ? 'Yes' : 'No'} state={row.plutoOfficeOnly ? 'success' : 'neutral'} />
        ),
        colMinWidth: '9.5rem' as TSize,
      },
      {
        sortable: true,
        label: 'Hidden',
        colMinWidth: '9.5rem' as TSize,
        transform: row => (
          <Status label={row.hideMainCategory ? 'Yes' : 'No'} state={row.hideMainCategory ? 'success' : 'neutral'} />
        ),
      },
      ...(ableTo('MAIN_CATEGORIES_EDIT') || ableTo('MAIN_CATEGORIES_DELETE')
        ? [
            {
              label: 'Actions',
              colWidth: '6.25rem',
              transform: row => (
                <TableActions
                  row={row}
                  icons={ableTo('MAIN_CATEGORIES_EDIT') ? ['edit'] : []}
                  deleteOption={ableTo('MAIN_CATEGORIES_DELETE')}
                  altTitle={row.name}
                  onClick={(row, icon) => {
                    switch (icon) {
                      case 'edit':
                        onEdit(row.id);
                        break;
                      case 'delete':
                        onDelete(row);
                        break;
                      default:
                    }
                  }}
                />
              ),
            } as ITableCol<IMainCategory>,
          ]
        : []),
    ],
    [ableTo, onDelete, onEdit],
  );

  const changeSort = (columnName: keyof typeof TABLE_COLUMN_NAME) => {
    const selectedCol: string = TABLE_COLUMN_NAME[columnName];
    const newSort: TSortDirection = selectedCol === sortColumn && sortDirection === 'asc' ? 'dsc' : 'asc';

    onSearch({
      ...searchParams,
      sort: [`${selectedCol}:${newSort}`],
    });
  };

  return (
    <Table<IMainCategory>
      id='mainCategoriesTable'
      virtual
      fixedHeader={true}
      lazyLoading={isLazyLoading}
      loading={isLoading}
      wrapContent={true}
      onLazyLoad={lazyLoad}
      lazyLoadingMsg='Loading more items...'
      lazyLoadScrollOffset={5}
      emptyMsg={
        isError
          ? 'There was an error retrieving main categories. Please try again later.'
          : !items?.length && !isLoading
          ? 'No main categories found.'
          : undefined
      }
      sortDir={sortDirection}
      sortCol={sortColumn ? TABLE_COLUMN_VALUE[sortColumn] : undefined}
      cols={columns}
      onSort={colName => changeSort(colName as keyof typeof TABLE_COLUMN_NAME)}
      rows={items}
    />
  );
};

export default MainCategoriesTable;
