import * as React from 'react';
import {
  Button,
  Cluster,
  Cover,
  Divider,
  FormItem,
  Heading,
  Icon,
  Template,
  TextInput,
  Stack,
  useValidateForm,
  Select,
  ISelectOption,
} from '@pluto-tv/assemble';
import {mainCategoriesValidator, IMainCategoriesSearch} from 'views/programming/mainCategories/validators';
import {useUserRegions} from 'helpers/useUserRegions';
import {useMainCategoriesSearchlLazyLoadProvider} from 'components/mainCategoriesList/providers/MainCategoriesSearchLazyProvider';
import {IMainCategorySearch} from 'models/mainCategories';
import {GenericFavoriteSearch} from 'components/favoriteSearch/GenericFavoriteSearch';
import {TSortDirection} from 'models/generic';
import {IUserSearch} from 'models/users';

const mainCategoriesEntityName = 'mainCategories';

const officeOnlyOptions: ISelectOption[] = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

export const categoryNameId = 'categoryName';

interface IMainCategoriesSearchProps {
  onSearch: (search: IMainCategorySearch) => void;
  onClear: () => void;
  setIsExpanded: (value: boolean) => void;
}

const MainCategoriesSearch = ({onSearch, onClear, setIsExpanded}: IMainCategoriesSearchProps): JSX.Element => {
  const {activeRegions} = useUserRegions();
  const {isLoading, searchParams} = useMainCategoriesSearchlLazyLoadProvider();
  const {model, onChange, form, onBlur, setFields, reset, getValidation} = useValidateForm<IMainCategoriesSearch>(
    mainCategoriesValidator,
    'ask',
  );

  const [userSearch, setUserSearch] = React.useState<IUserSearch<IMainCategorySearch, never>>();

  const {sortCol, sortDir} = React.useMemo(() => {
    const sortCol = searchParams.sort?.[0].split(':')[0] || 'order';
    const sortDir = (searchParams.sort?.[0].split(':')[1] || 'asc') as TSortDirection;
    return {sortCol, sortDir};
  }, [searchParams.sort]);

  React.useEffect(() => {
    setUserSearch(prevUserSearch => {
      if (prevUserSearch) {
        return {
          ...prevUserSearch,
          sortCol,
          sortDir,
        };
      }
      return prevUserSearch;
    });
  }, [sortCol, sortDir]);

  const handleSearch = async () => {
    const searchFields = await getValidation();
    const {model} = searchFields;

    const searchParams: IMainCategorySearch = {};
    if (model.name) searchParams.name = model.name;
    if (model.activeRegion) {
      searchParams.region = model.activeRegion.toLowerCase();
    } else {
      searchParams.region = activeRegions.map(ar => ar.code.toLowerCase());
    }
    if (model.officeOnly !== undefined) searchParams.officeOnly = model.officeOnly;

    if (Object.keys(searchParams).length > 0) {
      onSearch(searchParams);
    }
  };

  const handleSearchSelected = (userSearch: IUserSearch<IMainCategorySearch, never>) => {
    setUserSearch(userSearch);
    const searchActiveRegion = (userSearch.model.activeRegion || '') as string;
    const currentSearchParams: Record<string, any> = {
      name: userSearch.model.name,
      officeOnly: userSearch.model.officeOnly,
      sort: [`${userSearch.sortCol}:${userSearch.sortDir}`],
    };

    if (searchActiveRegion) {
      currentSearchParams.region = searchActiveRegion.toLowerCase();
    }

    onSearch(currentSearchParams);

    setFields({
      name: userSearch.model.name,
      activeRegion: searchActiveRegion,
      officeOnly: userSearch.model.officeOnly,
    });
  };

  const handleSearchCreated = (userSearch: IUserSearch<IMainCategorySearch, never>) => {
    setUserSearch(userSearch);
  };

  const handleClear = async () => {
    reset();
    onClear();
    setUserSearch(undefined);
  };

  return (
    <Cover scrolling={true} gutter='medium'>
      <Template label='header'>
        <Stack space='medium'>
          <Cluster align='center' justify='space-between'>
            <Icon icon='tune' space='small' size='large' iconAlign='center'>
              <Heading level='h4'>Search Filters</Heading>
            </Icon>
            <Icon icon='collapseleft' id='closeFilters' size='large' onClick={() => setIsExpanded(false)} />
          </Cluster>
          <GenericFavoriteSearch<IMainCategorySearch, never>
            searchModel={{
              name: '',
              model: model,
              sortCol: sortCol,
              sortDir: sortDir,
            }}
            getValidation={getValidation as unknown as () => Promise<Partial<IMainCategorySearch>>}
            searchSelected={userSearch}
            onSearchSelected={handleSearchSelected}
            onSearchCreated={handleSearchCreated}
            onClearSelection={onClear}
            entity={mainCategoriesEntityName}
          />
          <Divider color='graphite' />
        </Stack>
      </Template>
      <Template label='cover'>
        <form
          onSubmit={ev => {
            ev.preventDefault();
            handleSearch();
          }}
        >
          <Stack space='small'>
            <FormItem
              {...form.name}
              onBlur={() => {
                onBlur('name');
              }}
            >
              <TextInput
                id={categoryNameId}
                clearable={true}
                placeholder='Name'
                value={model.name || ''}
                onChange={val => {
                  onChange('name', val);
                }}
              />
            </FormItem>

            <FormItem {...form.activeRegion}>
              <Select
                id='activeRegion'
                clearable={true}
                placeholder='Select Active Region'
                value={
                  {
                    value: model.activeRegion,
                    label: activeRegions.find(ar => ar.code === model.activeRegion)?.name,
                  } as any
                }
                onChange={val => {
                  setFields({
                    activeRegion: val?.value,
                  });
                }}
                options={activeRegions.map(ar => ({
                  label: `${ar.name} (${ar.code})`,
                  value: ar.code,
                }))}
                predicate='value'
              />
            </FormItem>
            <FormItem {...form.officeOnly}>
              <Select
                id='officeOnly'
                clearable={true}
                placeholder='Select Office Only'
                value={officeOnlyOptions.find(oo => oo.value === model.officeOnly) || undefined}
                options={officeOnlyOptions}
                onChange={value => {
                  setFields({
                    officeOnly: value?.value,
                  });
                }}
              />
            </FormItem>
          </Stack>
        </form>
      </Template>
      <Template label='footer'>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button id='clearButton' ghost={true} onClick={handleClear} state={isLoading ? 'disabled' : ''}>
              Clear
            </Button>
            <Button id='searchButton' type='primary' onClick={handleSearch} state={isLoading ? 'thinking' : ''}>
              Search
            </Button>
          </Cluster>
        </Cluster>
      </Template>
    </Cover>
  );
};

export default MainCategoriesSearch;
