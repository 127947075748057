export const getParamsFromModel = <T extends Partial<Record<keyof T, unknown>>>(
  searchModel: T,
): {
  [key: string]: string | number;
}[] => {
  return Object.entries(searchModel).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      return [
        ...acc,
        ...value.map(item => ({
          [key]: encodeURIComponent(item),
        })),
      ];
    }

    if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
      return [...acc, {[key]: encodeURIComponent(value)}];
    }
    return acc;
  }, [] as {[key: string]: string | number}[]);
};
