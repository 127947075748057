import * as React from 'react';

import {cloneDeep, orderBy, sortedUniqBy, sortBy, startCase} from 'lodash-es';
import {useHistory} from 'react-router-dom';
import {
  Box,
  Button,
  Cluster,
  ContentBoxes,
  ContentBox,
  ContentBoxColumn,
  Dialog,
  FormItem,
  Grid,
  Icon,
  IOption,
  ITableCol,
  Heading,
  MultiselectDrag,
  Notification,
  Paragraph,
  Popover,
  Select,
  Spinner,
  Stack,
  Status,
  Table,
  Textarea,
  TextInput,
  Template,
  ISelectOption,
  TIcons,
  Toast,
  trimModel,
  useValidateForm,
  States,
  Click,
} from '@pluto-tv/assemble';

import channelRoutes from 'routes/programming.routes';

import {TableActions} from 'components/tableActions';
import {useUserRegions} from 'helpers/useUserRegions';
import {useFindQuery as useFindPartnersQuery} from 'features/partners/partnersApi';
import {useFindQuery as useFindDevices} from 'features/devices/devicesApi';

import {useAppPermissions} from 'app/permissions';
import {default as dmaDistributionCodes, sortCodes} from 'helpers/dmaDistributionCodes';

import CrudError from 'components/crudError';
import CustomReferenceForm from 'components/custom-reference-form';
import SamsungServiceForm from 'components/samsung-service-form';

import {useFindQuery as useFindMainCategoriesQuery} from 'features/mainCategories/mainCategoriesApi';
import {useFindQuery as useFindLegacyCategoriesQuery} from 'features/channelCategories/channelCategoriesApi';

import {IMainCategory} from 'models/mainCategories';
import {INestedChannelProps} from '../nestedPropsInterface';

import {useLanguageList} from 'helpers/useLanguageList';

import {useInsertMutation} from 'features/channels/channelsApi';
import {IChannel, IChannelCustomReference, ISamsungChannelProperties} from 'models/channels';
import {channelCopyDistributionCreateValidator} from '../../validators';
import createDictionary from 'helpers/createDictionary';
interface ICustomReferencePopover {
  add?: boolean;
  [key: number]: boolean;
}
interface IChannelProps extends INestedChannelProps {
  dirtyFields: any;
}

interface IMultidragState {
  state: States;
  labelOne: string;
  labelTwo: string;
}

const hasCustomReferenceType = (customReferenceTypes: IChannelCustomReference[], type: string) => {
  return Boolean(customReferenceTypes.find(customReferenceType => customReferenceType.type === type));
};

export default ({model, setFields, form, pristineModel}: IChannelProps): JSX.Element => {
  const history = useHistory();
  const {ableTo, permissions} = useAppPermissions();

  const hasDeliveryPartnerCustomRefSaved = hasCustomReferenceType(
    pristineModel.customReferences || [],
    'deliveryPartner',
  );

  const {data: partners, isFetching: isFetchingPartners} = useFindPartnersQuery();
  const {data: deviceList, isFetching: devicesLoading} = useFindDevices({
    offset: 0,
    limit: 250,
    sort: 'name:asc',
  });
  const {activeRegions, territories, isFetching: isRegionsFetching} = useUserRegions();

  const {
    data: mainCategories,
    isError: isMainCategoriesError,
    error: mainCategoriesError,
    isFetching: isMainCategoriesFetching,
  } = useFindMainCategoriesQuery();

  const {
    data: legacyCategories,
    isError: isLegacyCategoriesError,
    error: legacyCategoriesError,
    isFetching: isLegacyCategoriesFetching,
  } = useFindLegacyCategoriesQuery();

  const {languageList, isLanguagesError, languagesError, isLanguagesFetching, searchLanguages} = useLanguageList();

  const [customReferencePopoverOpen, setCustomReferencePopoverOpen] = React.useState<ICustomReferencePopover>({});
  const [territoriesDictionary, setTerritoriesDictionary] = React.useState({});
  const [partnerDictionary, setPartnerDictionary] = React.useState({});
  const [platforms, setPlatforms] = React.useState<ISelectOption[]>([]);
  const [samsungServicePopoverOpen, setSamsungServicePopoverOpen] = React.useState<ICustomReferencePopover>({});
  const [isCloneChannelOpen, setIsCloneChannelOpen] = React.useState(false);
  const [mainCategoriesList, setMainCategoriesList] = React.useState<ISelectOption[]>([]);
  const [isCreating, setIsCreating] = React.useState(false);
  const [regionFilterState, setRegionFilterState] = React.useState<IMultidragState>();
  const [distributionState, setDistributionState] = React.useState<IMultidragState>();

  const handleFilteredList = (mainCategoriesArray: IMainCategory[], activeRegion: string) => {
    const list = mainCategoriesArray
      .filter((mc: IMainCategory) => mc.activeRegion === activeRegion)
      .map((mc: IMainCategory) => ({label: mc.name, value: mc.id, order: mc.order}));

    return sortBy(list, 'label');
  };

  const {
    model: cloneDetailsModel,
    onChange: cloneDetailsOnChange,
    form: cloneDetailsForm,
    setFields: setFieldsForCloneDetails,
    setModel: setCloneDetailsModel,
    state: cloneDetailsState,
    onBlur: cloneDetailsOnBlur,
    reset: resetCloneDetailsForm,
  } = useValidateForm<IChannel>(channelCopyDistributionCreateValidator, 'onBlur');

  React.useEffect(() => {
    if (model) {
      const clonedChannel = cloneDeep(model);
      delete clonedChannel.name;
      delete clonedChannel.updatedAt;
      delete clonedChannel.slug;
      setCloneDetailsModel(clonedChannel);
    }
  }, [model, setCloneDetailsModel]);

  const [insertChannel] = useInsertMutation();

  const handleCloneCreate = async (navigateTo = true): Promise<string | undefined> => {
    let channelId: string | undefined;

    try {
      setIsCreating(true);

      const gracenoteIntegration = {
        category: '',
        dynamicClip: undefined,
        enabled: false,
        genre: '',
        seriesType: '',
        subCategory: '',
        subGenre: '',
      };
      const postModel: Partial<IChannel> = {
        name: cloneDetailsModel.name,
        category: cloneDetailsModel.category,
        categories: cloneDetailsModel.categories,
        description: cloneDetailsModel.summary,
        autoScheduleDays: 7,
        gracenoteIntegration,
        tmsid: '',
        activeRegion: cloneDetailsModel.activeRegion,
        activeRegions: cloneDetailsModel.activeRegions,
        tags: [cloneDetailsModel.activeRegion!.toLowerCase()],
        distribution: cloneDetailsModel.distribution,
        regionFilter: cloneDetailsModel.regionFilter,
        supportedChannelCaptionLanguages: cloneDetailsModel.supportedChannelCaptionLanguages,
        metadataLanguage: cloneDetailsModel.metadataLanguage,
        isStitched: cloneDetailsModel.isStitched,
        directOnly: cloneDetailsModel.directOnly,
        visibility: cloneDetailsModel.visibility,
        onDemand: cloneDetailsModel.onDemand,
        type: cloneDetailsModel.type,
        summary: cloneDetailsModel.summary,
        // - deviceExcluded and deviceIncluded are actually distribution
        // - legacyCategory is actually category
        // - shortDescription is actually summary
        // - longDescription is actually description
      };

      const newChannel = await insertChannel(trimModel(postModel, 'name')).unwrap();
      channelId = newChannel.id!;

      const toastMsg = !navigateTo ? (
        `You’ve Cloned ${model.name}`
      ) : (
        <Stack space='xxsmall'>
          <Paragraph>You&apos;ve cloned {model.name}</Paragraph>
          <Click
            underline={true}
            hoverColor='white'
            onClick={() => history.push(channelRoutes.paths.channelEditDetailsPage.replace(':id', channelId as string))}
          >
            View Channel: {postModel.name}
          </Click>
        </Stack>
      );

      Toast.success('Success', toastMsg, 8000);

      setIsCloneChannelOpen(false);
    } catch (e) {
      Toast.error('Error', (e as any).data.message);
    } finally {
      setIsCloneChannelOpen(false);
      setIsCreating(false);
      resetCloneDetailsForm();
    }

    return channelId;
  };

  const handleClonedAndEdit = async () => {
    try {
      const channelId = await handleCloneCreate(false);
      // Timeout is required to remove unmount error and memory leak.
      setTimeout(() => {
        if (channelId) {
          history.push(channelRoutes.paths.channelEditDetailsPage.replace(':id', channelId as string));
        }
      }, 800);
    } catch (e) {}
  };

  React.useEffect(() => {
    const list: ISelectOption[] = handleFilteredList(
      mainCategories?.data || [],
      cloneDetailsModel.activeRegion?.toLowerCase() as string,
    );
    setMainCategoriesList(list);
  }, [model.mainCategories, model.activeRegion, mainCategories, cloneDetailsModel.activeRegion]);

  const handleCustomReferenceClick = (icon: TIcons, index: number) => {
    if (icon === 'delete') {
      const cloned = cloneDeep(model.customReferences);

      if (!cloned) {
        return;
      }

      cloned.splice(index, 1);

      setFields({
        customReferences: cloned.length > 0 ? cloned : undefined,
      });
    } else if (icon === 'edit') {
      setCustomReferencePopoverOpen({[index]: true});
    }
  };

  const handleCustomReferencesUpdate = (customReference: IChannelCustomReference, index = -1) => {
    const cloned = cloneDeep(model.customReferences || []);
    const typeExists = cloned.find((ref, i) => ref.type === customReference.type && i !== index);

    if (!typeExists) {
      if (index >= 0) {
        cloned.splice(index, 1, customReference);
      } else {
        cloned.push(customReference);
      }
    } else {
      Toast.warning('The same Custom Reference type cannot be used more than once. Please update.');
    }

    setFields({
      customReferences: cloned,
    });

    setCustomReferencePopoverOpen({});
  };

  React.useEffect(() => {
    if (territories?.length) {
      setTerritoriesDictionary(createDictionary(territories));
    }
  }, [territories]);

  React.useEffect(() => {
    if (partners?.length) {
      setPartnerDictionary(createDictionary(partners));
    }
  }, [partners]);

  React.useEffect(() => {
    if (deviceList?.data.length) {
      const devices = orderBy(
        (deviceList?.data || []).map(d => ({label: d.platform, value: d.platform})),
        'label',
      );
      const uniqueDevices = sortedUniqBy(devices, 'label');

      setPlatforms(uniqueDevices);
    }
  }, [deviceList]);

  const handleSamsungServiceClick = (icon: TIcons, index: number) => {
    if (icon === 'delete') {
      const cloned = cloneDeep(model.samsungChannelProperties);

      if (!cloned) {
        return;
      }

      cloned.splice(index, 1);

      setFields({
        samsungChannelProperties: cloned.length > 0 ? cloned : undefined,
      });
    } else if (icon === 'edit') {
      setSamsungServicePopoverOpen({[index]: true});
    }
  };

  const handleSamsungServiceUpdate = (samsungChannel: ISamsungChannelProperties, index = -1) => {
    const cloned = cloneDeep(model.samsungChannelProperties || []);

    samsungChannel.channel = model.id;

    if (index >= 0) {
      cloned.splice(index, 1, samsungChannel);
    } else {
      cloned.push(samsungChannel);
    }

    setFields({
      samsungChannelProperties: cloned,
    });

    setSamsungServicePopoverOpen({});
  };

  const getMultiSelectInfo = React.useCallback(
    (field: string): IMultidragState => {
      return {
        state: ableTo('CHANNEL_EDIT') ? (form[field]?.state ? 'error' : '') : 'disabled',
        labelOne: `Include (${model[field]?.include?.length || 0})`,
        labelTwo: `Exclude (${model[field]?.exclude?.length || 0})`,
      };
    },
    [model, form, ableTo],
  );

  React.useEffect(() => {
    if (model.regionFilter) {
      setRegionFilterState(getMultiSelectInfo('regionFilter'));
    }

    if (model.distribution) {
      setDistributionState(getMultiSelectInfo('distribution'));
    }
  }, [model.regionFilter, model.distribution, getMultiSelectInfo]);

  if (isMainCategoriesError || isLegacyCategoriesError || isLanguagesError) {
    const err = mainCategoriesError || legacyCategoriesError || languagesError;
    return <CrudError error={err} />;
  }

  if (
    isRegionsFetching ||
    devicesLoading ||
    isFetchingPartners ||
    isMainCategoriesFetching ||
    isLegacyCategoriesFetching ||
    isLanguagesFetching
  ) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  return (
    <ContentBoxes layout='columns'>
      <ContentBoxColumn>
        <ContentBox title='Distribution Details'>
          <Stack space='xxlarge'>
            <Stack space='xlarge'>
              <Heading level='h4' color='secondary'>
                Active Region
              </Heading>
              <Stack space='xlarge'>
                <FormItem {...form?.activeRegion} state={model.activeRegion ? 'disabled' : ''}>
                  <Select
                    onChange={value => {
                      const activeRegion = value.value;
                      setFields({
                        activeRegion,
                      });
                    }}
                    value={{label: model.activeRegion || '', value: model.activeRegion?.toLowerCase()}}
                    id='activeRegtion'
                    predicate='value'
                    options={activeRegions.map(ar => ({
                      label: `${ar.name} (${ar.code})`,
                      value: ar.code.toLowerCase(),
                    }))}
                  />
                </FormItem>
              </Stack>
            </Stack>
            <Stack space='xlarge'>
              <Heading level='h4' color='secondary'>
                Territories
              </Heading>
              <MultiselectDrag
                clearable={ableTo('CHANNEL_EDIT')}
                permission={permissions.CHANNEL_EDIT}
                stateOne={regionFilterState?.state}
                stateTwo={regionFilterState?.state}
                labelOne={regionFilterState?.labelOne}
                labelTwo={regionFilterState?.labelTwo}
                options={
                  orderBy(
                    (territories || []).map(t => ({label: t.name, value: t.id.toUpperCase()})),
                    'label',
                  ) || []
                }
                valueOne={model.regionFilter?.include?.map(t => ({
                  value: t.toUpperCase(),
                  label: `${territoriesDictionary[t]}`,
                }))}
                valueTwo={model.regionFilter?.exclude?.map(t => ({
                  value: t.toUpperCase(),
                  label: `${territoriesDictionary[t]}`,
                }))}
                onChange={value => {
                  const include = (value?.one || [])
                    .sort((r1, r2) => (r1.label.toUpperCase() > r2.label.toUpperCase() ? 1 : -1))
                    .map(v => v.value);

                  const exclude = (value?.two || [])
                    .sort((r1, r2) => (r1.label.toUpperCase() > r2.label.toUpperCase() ? 1 : -1))
                    .map(v => v.value);

                  const imediateChange: any = {
                    regionFilter: {
                      include,
                      exclude,
                    },
                  };
                  // Ensuring it has no DMA if no US in region filter
                  if (!include.includes('US')) {
                    imediateChange.dmaDistribution = undefined;
                  }
                  setFields(imediateChange);
                }}
                helpTextOne={form?.regionFilter?.helpText}
                searchable={true}
                searchPlaceholder='Search territories'
                onSearch={(options: IOption[], val: any) =>
                  orderBy(
                    (options || []).filter(t => t.label.toLowerCase().startsWith(val.toLowerCase())),
                    'label',
                  ) || []
                }
              />
            </Stack>
            <Stack space='xlarge'>
              <Heading level='h4' color='secondary'>
                Devices
              </Heading>
              <MultiselectDrag
                clearable={ableTo('CHANNEL_EDIT')}
                permission={permissions.CHANNEL_EDIT}
                stateOne={distributionState?.state}
                stateTwo={distributionState?.state}
                labelOne={distributionState?.labelOne}
                labelTwo={distributionState?.labelTwo}
                onChange={value => {
                  const include = (value?.one || []).map(v => v.value).sort();
                  const exclude = (value?.two || []).map(v => v.value).sort();
                  setFields({
                    distribution: {
                      include,
                      exclude,
                    },
                  });
                }}
                options={platforms}
                valueOne={(model.distribution?.include || []).map(d => ({label: d, value: d}))}
                valueTwo={(model.distribution?.exclude || []).map(d => ({label: d, value: d}))}
                helpTextOne={form?.distribution?.helpText}
                searchable={true}
                searchPlaceholder='Search devices'
                onSearch={(options: IOption[], val: any) =>
                  orderBy(
                    (options || []).filter(t => t.label.toLowerCase().startsWith(val.toLowerCase())),
                    'label',
                  ) || []
                }
              />
            </Stack>
            <Cluster justify='space-between' space='xxlarge' align='center'>
              <div></div>
              <Button
                state={ableTo('CHANNEL_EDIT') ? '' : 'disabled'}
                type='primary'
                onClick={() => setIsCloneChannelOpen(true)}
              >
                Clone Distribution Details
              </Button>
            </Cluster>
            <Dialog
              isOpen={isCloneChannelOpen}
              onClose={() => setIsCloneChannelOpen(false)}
              width='45.8125rem'
              height='38.8rem'
            >
              <Template label='header'>
                <Heading level='h2'>Clone Channel</Heading>
              </Template>
              <Template label='body'>
                <Stack space='xxlarge'>
                  <Notification type='info'>
                    Cloning this channel includes copying its Active Region, Territories, and Devices.
                  </Notification>

                  <Stack space='medium'>
                    <FormItem {...cloneDetailsForm.name} onBlur={() => cloneDetailsOnBlur('name')}>
                      <TextInput onChange={value => cloneDetailsOnChange('name', value)} id='title' />
                    </FormItem>
                    <FormItem {...cloneDetailsForm.categories} onBlur={() => cloneDetailsOnBlur('categories')}>
                      <Select
                        searchPlaceholder='Search for main category'
                        onChange={value =>
                          setFieldsForCloneDetails({
                            categories: (value || [])?.map(v => {
                              return {
                                catId: v.value,
                                order: v.order,
                              };
                            }),
                          })
                        }
                        value={
                          cloneDetailsModel.categories?.map(d => ({
                            label: d.name,
                            value: d.catId,
                            order: d.order,
                          })) as ISelectOption[]
                        }
                        id='categories'
                        multiselect={true}
                        clearable={true}
                        addAll={true}
                        predicate='value'
                        searchable={true}
                        onSearch={val =>
                          orderBy(
                            (mainCategoriesList || [])
                              .filter(mc => mc.label.toLowerCase().startsWith(val.toLowerCase()))
                              .map(mc => ({label: mc.label, value: mc.value, order: mc.order}), 'label'),
                          ) || []
                        }
                        options={mainCategoriesList}
                      />
                    </FormItem>
                    <Grid gap='small' columnGap='xlarge'>
                      <FormItem {...cloneDetailsForm?.category} onBlur={() => cloneDetailsOnBlur('category')}>
                        <Select
                          onChange={value => setFieldsForCloneDetails({category: value.label})}
                          value={{label: cloneDetailsModel.category || ''}}
                          id='category'
                          searchable={true}
                          searchPlaceholder='Search for legacy category'
                          onSearch={val =>
                            orderBy(
                              (legacyCategories || [])
                                .filter(lc => lc.name.toLowerCase().startsWith(val.toLowerCase()))
                                .map(lc => ({label: lc.name})),
                              'label',
                            ) || []
                          }
                          options={orderBy(
                            (legacyCategories || []).map(lc => ({label: lc.name})),
                            'label',
                          )}
                        />
                      </FormItem>
                      <FormItem
                        {...cloneDetailsForm?.metadataLanguage}
                        onBlur={() => cloneDetailsOnBlur('metadataLanguage')}
                      >
                        <Select
                          onChange={value => setFieldsForCloneDetails({metadataLanguage: value.value})}
                          value={{
                            label: cloneDetailsModel.metadataLanguage || '',
                            value: cloneDetailsModel.metadataLanguage,
                          }}
                          id='metadataLanguage'
                          predicate={d => d.value?.toLowerCase() || ''}
                          searchable={true}
                          searchPlaceholder='Search for language'
                          onSearch={searchLanguages}
                          options={languageList}
                        />
                      </FormItem>
                    </Grid>
                    <FormItem {...cloneDetailsForm.summary} onBlur={() => cloneDetailsOnBlur('summary')}>
                      <Textarea
                        value={cloneDetailsModel.summary}
                        onChange={v => cloneDetailsOnChange('summary', v)}
                        id='summary'
                        minHeight='6.25rem'
                      />
                    </FormItem>
                  </Stack>
                </Stack>
              </Template>
              <Template label='footer'>
                <Cluster justify='space-between'>
                  <div></div>
                  <Cluster space='small'>
                    <Button ghost={true} onClick={() => setIsCloneChannelOpen(false)} id='cancelButton'>
                      Cancel
                    </Button>
                    <Button
                      type='primary'
                      state={
                        !cloneDetailsState.isValid || !cloneDetailsState.isDirty
                          ? 'disabled'
                          : isCreating
                          ? 'thinking'
                          : ''
                      }
                      onClick={() => handleCloneCreate()}
                      id='cloneButton'
                    >
                      Clone
                    </Button>
                    <Button
                      type='primary'
                      state={
                        !cloneDetailsState.isValid || !cloneDetailsState.isDirty
                          ? 'disabled'
                          : isCreating
                          ? 'thinking'
                          : ''
                      }
                      onClick={() => handleClonedAndEdit()}
                      id='cloneEditButton'
                    >
                      Clone and Edit
                    </Button>
                  </Cluster>
                </Cluster>
              </Template>
            </Dialog>
          </Stack>
        </ContentBox>
      </ContentBoxColumn>
      <ContentBoxColumn>
        <ContentBox title='DMA | Limit Channel Visibility'>
          <Stack space='medium'>
            <FormItem
              {...form.dmaDistribution}
              state={!(model.regionFilter?.include || []).find(e => e === 'US') ? 'disabled' : 'normal'}
              permission={permissions.CHANNEL_EDIT}
              helpText='For United States, limit channel visibility to the following DMAs (No Selections = Full Visibility)'
            >
              <Select
                onChange={value =>
                  setFields({
                    dmaDistribution: value?.length > 0 ? sortCodes(value.map(v => v.value)) : undefined,
                  })
                }
                value={model.dmaDistribution?.map(d => ({label: d.toString(), value: d}))}
                id='dmaDistribution'
                predicate='value'
                multiselect={true}
                options={dmaDistributionCodes}
                searchable={true}
                searchPlaceholder='Search for dma'
                onSearch={val =>
                  orderBy(
                    (dmaDistributionCodes || []).filter(
                      item => item.label.toLowerCase().indexOf(val.toLowerCase()) > -1,
                    ),
                    'label',
                  ) || []
                }
              />
            </FormItem>
          </Stack>
        </ContentBox>
        <ContentBox title='Samsung TV+'>
          <Stack space='small'>
            <Table
              flushTop={true}
              maxHeight='15.625rem'
              cols={[
                {
                  label: 'Service ID',
                  transform: row => row.serviceId,
                },
                {
                  label: 'Territory',
                  transform: row => row.region?.toUpperCase(),
                },
                {
                  label: 'Intiles Enabled',
                  sortable: true,
                  colMinWidth: '8rem',
                  transform: row => (
                    <Status
                      label={row.intilesEnabled ? 'Yes' : 'No'}
                      state={row.intilesEnabled ? 'success' : 'neutral'}
                    />
                  ),
                },
                ...(ableTo('CHANNEL_EDIT')
                  ? [
                      {
                        label: 'Actions',
                        colWidth: '6.25rem',
                        transform: (row, _col, index) => (
                          <Cluster>
                            <TableActions
                              row={row}
                              icons={[]}
                              deleteOption={true}
                              displayField='serviceId'
                              onClick={(row, icon) => handleSamsungServiceClick(icon, index)}
                            >
                              <Popover
                                appendToBody={true}
                                manualTrigger={true}
                                visible={samsungServicePopoverOpen[index]}
                                onClickOutside={() => setSamsungServicePopoverOpen({})}
                              >
                                <Template label='trigger'>
                                  <Icon
                                    space='small'
                                    icon='edit'
                                    onClick={() => handleSamsungServiceClick('edit', index)}
                                  />
                                </Template>
                                <Template label='popover'>
                                  <SamsungServiceForm
                                    channel={model}
                                    visible={samsungServicePopoverOpen[index]}
                                    onCancel={() => setSamsungServicePopoverOpen({})}
                                    value={row}
                                    onSave={val => handleSamsungServiceUpdate(val, index)}
                                  />
                                </Template>
                              </Popover>
                            </TableActions>
                          </Cluster>
                        ),
                      } as ITableCol<ISamsungChannelProperties>,
                    ]
                  : []),
              ]}
              rows={model.samsungChannelProperties}
            />
            <Cluster justify='space-between'>
              <div></div>
              {ableTo('CHANNEL_EDIT') && (
                <Popover
                  manualTrigger={true}
                  visible={samsungServicePopoverOpen.add}
                  onClickOutside={() => setSamsungServicePopoverOpen({})}
                >
                  <Template label='trigger'>
                    <Button type='primary' onClick={() => setSamsungServicePopoverOpen({add: true})}>
                      + Add
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <SamsungServiceForm
                      channel={model}
                      isNew={true}
                      visible={customReferencePopoverOpen.add}
                      value={{}}
                      onSave={handleSamsungServiceUpdate}
                      onCancel={() => setSamsungServicePopoverOpen({})}
                    />
                  </Template>
                </Popover>
              )}
            </Cluster>
          </Stack>
        </ContentBox>
        <ContentBox title='Configuration'>
          <Stack space='small'>
            <Table
              flushTop={true}
              maxHeight='15.625rem'
              cols={[
                {
                  label: 'Custom Reference Type',
                  transform: row => startCase(row.type),
                },
                {
                  label: 'Partner',
                  transform: row => partnerDictionary[row.value] || row.value,
                },
                ...(ableTo('CHANNEL_EDIT')
                  ? [
                      {
                        label: 'Actions',
                        colWidth: '6.25rem',
                        transform: (row, _col, index) => {
                          const canEditCustomRef = row.type !== 'deliveryPartner' || !hasDeliveryPartnerCustomRefSaved;

                          return (
                            <Cluster>
                              <TableActions
                                row={row}
                                icons={[]}
                                deleteOption={canEditCustomRef}
                                displayField='name'
                                altTitle='custom reference type'
                                onClick={(row, icon) => handleCustomReferenceClick(icon, index)}
                              >
                                <Popover
                                  appendToBody={true}
                                  manualTrigger={true}
                                  visible={customReferencePopoverOpen[index]}
                                  onClickOutside={() => setCustomReferencePopoverOpen({})}
                                >
                                  {canEditCustomRef && (
                                    <Template label='trigger'>
                                      <Icon
                                        space='small'
                                        icon='edit'
                                        onClick={() => handleCustomReferenceClick('edit', index)}
                                      />
                                    </Template>
                                  )}
                                  <Template label='popover'>
                                    <CustomReferenceForm
                                      partners={partners || []}
                                      visible={customReferencePopoverOpen[index]}
                                      onCancel={() => setCustomReferencePopoverOpen({})}
                                      value={row}
                                      onSave={val => handleCustomReferencesUpdate(val, index)}
                                    />
                                  </Template>
                                </Popover>
                              </TableActions>
                            </Cluster>
                          );
                        },
                      } as ITableCol<IChannelCustomReference>,
                    ]
                  : []),
              ]}
              rows={model.customReferences || []}
            ></Table>
            <Cluster justify='space-between'>
              <div></div>
              {ableTo('CHANNEL_EDIT') && (
                <Popover
                  manualTrigger={true}
                  visible={customReferencePopoverOpen.add}
                  onClickOutside={() => setCustomReferencePopoverOpen({})}
                >
                  <Template label='trigger'>
                    <Button type='primary' onClick={() => setCustomReferencePopoverOpen({add: true})}>
                      + Add
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <CustomReferenceForm
                      partners={partners || []}
                      isNew={true}
                      visible={customReferencePopoverOpen.add}
                      value={{
                        type: '',
                        value: '',
                      }}
                      onSave={val => handleCustomReferencesUpdate(val)}
                      onCancel={() => setCustomReferencePopoverOpen({})}
                    />
                  </Template>
                </Popover>
              )}
            </Cluster>
          </Stack>
        </ContentBox>
      </ContentBoxColumn>
    </ContentBoxes>
  );
};
