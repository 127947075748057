import * as React from 'react';
import MainCategoriesList from 'components/mainCategoriesList/components/MainCategoriesList';
import {useLazyFindQuery} from 'features/mainCategories/mainCategoriesApi';
import useSearch from 'components/mainCategoriesList/hooks/useSearch';
import {IMainCategory, IMainCategorySearch} from 'models/mainCategories';
import {MainCategoriesSearchLazyLoadProvider} from 'components/mainCategoriesList/providers/MainCategoriesSearchLazyProvider';

export interface ICarouselConfigListProps {
  actionsCol?: boolean;
  addNewCarouselConfig?: boolean;
  checkboxCol?: boolean | 'multiple';
  inModal?: boolean;
  nameTarget?: React.HTMLAttributeAnchorTarget;
  onSelect?: () => void;
  showFavoriteSearch?: boolean;
  isSearchExpanded?: boolean;
}

export default (): React.ReactElement => {
  return (
    <MainCategoriesSearchLazyLoadProvider
      useProvider={() => useSearch<IMainCategory, IMainCategorySearch>(useLazyFindQuery as any)}
    >
      <MainCategoriesList />
    </MainCategoriesSearchLazyLoadProvider>
  );
};
