import React from 'react';
import {Button, Cluster, Dialog, Heading, Stack, Template} from '@pluto-tv/assemble';
import {IVodCollection} from 'models/vodCollections';
import {reorderList} from 'helpers/dragAndDrop';

import {vodCollectionListMock} from 'views/programming/mainCategories/mocks/vodCollectionListMock';
import VodCollectionTable from 'views/programming/mainCategories/edit/vodCollectionList/VodCollectionTable';
interface IReorderVodCollectionListProps {
  handleReorderSave: () => void;
  setReorderOpen: (isOpen: boolean) => void;
  reorderOpen: boolean;
}

const vodCollectionData = vodCollectionListMock.data.filter(item => item.enabled);

const ReorderVodCollectionList: React.FC<IReorderVodCollectionListProps> = React.memo(
  ({handleReorderSave, setReorderOpen, reorderOpen}) => {
    const [isDirtyOrder, setIsDirtyOrder] = React.useState(false);
    const [rowsToReorder, setRowsToReorder] = React.useState<Partial<IVodCollection>[]>([]);
    const [selectedRows, setSelectedRows] = React.useState<Partial<IVodCollection>[]>([]);

    React.useEffect(() => {
      setRowsToReorder(vodCollectionData);
    }, []);

    const handleReorderClear = React.useCallback(() => {
      setRowsToReorder([]);
      setIsDirtyOrder(false);
      setReorderOpen(false);
    }, [setReorderOpen]);

    const handleReorderOnDrop = React.useCallback(
      (_from: string, _to: string, fromIndex: number[], toIndex: number) => {
        const validToIndex = Math.max(0, Math.min(toIndex, rowsToReorder.length));
        setRowsToReorder(oldList => reorderList(fromIndex, validToIndex, oldList));
        setIsDirtyOrder(true);
        setSelectedRows([]);
      },
      [rowsToReorder.length],
    );

    return (
      <Dialog
        id='reorderVodCollectionList'
        isOpen={reorderOpen}
        onClose={() => setReorderOpen(false)}
        width='80%'
        height='40rem'
      >
        <Template label='header'>
          <Stack space='small'>
            <Heading level='h3'>Reorder VOD Collection List</Heading>
            <Heading level='h4' color='warning'>
              Only published VOD Collections are available for reordering.
            </Heading>
          </Stack>
        </Template>
        <Template label='body'>
          <VodCollectionTable
            id='reorderVodCollectionList'
            rows={rowsToReorder}
            handleReorderOnDrop={handleReorderOnDrop}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </Template>
        <Template label='footer'>
          <Cluster justify='space-between'>
            <div></div>
            <Cluster space='small'>
              <Button ghost={true} onClick={() => handleReorderClear()} id='cancelOrderButton'>
                Cancel
              </Button>
              <Button
                type='primary'
                state={!isDirtyOrder ? 'disabled' : ''}
                onClick={() => handleReorderSave()}
                id='saveOrderButton'
              >
                Save Order
              </Button>
            </Cluster>
          </Cluster>
        </Template>
      </Dialog>
    );
  },
);

export default ReorderVodCollectionList;
