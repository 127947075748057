import {
  getSearchLazyLoadProviderContext,
  ISearchData,
} from 'components/mainCategoriesList/providers/getSearchLazyLoadProvider';
import {IMainCategorySearch, IMainCategory} from 'models/mainCategories';

const {
  useSearchLazyLoadProvider: useMainCategoriesSearchlLazyLoadProvider,
  SearchLazyLoadProvider: MainCategoriesSearchLazyLoadProvider,
} = getSearchLazyLoadProviderContext<
  IMainCategory,
  IMainCategorySearch,
  ISearchData<IMainCategory, IMainCategorySearch>
>();

export {useMainCategoriesSearchlLazyLoadProvider, MainCategoriesSearchLazyLoadProvider};
