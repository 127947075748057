import {IValidatorField} from '@pluto-tv/assemble';
import {IMainCategory, IMainCategoryChannelsSearch} from 'models/mainCategories';
import {IVodCollection} from 'models/vodCollections';

export interface IMainCategoriesSearch {
  name: string;
  activeRegion: string;
  officeOnly?: boolean;
}

export interface IMainCategoriesCreate {
  name: string;
  activeRegion: string;
  slug: string;
  plutoOfficeOnly?: boolean;
  hideMainCategory?: boolean;
  pngImageUrl?: string;
  svgImageUrl?: string;
}

export const mainCategoriesDetailsValidator: IValidatorField<IMainCategory>[] = [
  {
    name: 'name',
    label: 'Display Name',
    required: true,
    validators: [
      (name: string): string | undefined => {
        if (!name || !name.trim().length) {
          return 'Display Name is required';
        }
      },
    ],
  },
  {
    name: 'slug',
    label: 'Category Slug',
    required: true,
  },
  {
    name: 'activeRegion',
    label: 'Active Region',
    required: true,
  },
  {
    name: 'distribution',
    label: 'Distribution (Limit Category Display to These Devices)',
  },
  {
    name: 'hideMainCategory',
    label: 'Hide Category',
  },
  {
    name: 'plutoOfficeOnly',
    label: 'Office Only',
  },
  {
    name: 'svgImageUrl',
    label: 'SVG Image',
  },
  {
    name: 'pngImageUrl',
    label: 'PNG Image',
  },
];

export const mainCategoriesValidator: IValidatorField<IMainCategoriesSearch>[] = [
  {
    name: 'name',
    label: 'Display Name',
  },
  {
    name: 'activeRegion',
    label: 'Active Region',
  },
  {
    name: 'officeOnly',
    label: 'Office Only',
  },
];

export const mainCategoriesCreateValidator: IValidatorField<IMainCategoriesCreate>[] = [
  {
    name: 'name',
    label: 'Display Name',
    required: true,
  },
  {
    name: 'activeRegion',
    label: 'Active Region',
    required: true,
  },
  {
    name: 'slug',
    label: 'Category Slug',
    required: true,
    validators: [
      (slug: string): string | undefined => {
        if (!slug || !slug.trim().length) {
          return 'Category Slug is required';
        }
        if (!/^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(slug)) {
          return 'Category Slug must only contain lowercase letters, numbers, and hyphens';
        }
      },
    ],
  },
];

export const vodCollectionSearchValidator: IValidatorField<IVodCollection>[] = [
  {
    name: 'territoriesIncluded',
    label: 'Territories Included',
  },
  {
    name: 'devicesIncluded',
    label: 'Devices Included',
  },
  {
    name: 'devicesExcluded',
    label: 'Devices Excluded',
  },
  {
    name: 'enabled',
    label: 'Published',
  },
  {
    name: 'unpublished',
    label: 'Unpublished',
  },
  {
    name: 'kidsMode',
    label: 'Kids',
  },
  {
    name: 'plutoOfficeOnly',
    label: 'Office Only',
  },
];

export const channelListSearchValidator: IValidatorField<IMainCategoryChannelsSearch>[] = [
  {
    name: 'territoriesIncluded',
    label: 'Territories Included',
  },
  {
    name: 'devicesIncluded',
    label: 'Devices Included',
  },
  {
    name: 'devicesExcluded',
    label: 'Devices Excluded',
  },
  {
    name: 'published',
    label: 'Published',
  },
  {
    name: 'unpublished',
    label: 'Unpublished',
  },
  {
    name: 'kidsMode',
    label: 'Kids',
  },
  {
    name: 'plutoOfficeOnly',
    label: 'Office Only',
  },
];
