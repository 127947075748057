import React from 'react';

import {useLazyFindChannelsQuery} from 'features/mainCategories/mainCategoriesApi';
import {IMainCategoryChannelsSearch} from 'models/mainCategories';
import {SerializedError} from '@reduxjs/toolkit';
import {FetchBaseQueryError} from '@reduxjs/toolkit/query';
import {IChannel} from 'models/channels';

export interface ISearchChannels {
  data: IChannel[];
  totalCount: number;
  isError: boolean;
  error?: FetchBaseQueryError | SerializedError;
  isLoading: boolean;
  search: (params: IMainCategoryChannelsSearch) => void;
  reset: () => void;
}

export const INIT_PARAMS = {sort: 'number:asc', limit: 250, offset: 0, publishedState: true};

const useSearchChannels = (): ISearchChannels => {
  const paramsInitRef = React.useRef<IMainCategoryChannelsSearch>({} as IMainCategoryChannelsSearch);

  const [itemsData, setItemsData] = React.useState<{items: IChannel[]; totalCount: number}>({
    items: [],
    totalCount: 0,
  });

  const {items, totalCount} = itemsData;

  const [searchChannels, {data, isError, isFetching, error}] = useLazyFindChannelsQuery();

  const search = React.useCallback(
    (params?: IMainCategoryChannelsSearch) => {
      setItemsData(() => ({items: [], totalCount: 0}));
      searchChannels({
        ...(params || {}),
        sort: INIT_PARAMS.sort,
        limit: INIT_PARAMS.limit,
        offset: INIT_PARAMS.offset,
      } as IMainCategoryChannelsSearch);

      if (params?.id && params.id !== paramsInitRef.current.id) {
        paramsInitRef.current = {
          id: params.id,
          ...INIT_PARAMS,
        };
      }
    },
    [searchChannels],
  );

  const reset = React.useCallback(() => {
    setItemsData({items: [], totalCount: 0});
    searchChannels(paramsInitRef.current);
  }, [searchChannels]);

  React.useEffect(() => {
    if (isFetching) return;

    setItemsData({
      items: data || [],
      totalCount: data?.length ?? 0,
    });
  }, [data, isFetching]);

  React.useEffect(() => {
    if (isError || (error && 'status' in error && error.status === 404)) {
      setItemsData({
        items: [],
        totalCount: 0,
      });
    }
  }, [error, isError]);

  return {
    data: items as IChannel[],
    totalCount,
    reset,
    isLoading: isFetching,
    isError: isError || (error && 'status' in error && error.status === 404) || false,
    error,
    search,
  };
};

export default useSearchChannels;
