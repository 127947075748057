import * as React from 'react';
import {
  Box,
  Center,
  Cluster,
  Heading,
  Select,
  Status,
  TdLink,
  Notification,
  Paragraph,
  Popover,
  Template,
  TextInput,
  Button,
  FormItem,
  TSize,
} from '@pluto-tv/assemble';
import ReorderPanel from 'components/mainCategoriesList/components/ReorderPanel';
import {IMainCategory} from 'models/mainCategories';
import {useUserRegions} from 'helpers/useUserRegions';
import {reorderList} from 'helpers/dragAndDrop';
import {useFindQuery as useFindMainCategories} from 'features/mainCategories/mainCategoriesApi';
import {isEqual} from 'lodash-es';
import routes from 'routes/programming.routes';

interface IMainCategoriesReorderPanelProps {
  onClose: () => void;
  onApplyOrder: (region: string, mainCategories: IMainCategory[]) => void;
}

const MainCategoriesReorderPanel = ({onClose, onApplyOrder}: IMainCategoriesReorderPanelProps): JSX.Element => {
  const {activeRegions} = useUserRegions();
  const [activeRegion, setActiveRegion] = React.useState<string>();
  const [list, setList] = React.useState<IMainCategory[]>([]);
  const [targetOrderIndex, setTargetOrderIndex] = React.useState<number>(-1);

  const [popoverVisible, setPopoverVisible] = React.useState(-1);
  const {
    data: mainCategories,
    isLoading,
    isFetching,
    isError,
  } = useFindMainCategories(
    {
      region: activeRegion?.toLowerCase(),
      limit: 1000,
    },
    {
      skip: !activeRegion,
    },
  );

  const isSaveDisabled = React.useMemo(() => {
    const oldList = (mainCategories?.data || []).map(({id}) => id);
    const newList = list.map(({id}) => id);
    return isEqual(oldList, newList);
  }, [list, mainCategories?.data]);

  const handleApplyOrder = React.useCallback(() => {
    if (!activeRegion || !mainCategories?.data) return;
    const firstIndex = Math.min(...list.map(item => item.order));
    const newList = list.map((item, index) => ({
      ...item,
      order: firstIndex + index,
    }));
    onApplyOrder(activeRegion, newList);
  }, [activeRegion, list, mainCategories?.data, onApplyOrder]);

  React.useEffect(() => {
    if (mainCategories?.data) {
      setList(mainCategories?.data || []);
    }
  }, [mainCategories]);

  React.useEffect(() => {
    if (isError) {
      setList([]);
    }
  }, [isError]);

  const handleReorder = React.useCallback(
    (fromIndex: number[], toIndex: number) => {
      const validToIndex = Math.max(0, Math.min(toIndex, list.length));
      setList(oldList => reorderList(fromIndex, validToIndex, oldList));
    },
    [list.length],
  );

  const columns = React.useMemo(
    () => [
      {
        label: 'Order',
        transform: (row: IMainCategory, _, index: number) => (
          <Popover manualTrigger visible={popoverVisible === index} appendToBody>
            <Template label='trigger'>
              <TdLink row={row} title={`${index + 1}`} onClick={() => setPopoverVisible(index)} />
            </Template>
            <Template label='popover'>
              <Box background='charcoal' padding='small'>
                <Box width='13.5rem'>
                  <FormItem label='Order' required={true}>
                    <TextInput
                      type='number'
                      placeholder='Season Count'
                      value={index + 1}
                      onChange={val => {
                        // index === val - 1 means the user is trying to set the same order
                        setTargetOrderIndex(index === val - 1 ? -1 : val <= index ? val - 1 : val);
                      }}
                    />
                  </FormItem>
                </Box>
                <Cluster space='small' justify='end'>
                  <div></div>
                  <Button id='cancelBtn' ghost={true} size='small' onClick={() => setPopoverVisible(-1)}>
                    Cancel
                  </Button>
                  <Button
                    id='applyOrderBtn'
                    type='primary'
                    size='small'
                    state={targetOrderIndex === -1 ? 'disabled' : ''}
                    onClick={() => {
                      handleReorder([index], targetOrderIndex);

                      setTargetOrderIndex(-1);
                      setPopoverVisible(-1);
                    }}
                  >
                    Apply
                  </Button>
                </Cluster>
              </Box>
            </Template>
          </Popover>
        ),
      },
      {
        label: 'Display Name',
        colMinWidth: '22rem' as TSize,
        transform: row => (
          <TdLink
            row={row}
            title={row.name}
            url={routes.paths.mainCategoriesEditDetailsPage.replace(':id', row.id)}
            target='_blank'
          />
        ),
      },
      {
        label: 'Active Region',
        sortable: true,
        transform: (row: IMainCategory) => row.activeRegion?.toUpperCase() || 'N/A',
      },
      {
        label: 'Office Only',
        transform: (row: IMainCategory) => (
          <Status label={row.plutoOfficeOnly ? 'Yes' : 'No'} state={row.plutoOfficeOnly ? 'success' : 'neutral'} />
        ),
      },
    ],
    [handleReorder, popoverVisible, targetOrderIndex],
  );

  const emptyStste = (
    <Box height='37.5rem'>
      <Cluster justify='center' align='center' fullHeight>
        <Center textCenter={true}>
          <Notification type='info'>
            <Paragraph>Select an Active Region to show Main Categories Listing.</Paragraph>
          </Notification>
        </Center>
      </Cluster>
    </Box>
  );

  const header = (
    <>
      <Box width='70%'>
        <Heading level='h2'>Reorder Main Categories</Heading>
      </Box>
      <Box width='30%'>
        <Select
          id='selectActiveRegion'
          placeholder='Select Active Region'
          value={
            {
              value: activeRegion,
              label: activeRegions.find(ar => ar.code === activeRegion)?.name,
            } as any
          }
          onChange={val => {
            setActiveRegion(val?.value);
          }}
          options={activeRegions.map(ar => ({
            label: `${ar.name} (${ar.code})`,
            value: ar.code,
          }))}
          predicate='value'
        />
      </Box>
    </>
  );

  return (
    <ReorderPanel
      items={list}
      isLoading={isLoading || isFetching}
      contentId='reorderMainCatContent'
      columns={columns}
      onClose={onClose}
      onApplyOrder={handleApplyOrder}
      header={header}
      width='56.25rem'
      onDrop={handleReorder}
      useCustomBodyComponent={!Boolean(activeRegion)}
      customBodyComponent={emptyStste}
      isSaveDisabled={isSaveDisabled}
      scrollTrigger={activeRegion}
    />
  );
};

export default MainCategoriesReorderPanel;
