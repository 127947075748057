import * as React from 'react';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Cluster,
  Cover,
  Dialog,
  Divider,
  Expand,
  FormItem,
  Heading,
  Icon,
  ImageWrapper,
  ISelectOption,
  Label,
  Notification,
  Paragraph,
  Select,
  Sidebar,
  Spinner,
  Stack,
  Status,
  Table,
  TdLink,
  Template,
  useValidateForm,
} from '@pluto-tv/assemble';
import channelRoutes from 'routes/programming.routes';
import {IChannel} from 'models/channels';
import useToggleSearchBarOnSlash from 'helpers/useToggleSearchBarOnSlash';
import {useAppPermissions} from 'app/permissions';
import {withThousandsSeparator} from 'utils/thousands-separator';
import {orderBy} from 'lodash-es';
import {useFindQuery as useFindDevicesQuery} from 'features/devices/devicesApi';
import {useUserRegions} from 'helpers/useUserRegions';
import {INestedMainCategoriesProps} from 'views/programming/mainCategories/nestedPropsInterface';
import useSearchChannels, {INIT_PARAMS} from 'views/programming/mainCategories/hooks/useGetSearchChannels';
import {IMainCategoryChannelsSearch, IMainCategoryChannelsSearchForm} from 'models/mainCategories';
import {channelListSearchValidator} from 'views/programming/mainCategories/validators';
import {rewriteImageUrl} from 'utils/imageUtils';

export interface IChannelListProps extends INestedMainCategoriesProps {
  actionsCol?: boolean;
  addNewChannel?: boolean;
  deviceUpdates?: boolean;
  checkboxCol?: boolean | 'multiple';
  filterByArchived?: boolean;
  showFavoriteSearch?: boolean;
  inModal?: boolean;
  onSelect?: (rows: IChannel[]) => void;
  onRowClick?: (row: IChannel) => void;
  nameTarget?: React.HTMLAttributeAnchorTarget;
  isSearchExpanded?: boolean;
  searchRegion?: string;
  disableChannels?: IChannel[] | Partial<IChannel>[];
  hideArchivedChannels?: boolean;
}

const orderByLabel = valueToMap => {
  return orderBy(
    valueToMap.map(label => ({label, value: label})),
    ['label'],
  );
};

const KIDS_MODE_OPTIONS: ISelectOption[] = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

const OFFICE_MODE_OPTIONS: ISelectOption[] = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

const REORDER_OPTIONS: ISelectOption[] = [
  {label: 'Natural', value: 'natural'},
  {label: 'Custom', value: 'custom'},
];

export default React.memo(({inModal = false, isSearchExpanded = true, model}: IChannelListProps): JSX.Element => {
  const [searchExpanded, setSearchExpanded] = React.useState(isSearchExpanded);
  const {ableTo} = useAppPermissions();
  const [rowsToReorder, setRowsToReorder] = React.useState<IChannel[]>([]);
  const [reorderOpen, setReorderOpen] = React.useState(false);
  const [reorderOption, setReorderOption] = React.useState<ISelectOption>({label: 'Natural', value: 'natural'});
  const [isDirtyOrder, setIsDirtyOrder] = React.useState(false);
  const {territories} = useUserRegions();

  const getChannelEditUrl = (channel: IChannel) =>
    channelRoutes.paths.channelEditDetailsPage.replace(':id', channel?.id);

  useToggleSearchBarOnSlash(setSearchExpanded, searchExpanded);

  const {data: deviceTypesList} = useFindDevicesQuery({
    offset: 0,
    limit: 250,
    sort: 'name:asc',
  });

  const {search: onSearch, data: channelList, totalCount, isError, isLoading, reset} = useSearchChannels();

  React.useEffect(() => {
    if (channelList) {
      setRowsToReorder(channelList || []);
    }
  }, [channelList]);

  const {
    model: searchModel,
    setFields: setSearchFields,
    form: searchForm,
    reset: searchReset,
    setModel: setSearchModel,
  } = useValidateForm<IMainCategoryChannelsSearchForm>(channelListSearchValidator, 'ask');

  React.useEffect(() => {
    if (!model.id) return;

    onSearch({id: model.id, ...INIT_PARAMS});
    setSearchModel({published: true});
  }, [onSearch, model.id, setSearchModel]);

  const filteredDeviceIncludedList = React.useMemo(() => {
    const deviceIncludedOptionList = orderByLabel([...new Set(deviceTypesList?.data.map(d => d.platform))]);
    return orderByLabel(
      deviceIncludedOptionList.flatMap(a => a.label).filter(i => !searchModel.devicesExcluded?.includes(i)),
    );
  }, [deviceTypesList?.data, searchModel.devicesExcluded]);

  const filteredDeviceExcludedList = React.useMemo(() => {
    const deviceExludedOptionList = orderByLabel([...new Set(deviceTypesList?.data.map(d => d.platform))]);
    return orderByLabel(
      deviceExludedOptionList.flatMap(a => a.label).filter(i => !searchModel.devicesIncluded?.includes(i)),
    );
  }, [deviceTypesList?.data, searchModel.devicesIncluded]);

  const openReorder = () => {
    setReorderOpen(true);
  };

  const handleReorderCancel = () => {
    setReorderOpen(false);
  };

  const onDrop = (key, value, orig, dest) => {
    const newOrder = [...rowsToReorder];
    const row = newOrder.splice(orig, 1)[0];
    newOrder.splice(dest, 0, row);
    setIsDirtyOrder(true);
    setRowsToReorder(changeRowOrder(newOrder));
  };

  const changeRowOrder = (arr: IChannel[]) => {
    return arr.map((item, index) => {
      return {...item, order: index + 1};
    });
  };

  const handleSearchClear = React.useCallback(() => {
    searchReset();
    reset();
    setSearchModel({published: true});
  }, [reset, searchReset, setSearchModel]);

  const handleSearch = React.useCallback(() => {
    const params: IMainCategoryChannelsSearch = {id: model.id || '', ...INIT_PARAMS, ...searchModel};
    delete params.published;
    delete params.unpublished;
    delete params.publishedState;

    if (searchModel.published && !searchModel.unpublished) {
      params.publishedState = true;
    } else if (searchModel.unpublished && !searchModel.published) {
      params.publishedState = false;
    }

    onSearch(params);
  }, [model.id, onSearch, searchModel]);

  const handleActionPlaceholder = () => {
    // placeholder
  };

  return (
    <Sidebar fullHeight={true}>
      <Expand width='18.75rem' height='100%' fullHeightContainer={true} isExpanded={searchExpanded}>
        <Template label='expandable'>
          <Box
            background='pewter'
            paddingY={inModal ? 'none' : 'medium'}
            paddingRight='medium'
            paddingLeft={inModal ? 'none' : 'medium'}
            fullHeight={true}
          >
            <Cover scrolling={true} gutter='medium'>
              <Template label='header'>
                <Stack space='medium'>
                  <Cluster align='center' justify='space-between'>
                    <Icon icon='tune' space='small' size='large' iconAlign='center'>
                      <Heading level='h4'>Search Filters</Heading>
                    </Icon>
                    <Icon
                      id='closeFilters'
                      icon='collapseleft'
                      size='large'
                      onClick={() => setSearchExpanded(!searchExpanded)}
                    />
                  </Cluster>
                  <Divider color='graphite' />
                </Stack>
              </Template>
              <Template label='cover'>
                {searchExpanded && (
                  <form
                    id='searchFilter'
                    onSubmit={ev => {
                      ev.preventDefault();
                      handleSearch();
                    }}
                  >
                    <Stack space='small'>
                      {/* Using this to allow pressing enter to submit form */}
                      <input type='submit' style={{display: 'none'}} />
                      <Stack space='xxlarge'>
                        <Stack space='small'>
                          <Label>Status</Label>
                          <Stack space='small'>
                            <Checkbox
                              id='publishedFilter'
                              label='Published'
                              value={searchModel.published}
                              onChange={val => {
                                setSearchFields({published: val});
                              }}
                            />
                            <Checkbox
                              id='unpublishedFilter'
                              label='Unpublished'
                              value={searchModel.unpublished}
                              onChange={val => {
                                setSearchFields({unpublished: val});
                              }}
                            />
                          </Stack>
                        </Stack>
                        <FormItem label='Territories Included'>
                          <Select
                            placeholder='Select Territory'
                            id='territoriesIncluded'
                            value={searchModel.territoriesIncluded?.map(d => ({label: d, value: d}))}
                            multiselect={true}
                            clearable={true}
                            searchable={true}
                            options={(territories || []).map(t => ({label: t.name, value: t.id}))}
                            onChange={value =>
                              setSearchFields({
                                territoriesIncluded: (value || []).map(v => v.value),
                              })
                            }
                            predicate='value'
                          ></Select>
                        </FormItem>
                      </Stack>
                      <FormItem {...searchForm.devicesIncluded}>
                        <Select
                          id='devicesIncluded'
                          clearable={true}
                          placeholder='Select Devices'
                          multiselect={true}
                          value={searchModel.devicesIncluded?.map(p => ({label: p, value: p}))}
                          options={filteredDeviceIncludedList}
                          onChange={value => {
                            setSearchFields({
                              devicesIncluded: ((value as ISelectOption[]) || []).map(v => v.value),
                            });
                          }}
                          predicate='value'
                          searchable={true}
                          onSearch={term =>
                            orderBy(
                              (filteredDeviceIncludedList || [])
                                .filter(p => p.label.toLowerCase().startsWith(term.toLowerCase()))
                                .map(p => ({label: p.label, value: p.value}), 'label'),
                            ) || ([] as ISelectOption[])
                          }
                        />
                      </FormItem>
                      <FormItem {...searchForm.devicesExcluded}>
                        <Select
                          id='devicesExcluded'
                          clearable={true}
                          placeholder='Select Devices'
                          multiselect={true}
                          value={searchModel.devicesExcluded?.map(p => ({label: p, value: p}))}
                          options={filteredDeviceExcludedList}
                          onChange={value => {
                            setSearchFields({
                              devicesExcluded: ((value as ISelectOption[]) || []).map(v => v.value),
                            });
                          }}
                          predicate='value'
                          searchable={true}
                          onSearch={term =>
                            orderBy(
                              (filteredDeviceExcludedList || [])
                                .filter(p => p.label.toLowerCase().startsWith(term.toLowerCase()))
                                .map(p => ({label: p.label, value: p.value}), 'label'),
                            ) || ([] as ISelectOption[])
                          }
                        />
                      </FormItem>
                      <FormItem {...searchForm.kidsMode} label='Kids'>
                        <Select
                          id='kidsOnly'
                          clearable={true}
                          predicate='value'
                          placeholder='Select Kids Only'
                          options={KIDS_MODE_OPTIONS}
                          value={{label: '', value: searchModel.kidsMode}}
                          onChange={value => {
                            setSearchFields({kidsMode: value?.value});
                          }}
                        />
                      </FormItem>
                      <FormItem {...searchForm.plutoOfficeOnly} label='Office Only'>
                        <Select
                          id='officeOnly'
                          clearable={true}
                          predicate='value'
                          placeholder='Select Office Only'
                          options={OFFICE_MODE_OPTIONS}
                          value={{label: '', value: searchModel.plutoOfficeOnly}}
                          onChange={value => {
                            setSearchFields({plutoOfficeOnly: value?.value});
                          }}
                        />
                      </FormItem>
                    </Stack>
                  </form>
                )}
              </Template>
              <Template label='footer'>
                <Cluster justify='space-between'>
                  <div></div>
                  <Cluster space='small'>
                    <Button id='clearButton' ghost={true} onClick={handleSearchClear}>
                      Clear
                    </Button>
                    <Button id='searchButton' type='primary' onClick={handleSearch}>
                      Search
                    </Button>
                  </Cluster>
                </Cluster>
              </Template>
            </Cover>
          </Box>
        </Template>
      </Expand>
      <Cover
        scrolling={true}
        gutter='large'
        coverTemplateHeight='100%'
        overflow='auto'
        padding={inModal ? 'none' : {mobile: 'medium', wide: 'large'}}
      >
        <Template label='header'>
          <Cluster justify='space-between' align='center' space='medium'>
            <Cluster align='end' space='small'>
              <Heading level='h1'>Channels</Heading>
              <Cluster space='xxsmall' align='center'>
                <Icon
                  id='expandFilter'
                  icon='tune'
                  space='xxxsmall'
                  verticalAlign='bottom'
                  lineHeight='0px'
                  onClick={() => setSearchExpanded(!searchExpanded)}
                >
                  {withThousandsSeparator(totalCount || 0)} Items
                </Icon>
              </Cluster>
            </Cluster>
            <Cluster space='small' align='center'>
              <Button
                id='reorderBtn'
                type='secondary'
                onClick={openReorder}
                permission={ableTo('MAIN_CATEGORIES_EDIT') ? '' : 'disabled'}
              >
                Reorder
              </Button>
              <Dialog isOpen={reorderOpen} onClose={handleReorderCancel} width='100%' height='100%'>
                <Template label='header'>
                  <Cluster justify='space-between' align='center' fullWidth={true}>
                    <Heading level='h2'>Reorder Channel List</Heading>
                    <Cluster space='small' align='center'>
                      <Label>Channel List Stacking Order</Label>
                      <Select
                        id='channelListOrder'
                        width='9rem'
                        predicate='value'
                        options={REORDER_OPTIONS}
                        value={reorderOption}
                        onChange={value => {
                          setReorderOption(value);
                        }}
                      />
                    </Cluster>
                  </Cluster>
                </Template>
                <Template label='body'>
                  {reorderOption.value === 'natural' && (
                    <Cover center={true}>
                      <Template label='cover'>
                        <Center maxWidth='29rem'>
                          <Notification type='info'>
                            Order is set to Natural (according to channel number). To reorder channel list, please set
                            order to Custom.
                          </Notification>
                        </Center>
                      </Template>
                    </Cover>
                  )}
                  {reorderOption.value === 'custom' && (
                    <Table
                      emptyMsg='No Channels to reorder.'
                      fixedHeader={true}
                      wrapContent={true}
                      onDrop={onDrop}
                      draggable={true}
                      dragKey='key'
                      dropKeys={['key']}
                      predicate='id'
                      rows={rowsToReorder || []}
                      cols={[
                        {
                          label: 'Channel Name',
                          sortable: true,
                          colMinWidth: '22.125rem',
                          transform: row => <TdLink row={row} title={row.name} url={getChannelEditUrl(row)} />,
                        },
                        {
                          label: 'No.',
                          field: 'number',
                          sortable: true,
                          colMinWidth: '5rem',
                        },
                        {
                          label: 'Published',
                          sortable: true,
                          colMinWidth: '9.5rem',
                          transform: row => (
                            <Status
                              label={row.published ? 'Published' : 'Unpublished'}
                              state={row.published ? 'success' : 'neutral'}
                            />
                          ),
                        },
                        {
                          label: 'Kids',
                          sortable: true,
                          colMinWidth: '8.875rem',
                          transform: row => (
                            <Status label={row.kidsMode ? 'Yes' : 'No'} state={row.kidsMode ? 'success' : 'neutral'} />
                          ),
                        },
                        {
                          label: 'Office Only',
                          sortable: true,
                          colMinWidth: '8.875rem',
                          transform: row => (
                            <Status
                              label={row.plutoOfficeOnly ? 'Yes' : 'No'}
                              state={row.plutoOfficeOnly ? 'success' : 'neutral'}
                            />
                          ),
                        },
                      ]}
                    >
                      <Template label='loading'>
                        <Cluster space='small' align='center'>
                          <Spinner />
                          <Paragraph>Loading Channel List</Paragraph>
                        </Cluster>
                      </Template>
                      <Template label='empty'>
                        <Notification type='warning'>There are no Channels currently available.</Notification>
                      </Template>
                    </Table>
                  )}
                </Template>
                <Template label='footer'>
                  <Cluster justify='space-between'>
                    <div></div>
                    <Cluster space='small'>
                      <Button ghost={true} onClick={handleReorderCancel} id='cancelBtn'>
                        Cancel
                      </Button>
                      <Button
                        type='primary'
                        onClick={handleActionPlaceholder}
                        id='saveBtn'
                        state={!isDirtyOrder ? 'disabled' : ''}
                      >
                        Save Order
                      </Button>
                    </Cluster>
                  </Cluster>
                </Template>
              </Dialog>
            </Cluster>
          </Cluster>
        </Template>
        <Template label='cover'>
          <Box
            background='pewter'
            borderTop={true}
            borderSize='2px'
            borderColor='cavern'
            paddingTop='xsmall'
            paddingBottom='none'
            paddingX={inModal ? 'none' : 'large'}
            fullHeight={true}
          >
            <Table<IChannel>
              id='channelList'
              loading={isLoading}
              fixedHeader={true}
              predicate='id'
              wrapContent={true}
              emptyMsg={isError || (!channelList?.length && !isLoading) ? 'No channels associated.' : undefined}
              rows={channelList || []}
              cols={[
                {
                  label: 'Channel Name',
                  colMinWidth: '22.125rem',
                  transform: row => (
                    <Cluster wrap={false} space='small' align='center'>
                      <ImageWrapper
                        width='3rem'
                        height='3rem'
                        src={rewriteImageUrl(row.featuredImage?.path)}
                        alt={row.name}
                      />
                      <TdLink row={row} title={row.name || ''} url={getChannelEditUrl(row)} target='_blank' />
                    </Cluster>
                  ),
                },
                {
                  label: 'No.',
                  field: 'number',
                  colMinWidth: '5rem',
                },
                {
                  label: 'Published',
                  colMinWidth: '9.5rem',
                  transform: row => (
                    <Status
                      label={row.published ? 'Published' : 'Unpublished'}
                      state={row.published ? 'success' : 'neutral'}
                    />
                  ),
                },
                {
                  label: 'Kids',
                  colMinWidth: '8.875rem',
                  transform: row => (
                    <Status label={row.kidsMode ? 'Yes' : 'No'} state={row.kidsMode ? 'success' : 'neutral'} />
                  ),
                },
                {
                  label: 'Office Only',
                  colMinWidth: '8.875rem',
                  transform: row => (
                    <Status
                      label={row.plutoOfficeOnly ? 'Yes' : 'No'}
                      state={row.plutoOfficeOnly ? 'success' : 'neutral'}
                    />
                  ),
                },
              ]}
            >
              <Template label='loading'>
                <Cluster space='small' align='center'>
                  <Spinner />
                  <Paragraph>Loading Channel List</Paragraph>
                </Cluster>
              </Template>
              <Template label='empty'>
                <Notification type='warning'>There are no channels currently available.</Notification>
              </Template>
            </Table>
          </Box>
        </Template>
        <Template label='footer'>
          <></>
        </Template>
      </Cover>
    </Sidebar>
  );
});
