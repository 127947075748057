import * as React from 'react';
import {IListPayload} from 'models/generic';

const DEFAULT_LIMIT = 50;

export interface ISearchData<T, U> {
  data: IListPayload<T>;
  currentData: IListPayload<T>;
  isFetching: boolean;
  isError: boolean;
  error: Error;
  isLoading: boolean;
  search: (params: U) => void;
}

interface IUseSearchLazyLoadConfigContext<T, U> {
  items: T[];
  totalCount: number;
  isLazyLoading: boolean;
  lazyLoad: () => void;
  isError: boolean;
  error: Error;
  isLoading: boolean;
  search: (params: U) => void;
  reset: (resetForm?: boolean) => void;
  searchParams: U;
}

// T type returned by the endpoint
// U params for the endpoint
// V useProvider hook
export const getSearchLazyLoadProviderContext = <T, U extends Record<string, unknown>, V extends ISearchData<T, U>>(): {
  SearchContext: React.Context<IUseSearchLazyLoadConfigContext<T, U> | null>;
  SearchLazyLoadProvider: React.FC<{useProvider: () => V}>;
  useSearchLazyLoadProvider: () => IUseSearchLazyLoadConfigContext<T, U>;
} => {
  const SearchContext = React.createContext<IUseSearchLazyLoadConfigContext<T, U> | null>(null);

  return {
    SearchContext,
    SearchLazyLoadProvider: ({children, useProvider}) => {
      const offsetRef = React.useRef<number>(0);
      const paramsRef = React.useRef<U>({} as U);
      const hasMoreRef = React.useRef<boolean>(false);

      const [itemsData, setItemsData] = React.useState<{items: T[]; totalCount: number}>({
        items: [],
        totalCount: 0,
      });

      const {items, totalCount} = itemsData;

      const {isFetching, error, isError, data, search: doSearch} = useProvider();

      React.useEffect(() => {
        if (error && 'status' in error && error.status === 404) {
          hasMoreRef.current = false;
        }
      }, [error]);

      const search = React.useCallback(
        (params?: U) => {
          hasMoreRef.current = false;
          paramsRef.current = params ?? ({} as U);
          offsetRef.current = 0;

          setItemsData(() => ({items: [], totalCount: 0}));
          doSearch({...(params as U), offset: offsetRef.current, limit: DEFAULT_LIMIT});
        },
        [doSearch],
      );

      const reset = React.useCallback((resetForm?: boolean) => {
        setItemsData({items: [], totalCount: 0});
        offsetRef.current = 0;
        paramsRef.current = resetForm ? ({} as U) : paramsRef.current;
        hasMoreRef.current = false;
      }, []);

      React.useEffect(() => {
        if (isFetching || !data) return;

        if (!data?.data?.length) {
          if (offsetRef.current === 0) {
            setItemsData({items: [], totalCount: 0});
          }
          hasMoreRef.current = false;
        } else {
          setItemsData(prevData => ({
            items: offsetRef.current === 0 ? data?.data : [...prevData.items, ...data?.data],
            totalCount: data?.metadata.totalCount ?? 0,
          }));

          hasMoreRef.current = data?.data?.length < DEFAULT_LIMIT ? false : true;
        }
      }, [data, isFetching]);

      const lazyLoad = React.useCallback(() => {
        if (!hasMoreRef.current || !items.length) return;
        offsetRef.current = offsetRef.current + DEFAULT_LIMIT;
        doSearch({...paramsRef.current, offset: offsetRef.current, limit: DEFAULT_LIMIT});
      }, [doSearch, items.length]);

      const value = React.useMemo(
        () => ({
          items,
          totalCount,
          isLazyLoading: isFetching && offsetRef.current > 0,
          lazyLoad,
          reset,
          isLoading: isFetching && offsetRef.current === 0,
          isError,
          error,
          search,
          searchParams: paramsRef.current,
        }),
        [error, isError, isFetching, items, lazyLoad, reset, search, totalCount],
      );

      return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
    },

    useSearchLazyLoadProvider: () => {
      const context = React.useContext(SearchContext);
      if (!context) {
        throw new Error('useSearchLazyLoadProvider must be used within a SearchLazyLoadProvider');
      }
      return context;
    },
  };
};
