export class MainCategoriesDeleteError extends Error {
  constructor(message = '') {
    super(message);
    this.name = 'MainCategoriesDeleteError';
    Object.setPrototypeOf(this, MainCategoriesDeleteError.prototype);
  }
}

export class MainCategoriesHasAssociatedEntitiesDeleteError extends Error {
  constructor(message = 'Remove all associated channels or VOD collections from this main category to delete.') {
    super(message);
    this.name = 'MainCategoriesHasAssociatedEntitiesDeleteError';
    Object.setPrototypeOf(this, MainCategoriesHasAssociatedEntitiesDeleteError.prototype);
  }
}
