import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';
import {
  IMainCategoryResponse,
  IMainCategoryRequest,
  IMainCategory,
  IMainCategoryChannelsSearch,
  IMainCategoryReorderRequest,
} from 'models/mainCategories';
import {getParamsFromModel} from 'features/helpers';
import {IChannel} from 'models/channels';

export const mainCategoriesApi = createApi({
  reducerPath: 'mainCategoriesApi',
  tagTypes: ['MainCategories', 'MainCategoryChannels'],
  baseQuery: baseQueryWithAuth,
  endpoints: builder => ({
    find: builder.query<IMainCategoryResponse, Partial<IMainCategoryRequest> | void>({
      query: (params: Partial<IMainCategoryRequest> = {limit: 1000}) => {
        const paramsString = getParamsFromModel<Partial<IMainCategoryRequest>>(params)
          .map(param => Object.entries(param).map(([key, value]) => `${key}=${value}`))
          .join('&');
        const searchParams = new URLSearchParams(paramsString);
        return {
          url: `main-categories?${searchParams.toString()}`,
          method: 'GET',
        };
      },
      transformResponse: (response: IMainCategoryResponse) => {
        return response.data ? response : {data: [], metadata: {offset: 0, limit: 0, totalCount: 0}};
      },
      providesTags: result =>
        result?.data
          ? [
              ...result.data.map(({id}) => ({type: 'MainCategories', id} as const)),
              {type: 'MainCategories', id: 'LIST'},
            ]
          : [],
    }),
    findById: builder.query<IMainCategory, string>({
      query: id => ({
        url: `main-categories/${id}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, id) => [{type: 'MainCategories', id}],
    }),
    findChannels: builder.query<IChannel[], IMainCategoryChannelsSearch>({
      query: params => {
        const queryParams: Partial<IMainCategoryChannelsSearch> = {...params};
        delete queryParams['id'];

        const paramsString = getParamsFromModel<IMainCategoryChannelsSearch>(params)
          .map(param => Object.entries(param).map(([key, value]) => `${key}=${value}`))
          .join('&');
        const searchParams = new URLSearchParams(paramsString);

        return {
          url: `main-categories/${params.id}/channels?${searchParams}`,
          method: 'GET',
        };
      },
      providesTags: ['MainCategoryChannels'],
    }),
    reorder: builder.mutation<{id: string; order: number}[], IMainCategoryReorderRequest>({
      query: body => ({
        url: `main-categories/reorder`,
        method: 'POST',
        body,
      }),
      invalidatesTags: result =>
        result
          ? [...result.map(({id}) => ({type: 'MainCategories', id} as const)), {type: 'MainCategories', id: 'LIST'}]
          : [],
    }),
    delete: builder.mutation<number, string>({
      query: id => ({
        url: `main-categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error, id) => (!error ? [{type: 'MainCategories', id}] : []),
    }),
    insert: builder.mutation<IMainCategory, Partial<IMainCategory>>({
      query: mainCategory => ({
        url: `main-categories`,
        method: 'POST',
        body: mainCategory,
      }),
      invalidatesTags: [{type: 'MainCategories', id: 'LIST'}],
    }),
  }),
});

export const {
  useFindQuery,
  useLazyFindQuery,
  useFindByIdQuery,
  useFindChannelsQuery,
  useLazyFindChannelsQuery,
  useDeleteMutation,
  useInsertMutation,
  useReorderMutation,
} = mainCategoriesApi;
