import * as React from 'react';
import {useHistory, useParams, useLocation} from 'react-router-dom';
import {
  Box,
  Button,
  Cluster,
  Cover,
  Dialog,
  Divider,
  Heading,
  Icon,
  Paragraph,
  Spinner,
  Stack,
  Template,
  Toast,
  useValidateForm,
} from '@pluto-tv/assemble';
import programmingRoutes from 'routes/programming.routes';
import MainCategoriesDetails from './details';
import MainCategoriesChannelList from './channelList';
import {IMainCategory} from 'models/mainCategories';
import {mainCategoriesDetailsValidator} from '../validators';
import {useAppPermissions} from 'app/permissions';
import VodCollectionList from 'views/programming/mainCategories/edit/vodCollectionList';
import {useFindByIdQuery} from 'features/mainCategories/mainCategoriesApi';
import CrudError from 'components/crudError';
import ConfirmRouteChange from 'components/confirmRouteChange';
import {useDelete} from 'components/mainCategoriesList/hooks/useDelete';
import {MainCategoriesHasAssociatedEntitiesDeleteError} from 'components/mainCategoriesList/hooks/customErrors';

export const CHANNEL_TABS = {
  details: 'details',
  channelList: 'channelList',
  vodCollectionList: 'vodCollectionList',
} as const;

export type TChannelTabs = keyof typeof CHANNEL_TABS;

const pathToTabMap = {
  details: CHANNEL_TABS.details,
  'channel-list': CHANNEL_TABS.channelList,
  'vod-collection-list': CHANNEL_TABS.vodCollectionList,
} as const;

const getActiveTab = (pathname: string): TChannelTabs => {
  const nestedPath = pathname.slice(pathname.lastIndexOf('/') + 1) as keyof typeof pathToTabMap;
  if (pathToTabMap[nestedPath]) {
    return CHANNEL_TABS[pathToTabMap[nestedPath]];
  }

  return CHANNEL_TABS.details;
};

const MainCategoryEdit = (): JSX.Element => {
  const {id} = useParams<{id: string}>();
  const location = useLocation();
  const history = useHistory();
  const activeTab: TChannelTabs = getActiveTab(location.pathname);
  const {ableTo} = useAppPermissions();
  const [isSaving, setIsSaving] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isLeaving, setIsLeaving] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const {onDelete} = useDelete();

  const canEdit = ableTo('MAIN_CATEGORIES_EDIT');

  const {data: mainCategory, isLoading, isError, error, isSuccess} = useFindByIdQuery(id, {skip: !id});

  const {
    form,
    model,
    onBlur,
    onChange,
    pristineModel,
    setFields,
    dirtyFields,
    state: formState,
    setModel,
  } = useValidateForm<IMainCategory>([...mainCategoriesDetailsValidator]);

  const navigationTabs = React.useMemo(
    () => [
      {
        key: CHANNEL_TABS.details,
        identifier: 'detailsTab',
        label: 'Details',
        route: programmingRoutes.paths.mainCategoriesEditDetailsPage,
        component: (
          <MainCategoriesDetails
            key={CHANNEL_TABS.details}
            form={form}
            model={model}
            onBlur={onBlur}
            onChange={onChange}
            pristineModel={pristineModel}
            setFields={setFields}
            dirtyFields={dirtyFields}
            canEdit={canEdit}
          />
        ),
      },
      {
        key: CHANNEL_TABS.channelList,
        label: 'Channel List',
        route: programmingRoutes.paths.mainCategoriesEditChannelList,
        component: (
          <MainCategoriesChannelList
            key={CHANNEL_TABS.channelList}
            form={form}
            model={model}
            onBlur={onBlur}
            onChange={onChange}
            pristineModel={pristineModel}
            setFields={setFields}
          />
        ),
      },
      {
        key: CHANNEL_TABS.vodCollectionList,
        identifier: 'vodCollectionListTab',
        label: 'Vod Collection List',
        route: programmingRoutes.paths.mainCategoriesEditVodCollectionList,
        component: <VodCollectionList key={CHANNEL_TABS.vodCollectionList} />,
      },
    ],
    [canEdit, dirtyFields, form, model, onBlur, onChange, pristineModel, setFields],
  );

  React.useEffect(() => {
    if (isSuccess && mainCategory) {
      setModel(mainCategory);
    }
  }, [isSuccess, mainCategory, setModel]);

  React.useEffect(() => {
    if (isLeaving) {
      history.push(programmingRoutes.paths.mainCategoriesListPage);
    }
  }, [isLeaving, history]);

  const saveData = React.useCallback(async () => {
    setIsSaving(true);

    // Save data to API here
    alert('Saving data...');

    try {
    } catch (e) {
    } finally {
      setIsSaving(false);
    }
  }, []);

  const cancelHandler = () => {
    // For conflicting modals
    setIsLeaving(true);
  };

  const handleOnClose = () => {
    setDeleteModalOpen(false);
  };

  const deleteHandler = async (mainCategory?: IMainCategory) => {
    if (!mainCategory) return;
    setIsDeleting(true);

    try {
      await onDelete(mainCategory.id);
      Toast.success('Success', `'${mainCategory.name}' has been removed.`);
      setIsLeaving(true);
    } catch (e: any) {
      if (e instanceof MainCategoriesHasAssociatedEntitiesDeleteError) {
        Toast.error('Remove all associated channels or VOD collections from this main category to delete.');
        setDeleteModalOpen(false);
      } else {
        Toast.error('Error', `Failed to remove '${mainCategory.name}'. Please try again.`);
      }
    } finally {
      setIsDeleting(false);
    }
  };

  if (isError) {
    return <CrudError error={error} />;
  }

  if (isLoading) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  return (
    <>
      <ConfirmRouteChange when={!isLeaving ? formState.isDirty : false} onSave={saveData} isValid={formState.isValid} />
      <Cover
        scrolling={true}
        gutter='large'
        coverTemplateHeight='100%'
        paddingX={{mobile: 'medium', wide: 'large'}}
        paddingTop={{mobile: 'medium', wide: 'large'}}
      >
        <Template label='header'>
          <Stack space='medium'>
            <Cluster growNthChild={1} justify='space-between' align='center' space='medium' wrap={false}>
              <Stack space='xxsmall'>
                <Heading level='h1' truncate={true} truncateBackgroundHover='shadow'>
                  {pristineModel?.name}
                </Heading>
                <Cluster align='flex-start' space='xxxxsmall' wrap={false}>
                  <Heading level='h5' color='secondary' whiteSpace='nowrap' id='activeRegionLabel'>
                    Active Region:
                  </Heading>
                  <Heading level='h5' color='primary'>
                    {pristineModel?.activeRegion?.toUpperCase()}
                  </Heading>
                </Cluster>
              </Stack>
              <Cluster space='small' align='center' wrap={false}>
                <Button
                  id='deleteBtn'
                  type='delete'
                  onClick={() => setDeleteModalOpen(true)}
                  permission={ableTo('MAIN_CATEGORIES_DELETE') ? '' : 'disabled'}
                >
                  Delete
                </Button>
              </Cluster>
            </Cluster>
            <Divider color='mist' marginBottom='xxxxxsmall' />
            <Cluster buttonGroup={true} justify='center' wrap={false}>
              {navigationTabs.map(({key, label, route, identifier}) => (
                <Button
                  id={identifier}
                  key={key}
                  active={activeTab === key}
                  onClick={() => history.push(route.replace(':id', id))}
                >
                  {label}
                </Button>
              ))}
            </Cluster>
          </Stack>
        </Template>
        <Template label='cover'>{navigationTabs.map(({key, component}) => activeTab === key && component)}</Template>
        <Template label='footer'>
          <Box background='onyx' paddingX='small' paddingY='small' marginX={{mobile: 'none', wide: 'largeNegative'}}>
            <Cluster justify='space-between'>
              <div></div>
              <Cluster space='xxxsmall'>
                <Button ghost={true} onClick={cancelHandler} id='cancelBtn' permission={canEdit ? '' : 'disabled'}>
                  Discard
                </Button>
                <Button
                  type='primary'
                  state={!formState.isValid || !formState.isDirty ? 'disabled' : isSaving ? 'thinking' : ''}
                  onClick={saveData}
                  id='saveBtn'
                  permission={canEdit ? '' : 'disabled'}
                >
                  Save Changes
                </Button>
              </Cluster>
            </Cluster>
          </Box>
        </Template>
      </Cover>

      <Dialog isOpen={deleteModalOpen} onClose={handleOnClose} width='37.5rem'>
        <Template label='header'>
          <Heading level='h2'>
            <Icon icon='warning' color='warning' space='xxsmall'>
              Are You Sure?
            </Icon>
          </Heading>
        </Template>
        <Template label='body'>
          <Stack space='medium'>
            <Paragraph color='secondary'>
              Are you sure you want to remove the category &apos;{`${pristineModel?.name}`}&apos;?
            </Paragraph>
          </Stack>
        </Template>
        <Template label='footer'>
          <Cluster justify='space-between'>
            <div></div>
            <Cluster space='small'>
              <Button ghost={true} size='small' onClick={handleOnClose}>
                Cancel
              </Button>
              <Button
                size='small'
                type='primary'
                onClick={() => deleteHandler(mainCategory)}
                state={isDeleting ? 'thinking' : ''}
              >
                Yes, Proceed
              </Button>
            </Cluster>
          </Cluster>
        </Template>
      </Dialog>
    </>
  );
};

export default MainCategoryEdit;
