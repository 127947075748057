import {
  Sidebar,
  Expand,
  Template,
  Box,
  Cover,
  Stack,
  Cluster,
  Icon,
  Heading,
  Divider,
  Label,
  Checkbox,
  FormItem,
  Select,
  ISelectOption,
  Button,
  Spinner,
  useValidateForm,
  Dialog,
} from '@pluto-tv/assemble';
import {useUserRegions} from 'helpers/useUserRegions';
import {orderBy} from 'lodash-es';
import {IVodCollection} from 'models/vodCollections';
import React from 'react';
import {withThousandsSeparator} from 'utils/thousands-separator';
import {vodCollection} from 'views/content/episode/edit/scheduling/__tests__/fixtures/vod';
import {vodCollectionListMock} from 'views/programming/mainCategories/mocks/vodCollectionListMock';
import {vodCollectionSearchValidator} from 'views/programming/mainCategories/validators';
import {useFindQuery as useFindDevicesQuery} from 'features/devices/devicesApi';
import useToggleSearchBarOnSlash from 'helpers/useToggleSearchBarOnSlash';
import ReorderVodCollectionList from 'views/programming/mainCategories/edit/vodCollectionList/ReorderVodCollectionList';
import VodCollectionTable from 'views/programming/mainCategories/edit/vodCollectionList/VodCollectionTable';
import {useAppPermissions} from 'app/permissions';

const vodCollectionData = vodCollectionListMock;

const VodCollectionList = React.memo(() => {
  const {ableTo} = useAppPermissions();

  const [searchExpanded, setSearchExpanded] = React.useState(true);
  const [filteredDeviceIncludedList, setFilteredDeviceIncludedList] = React.useState<ISelectOption[]>([]);
  const [filteredDeviceExcludedList, setfilteredDeviceExcludedList] = React.useState<ISelectOption[]>([]);
  const [reorderOpen, setReorderOpen] = React.useState(false);
  const [addVodOpen, setAddVodOpen] = React.useState(false);

  const {territories, isFetching: isTerritoriesFetching} = useUserRegions();
  const {data: deviceTypesList, isFetching: isDevicesTypesFetching} = useFindDevicesQuery({
    offset: 0,
    limit: 250,
    sort: 'name:asc',
  });

  useToggleSearchBarOnSlash(setSearchExpanded, searchExpanded);

  const {
    model: searchModel,
    // setModel: setSearchModel,
    setFields: setSearchFields,
    form: searchForm,
    state: searchState,
    reset: resetSearch,
    // onBlur: searchOnBlur,
    //change searchValidator
  } = useValidateForm<IVodCollection>(vodCollectionSearchValidator, 'onBlur');

  const handleVodSearchBarClear = React.useCallback(() => {
    // setSearchModel({});
    resetSearch();
  }, [resetSearch]);

  React.useEffect(() => {
    const platformsFilteredDeviceIncluded = deviceTypesList?.data
      .filter(i => !searchModel.devicesExcluded?.includes(i.platform))
      .map(a => ({label: a.platform, value: a.platform}));

    setFilteredDeviceIncludedList(platformsFilteredDeviceIncluded || []);
  }, [searchModel.devicesExcluded, deviceTypesList?.data]);

  React.useEffect(() => {
    const platformsFilteredDeviceExcluded = deviceTypesList?.data
      .filter(i => !searchModel.devicesIncluded?.includes(i.platform))
      .map(a => ({label: a.platform, value: a.platform}));

    setfilteredDeviceExcludedList(platformsFilteredDeviceExcluded || []);
  }, [searchModel.devicesIncluded, deviceTypesList?.data]);

  const handleReorderSave = async () => {
    setReorderOpen(false);
    //refetch vod collection list

    alert('Saving reorder');
  };

  if (isTerritoriesFetching || isDevicesTypesFetching) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} minHeight='9.375rem' size='xlarge' />
      </Box>
    );
  }

  return (
    <Sidebar fullHeight={true}>
      <Expand width='18.75rem' height='100%' fullHeightContainer={true} isExpanded={searchExpanded}>
        <Template label='expandable'>
          <Box background='pewter' paddingY={'medium'} paddingRight='medium' paddingLeft={'medium'} fullHeight={true}>
            <Cover scrolling={true} gutter='medium'>
              <Template label='header'>
                <Stack space='medium'>
                  <Cluster align='center' justify='space-between'>
                    <Icon icon='tune' space='small' size='large' iconAlign='center'>
                      <Heading level='h4'>Search Filters</Heading>
                    </Icon>
                    <Icon
                      icon='collapseleft'
                      id='closeFilter'
                      size='large'
                      onClick={() => setSearchExpanded(!searchExpanded)}
                    />
                  </Cluster>
                  <Divider color='graphite' />
                </Stack>
              </Template>
              <Template label='cover'>
                <form
                  onSubmit={ev => {
                    ev.preventDefault();
                    setTimeout(() => alert('Searching...'));
                  }}
                >
                  <Box paddingY='small'>
                    <Stack space='small'>
                      {/* Using this to allow pressing enter to submit form */}
                      <input type='submit' style={{display: 'none'}} />
                      <Stack space='xlarge'>
                        <Stack space='small'>
                          <Label>Status</Label>
                          <Stack space='small'>
                            <Checkbox
                              label='Published'
                              onChange={val => setSearchFields({enabled: val})}
                              value={searchModel.enabled}
                              id='publishedFilter'
                            />
                            <Checkbox
                              label='Unpublished'
                              onChange={val => setSearchFields({unpublished: val})}
                              value={searchModel.unpublished}
                              id='unPublishedFilter'
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                      <Divider color='graphite' />
                      <FormItem {...searchForm.territoriesIncluded}>
                        <Select
                          clearable={true}
                          multiselect={true}
                          placeholder='Select Territory'
                          value={(searchModel.territoriesIncluded || [])?.map(d => ({label: d, value: d}))}
                          onChange={val =>
                            setSearchFields({
                              territoriesIncluded: (val || []).map(v => v.value),
                            })
                          }
                          options={(territories || []).map(te => ({
                            label: `${te.name} (${te.id})`,
                            value: te.id,
                          }))}
                          predicate='value'
                          id='territoriesIncluded'
                        />
                      </FormItem>
                      <FormItem {...searchForm.devicesIncluded}>
                        <Select
                          id='devicesIncluded'
                          clearable={true}
                          placeholder='Select Devices'
                          multiselect={true}
                          value={searchModel.devicesIncluded?.map(p => ({label: p, value: p}))}
                          options={filteredDeviceIncludedList}
                          onChange={value => {
                            setSearchFields({
                              devicesIncluded: ((value as ISelectOption[]) || []).map(v => v.value),
                            });
                          }}
                          predicate='value'
                          searchable={true}
                          onSearch={term =>
                            orderBy(
                              (filteredDeviceIncludedList || [])
                                .filter(p => p.label.toLowerCase().startsWith(term.toLowerCase()))
                                .map(p => ({label: p.label, value: p.value}), 'label'),
                            ) || ([] as ISelectOption[])
                          }
                        />
                      </FormItem>
                      <FormItem {...searchForm.devicesExcluded}>
                        <Select
                          id='devicesExcluded'
                          clearable={true}
                          placeholder='Select Devices'
                          multiselect={true}
                          value={searchModel.devicesExcluded?.map(p => ({label: p, value: p}))}
                          options={filteredDeviceExcludedList}
                          onChange={value => {
                            setSearchFields({
                              devicesExcluded: ((value as ISelectOption[]) || []).map(v => v.value),
                            });
                          }}
                          predicate='value'
                          searchable={true}
                          onSearch={term =>
                            orderBy(
                              (filteredDeviceExcludedList || [])
                                .filter(p => p.label.toLowerCase().startsWith(term.toLowerCase()))
                                .map(p => ({label: p.label, value: p.value}), 'label'),
                            ) || ([] as ISelectOption[])
                          }
                        />
                      </FormItem>
                      <FormItem {...searchForm.kidsMode}>
                        <Select
                          id='kids'
                          clearable={true}
                          placeholder='Select Kids Only'
                          value={
                            searchModel.kidsMode === undefined
                              ? undefined
                              : searchModel.kidsMode
                              ? {label: 'Yes'}
                              : {label: 'No'}
                          }
                          options={[{label: 'Yes'}, {label: 'No'}]}
                          onChange={value => {
                            if (!value) {
                              setSearchFields({kidsMode: undefined});
                            } else {
                              setSearchFields({kidsMode: value.label === 'Yes'});
                            }
                          }}
                        />
                      </FormItem>
                      <FormItem {...searchForm.plutoOfficeOnly}>
                        <Select
                          id='officeOnly'
                          clearable={true}
                          placeholder='Select Office Only'
                          options={[{label: 'Yes'}, {label: 'No'}]}
                          value={
                            searchModel.plutoOfficeOnly === undefined
                              ? undefined
                              : searchModel.plutoOfficeOnly
                              ? {label: 'Yes'}
                              : {label: 'No'}
                          }
                          onChange={value => {
                            if (!value) {
                              setSearchFields({plutoOfficeOnly: undefined});
                            } else {
                              setSearchFields({plutoOfficeOnly: value.label === 'Yes'});
                            }
                          }}
                        />
                      </FormItem>
                    </Stack>
                  </Box>
                </form>
              </Template>
              <Template label='footer'>
                <Cluster justify='space-between'>
                  <div></div>
                  <Cluster space='small'>
                    <Button
                      ghost={true}
                      state={/*isFetching ? 'disabled' :*/ ''}
                      onClick={() => handleVodSearchBarClear()}
                      id='clearButton'
                    >
                      Clear
                    </Button>
                    <Button
                      type='primary'
                      state={!searchState.isDirty ? 'disabled' : /*isFetching ? 'thinking' :*/ ''}
                      onClick={() => alert('Searching...')}
                      id='searchButton'
                    >
                      Search
                    </Button>
                  </Cluster>
                </Cluster>
              </Template>
            </Cover>
          </Box>
        </Template>
      </Expand>
      <Cover
        scrolling={true}
        gutter='large'
        coverTemplateHeight='100%'
        overflow='auto'
        padding={{mobile: 'medium', wide: 'large'}}
      >
        <Template label='header'>
          <Cluster justify='space-between' align='center' space='medium'>
            <Cluster align='end' space='small'>
              <Heading level='h1'>VOD Collections</Heading>
              <Cluster space='xxsmall' align='center'>
                <Icon
                  icon='tune'
                  space='xxxsmall'
                  verticalAlign='bottom'
                  lineHeight='0px'
                  onClick={() => setSearchExpanded(!searchExpanded)}
                  id='expandFilter'
                >
                  {withThousandsSeparator(vodCollectionData?.metadata.totalCount || 0)} Items
                </Icon>
              </Cluster>
            </Cluster>
            <Cluster space='small' align='center'>
              <>
                <Button
                  id='reorderBtn'
                  onClick={() => setReorderOpen(true)}
                  state={vodCollectionData.data.length === 0 ? 'disabled' : ''}
                  permission={ableTo('MAIN_CATEGORIES_EDIT') ? '' : 'disabled'}
                >
                  Reorder
                </Button>

                {reorderOpen && (
                  <ReorderVodCollectionList
                    setReorderOpen={setReorderOpen}
                    handleReorderSave={handleReorderSave}
                    reorderOpen={reorderOpen}
                  />
                )}
              </>
              <>
                <Button
                  id='addBtn'
                  type='primary'
                  onClick={() => setAddVodOpen(true)}
                  permission={ableTo('MAIN_CATEGORIES_EDIT') ? '' : 'disabled'}
                >
                  + ADD
                </Button>
                <Dialog isOpen={addVodOpen} onClose={() => setAddVodOpen(false)} width='42.8125rem' height='30rem'>
                  <Template label='header'>
                    <Heading level='h2'>Add VOD Collection</Heading>
                  </Template>
                </Dialog>
              </>
            </Cluster>
          </Cluster>
        </Template>
        <Template label='cover'>
          <Box
            background='pewter'
            borderTop={true}
            borderSize='2px'
            borderColor='cavern'
            paddingTop={(vodCollection?.data.length as number) > 0 ? 'xsmall' : 'medium'}
            paddingBottom='none'
            paddingX={'large'}
            fullHeight={true}
          >
            <VodCollectionTable rows={vodCollectionData?.data || []} id='vodCollectionList' />
          </Box>
        </Template>
      </Cover>
    </Sidebar>
  );
});

VodCollectionList.displayName = 'VodCollectionList';
export default VodCollectionList;
