import React from 'react';
import {
  Template,
  Heading,
  Stack,
  Cluster,
  Button,
  useValidateForm,
  Dialog,
  FormItem,
  TextInput,
  Select,
  Box,
} from '@pluto-tv/assemble';
import {mainCategoriesCreateValidator, IMainCategoriesCreate} from 'views/programming/mainCategories/validators';
import {useUserRegions} from 'helpers/useUserRegions';

interface IMainCategoriesCreateFormProps {
  onCancel: () => void;
  onCreate: (newMainCategory: IMainCategoriesCreate, navigateTo?: boolean) => void;
  canCreate: boolean;
  isCreating: boolean;
}

const MainCategoriesCreateForm = React.memo(({onCancel, onCreate, isCreating}: IMainCategoriesCreateFormProps) => {
  const [userChangedSlug, setUserChangedSlug] = React.useState(false);
  const {activeRegions} = useUserRegions();
  const {
    model,
    onChange,
    form,
    onBlur,
    setFields,
    state: formState,
  } = useValidateForm<IMainCategoriesCreate>(mainCategoriesCreateValidator, 'immediate');
  const requiredFieldsComplete = model.name && model.activeRegion;

  const handleCancel = () => {
    onCancel();
  };

  const handleCreate = async () => {
    onCreate(model as IMainCategoriesCreate);
  };

  const handleCreateAndEdit = async () => {
    onCreate(model as IMainCategoriesCreate, true);
  };

  const createCategorySlug = React.useCallback(() => {
    if (model.name && model.activeRegion && !userChangedSlug) {
      const categorySlug = `${model.name}-${model.activeRegion}`
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/^-|-$/g, '');
      setFields({slug: categorySlug});
    } else if (model.slug && (!model.name || !model.activeRegion) && !userChangedSlug) {
      setFields({slug: ''});
    }
  }, [model.activeRegion, model.slug, model.name, setFields, userChangedSlug]);

  React.useEffect(() => {
    createCategorySlug();
  }, [createCategorySlug, model.activeRegion, model.name, userChangedSlug]);

  return (
    <Dialog isOpen={true} onClose={() => onCancel()} width='42.8125rem' id='create-main-category-form-dialog'>
      <Template label='header'>
        <Heading level='h2'>Add Main Category</Heading>
      </Template>
      <Template label='body'>
        <Box id='addMainCategoryContent'>
          <form
            id='createMainCategory'
            onSubmit={ev => {
              ev.preventDefault();
            }}
          >
            <Stack space='small'>
              <FormItem {...form.name} onBlur={() => onBlur('name')}>
                <TextInput
                  id='displayName'
                  onChange={value => {
                    onChange('name', value);
                  }}
                  value={model.name}
                />
              </FormItem>
              <FormItem {...form.activeRegion}>
                <Select
                  appendToBody
                  id='activeRegion'
                  clearable={true}
                  placeholder='Select Active Region'
                  value={
                    {
                      value: model.activeRegion,
                      label: activeRegions.find(ar => ar.code === model.activeRegion)?.name,
                    } as any
                  }
                  onChange={val =>
                    setFields({
                      activeRegion: val?.value,
                    })
                  }
                  options={activeRegions.map(ar => ({
                    label: `${ar.name} (${ar.code})`,
                    value: ar.code,
                  }))}
                  predicate='value'
                />
              </FormItem>
              <FormItem
                {...form.slug}
                onBlur={() => {
                  onBlur('slug');

                  if (userChangedSlug && !model.slug) {
                    setUserChangedSlug(false);
                  }
                }}
              >
                <TextInput
                  id='slug'
                  onChange={value => {
                    onChange('slug', value);
                    setUserChangedSlug(true);
                  }}
                  value={model.slug}
                />
              </FormItem>
            </Stack>
          </form>
        </Box>
      </Template>
      <Template label='footer'>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button ghost={true} onClick={handleCancel} id='cancelBtn'>
              Cancel
            </Button>
            <Button
              type='primary'
              onClick={() => handleCreate()}
              id='createBtn'
              state={
                !formState.isValid || !formState.isDirty || !requiredFieldsComplete
                  ? 'disabled'
                  : isCreating
                  ? 'thinking'
                  : ''
              }
            >
              Create
            </Button>
            <Button
              type='primary'
              onClick={handleCreateAndEdit}
              id='createAndEditBtn'
              state={
                !formState.isValid || !formState.isDirty || !requiredFieldsComplete
                  ? 'disabled'
                  : isCreating
                  ? 'thinking'
                  : ''
              }
            >
              Create and Edit
            </Button>
          </Cluster>
        </Cluster>
      </Template>
    </Dialog>
  );
});

export default MainCategoriesCreateForm;
