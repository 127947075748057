export const vodCollection = {
  metadata: {
    offset: 0,
    limit: 1000,
    sort: 'name:asc',
    totalCount: 3,
  },
  data: [
    {
      id: '63fe8698dff38e0008379b77',
      name: 'Ad Test',
      displayName: '',
      order: 183,
      enabled: true,
      plutoOfficeOnly: true,
      enabledAt: '2023-02-28T22:58:05.454Z',
      description: '',
      regionFilter: {
        include: ['US'],
        exclude: null,
      },
      distribution: {
        include: [
          'android',
          'androidcharterav',
          'androidmobilehuawei',
          'androidmobiletelcel',
          'androidmobileverizon',
          'androidtvdeutschetelekom',
          'androidtvdirectv',
          'androidtvdirectvhome',
          'androidtvhilton',
          'androidtvliveverizon',
          'androidtvtivoplus',
          'androidtvverizon',
          'catalyst',
          'chromecast',
          'claroandroid',
          'comcastx1',
          'comcastxclass',
          'contour',
          'ctv',
          'desktop',
          'embed',
          'firetvliveverizon',
          'firetvverizon',
          'gom',
          'googletv',
          'googletvlive',
          'gracenoteepg',
          'hisense',
          'ios',
          'km',
          'lgwebos',
          'lifefitness',
          'livectv',
          'macos',
          'marriott',
          'mobile',
          'msn',
          'my5',
          'now-tv',
          'oculus',
          'opera-other',
          'philipstv',
          'plutotvpublishers',
          'ps4',
          'ps5',
          'roku',
          'rokuchannel',
          'samsung-tizen',
          'samsung-tvplus',
          'samsungdaily',
          'samsungmobiletvplus',
          'sky-q',
          'skyticket',
          'slingtv',
          'stbclaro',
          'stbverizon',
          'tclchannel',
          'telefonica',
          'tivo',
          'tvos',
          'tvosdemo',
          'viacandroidfiretv',
          'viacandroidmobile',
          'viacandroidtv',
          'viacios',
          'viacroku',
          'viactvos',
          'viacweb',
          'viacxboxone',
          'vidaahisense',
          'virginmedia',
          'virginmedia360',
          'vizio',
          'viziovia',
          'viziowatchfree',
          'watchfreeplus',
          'web',
          'windows',
          'xboxone',
          'xboxseriesx',
          'xiaomitv',
          'firetvkepler',
        ],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2023-02-28T22:56:24.053Z',
      updatedAt: '2024-03-08T20:24:38.367Z',
      disabledAt: '2023-02-28T22:56:24.053Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '63fe8698dff38e0008379b78',
          catId: '61d62705dd139500071324ef',
          order: 5,
          name: 'Test',
        },
      ],
      customReferences: null,
      archived: false,
      heroCarousel: false,
      titleCount: 6,
      settings: null,
    },
    {
      id: '658245e76e4f6a00088714ad',
      name: 'AD Test 2',
      displayName: 'Ad Format Test 2',
      order: 224,
      enabled: true,
      plutoOfficeOnly: true,
      enabledAt: '2023-12-20T01:39:51.311Z',
      description: '',
      regionFilter: {
        include: ['US'],
        exclude: null,
      },
      distribution: {
        include: [
          '10playandroidmobile',
          '10playandroidtv',
          '10playchromecast',
          '10playfetchtv',
          '10playfiretv',
          '10playfoxtel',
          '10playhbbtv',
          '10playhbbtvfreeview',
          '10playhisense',
          '10playios',
          '10playlgwebos',
          '10playsamsungtizen',
          '10playtelstra',
          '10playtvos',
          '10playweb',
          '10playxboxone',
          'android',
          'androidautomotiveos',
          'androidcharterav',
          'androidmobilehuawei',
          'androidmobiletelcel',
          'androidmobiletelecomar',
          'androidmobileverizon',
          'androidtvbouygues',
          'androidtvdeutschetelekom',
          'androidtvdirectv',
          'androidtvdirectvhome',
          'androidtvhilton',
          'androidtvliveverizon',
          'androidtvtd',
          'androidtvtimvision',
          'androidtvtivoplus',
          'androidtvverizon',
          'catalyst',
          'chromecast',
          'claroandroid',
          'comcastx1',
          'comcastxclass',
          'contour',
          'ctv',
          'desktop',
          'deutschetelekompromo',
          'embed',
          'firetvkepler',
          'firetvliveverizon',
          'firetvverizon',
          'freeviewstb',
          'gom',
          'googletv',
          'googletvlive',
          'gracenoteepg',
          'hisense',
          'ios',
          'km',
          'lgchannels',
          'lgchannelshome',
          'lgwebos',
          'lifefitness',
          'livectv',
          'macos',
          'marriott',
          'mobile',
          'msn',
          'my5',
          'now-tv',
          'oculus',
          'opera-other',
          'philipstv',
          'plutotvpublishers',
          'ps4',
          'ps5',
          'roku',
          'rokuchannel',
          'ruutuandroidmobile',
          'ruutuandroidtv',
          'ruutuchromecast',
          'ruutuelisaviihde',
          'ruutuios',
          'ruutulgwebos',
          'ruutupanasonic',
          'ruutuphilipstv',
          'ruutusamsungtizen',
          'ruututvos',
          'ruutuvestel',
          'ruutuweb',
          'samsung-tizen',
          'samsung-tvplus',
          'samsungdaily',
          'samsungmobiletvplus',
          'sky-q',
          'skyticket',
          'slingtv',
          'stbclaro',
          'stbverizon',
          'tclchannel',
          'telefonica',
          'tivo',
          'tivovestelweb',
          'tvos',
          'tvosdemo',
          'viacandroidfiretv',
          'viacandroidmobile',
          'viacandroidtv',
          'viacios',
          'viacroku',
          'viactvos',
          'viacweb',
          'viacxboxone',
          'vidaahisense',
          'virginmedia',
          'virginmedia360',
          'vizio',
          'viziovia',
          'viziowatchfree',
          'watchfreeplus',
          'web',
          'webautomotive',
          'windows',
          'xboxone',
          'xboxseriesx',
          'xiaomitv',
          'youviewstb',
        ],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2023-12-20T01:39:51.316Z',
      updatedAt: '2024-03-08T20:24:38.446Z',
      disabledAt: '',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '658245e76e4f6a00088714ae',
          catId: '61d62705dd139500071324ef',
          order: 277,
          name: 'Test',
        },
      ],
      customReferences: null,
      archived: false,
      heroCarousel: false,
      titleCount: 5,
      settings: null,
    },
    {
      id: '5963dbe4491f7ff12f5a718c',
      name: 'Movies: Action',
      displayName: 'Action',
      order: 17,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '2018-04-18T00:09:46.041Z',
      description: '',
      regionFilter: {
        include: ['US'],
        exclude: ['GB'],
      },
      distribution: {
        include: [
          'desktop',
          'ctv',
          'livectv',
          'chromecast',
          'my5',
          'roku',
          'ps4',
          'rokuchannel',
          'ps3',
          'vizio',
          'sky-q',
          'xbox360',
          'viziowatchfree',
          'skyticket',
          'virginmedia',
          'now-tv',
          'samsung-tizen',
          'xboxone',
          'gom',
          'web',
          'android',
          'km',
          'samsung-orsay',
          'ios',
          'samsung-tvplus',
          'msn',
          'lgwebos',
          'hisense',
          'comcastx1',
          'tivo',
          'viziovia',
          'contour',
          'tvos',
          'mobile',
          'opera-other',
          'stbverizon',
          'androidmobileverizon',
          'androidtvverizon',
          'androidtvliveverizon',
          'firetvverizon',
          'firetvliveverizon',
          'androidmobiletelcel',
          'ps5',
          'xboxseriesx',
          'slingtv',
          'windows',
          'tclchannel',
          'oculus',
          'androidtvhilton',
          'androidcharterav',
          'androidtvdirectv',
          'comcastxclass',
          'androidtvdirectvhome',
          'androidautomotiveos',
          'lgchannels',
          'firetvkepler',
          'visionos',
        ],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://s3.amazonaws.com/pluto.tv/vodcategories/5963dbe4491f7ff12f5a718c/iconSvg-1576022938055.svg',
      },
      iconPng: {
        path: 'https://s3.amazonaws.com/pluto.tv/vodcategories/5963dbe4491f7ff12f5a718c/iconPng-1576022943538.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2017-07-10T19:56:20.652Z',
      updatedAt: '2024-03-08T20:24:37.986Z',
      disabledAt: '2017-07-10T19:56:20.652Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '618c6455ba73880008f2ed8f',
          catId: '618c3cc54a27070007796ab4',
          order: 13,
          name: 'Movies',
        },
      ],
      customReferences: null,
      archived: false,
      heroCarousel: false,
      titleCount: 654,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
  ],
};
