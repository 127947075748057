import {usePreferencesQuery, useUpdatePreferencesMutation} from 'features/users/usersApi';

interface IUsePreferences<TSearch> {
  isFetching: boolean;
  isError: boolean;
  preferences: any;
  searches: TSearch[];
  updateSavedSearches: (searchType: string, searchList: TSearch[]) => Promise<unknown>;
}

export const usePreferences = <TSearch,>(entity: string): IUsePreferences<TSearch> => {
  const [updatePreferences] = useUpdatePreferencesMutation();

  const {isFetching, isError, data: preferences} = usePreferencesQuery();

  const entitySearches: TSearch[] = preferences?.searches?.[entity] || [];

  const updateSavedSearches = async (searchType = entity, searchList: TSearch[]) => {
    return updatePreferences({
      ...preferences,
      searches: {
        ...preferences?.searches,
        [searchType]: searchList,
      },
    }).unwrap();
  };

  return {
    searches: entitySearches,
    isError,
    isFetching,
    preferences,
    updateSavedSearches,
  };
};
