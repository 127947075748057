import * as React from 'react';
import {
  Box,
  Cluster,
  ContentBoxes,
  ContentBox,
  Cover,
  Expand,
  Heading,
  Icon,
  Sidebar,
  Stack,
  Template,
  Button,
  Spinner,
  Toast,
  trimModel,
  Paragraph,
  Click,
} from '@pluto-tv/assemble';
import MainCategoriesTable from 'components/mainCategoriesList/components/MainCategoriesTable';
import MainCategoriesSearch, {categoryNameId} from 'components/mainCategoriesList/components/MainCategoriesSearch';
import useToggleSearchBarOnSlash from 'helpers/useToggleSearchBarOnSlash';
import MainCategoriesReorderPanel from 'components/mainCategoriesList/components/MainCategoriesReorderPanel';
import MainCategoriesCreateForm from 'components/mainCategoriesList/components//MainCategoriesCreateForm';
import {IMainCategory, IMainCategorySearch} from 'models/mainCategories';
import {useHistory} from 'react-router-dom';
import routes from 'routes/programming.routes';
import {useMainCategoriesSearchlLazyLoadProvider} from 'components/mainCategoriesList/providers/MainCategoriesSearchLazyProvider';
import {useUserRegions} from 'helpers/useUserRegions';
import CrudError from 'components/crudError';
import NotAuthorized from 'components/notAuthorized';
import {useAppPermissions} from 'app/permissions';
import {useDelete} from 'components/mainCategoriesList/hooks/useDelete';
import {MainCategoriesHasAssociatedEntitiesDeleteError} from 'components/mainCategoriesList/hooks/customErrors';
import {IMainCategoriesCreate} from 'views/programming/mainCategories/validators';
import {useInsertMutation, useReorderMutation} from 'features/mainCategories/mainCategoriesApi';

export const INIT_PARAMS = {
  sort: ['order:asc'],
};

const DEFAULT_PNG_IMAGE = 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png';
const DEFAULT_SVG_IMAGE = 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg';

const MainCategoriesList = React.memo(() => {
  const history = useHistory();
  const {ableTo} = useAppPermissions();
  const {onDelete} = useDelete();
  const [insertMainCategory] = useInsertMutation();
  const [reorderMutation] = useReorderMutation();

  const [searchExpanded, setSearchExpanded] = React.useState(true);
  const [reorderOpen, setReorderOpen] = React.useState<boolean>(false);
  const [createOpen, setCreateOpen] = React.useState<boolean>(false);
  const [isSearchActive, setIsSearchActive] = React.useState(false);
  const [isCreating, setIsCreating] = React.useState(false);

  useToggleSearchBarOnSlash(setSearchExpanded, searchExpanded);

  const {
    reset,
    items,
    totalCount,
    lazyLoad,
    isLazyLoading,
    isError,
    error,
    isLoading,
    searchParams,
    search: searchMainCategories,
  } = useMainCategoriesSearchlLazyLoadProvider();

  const {activeRegions, isFetching: isUserRegionsFetching, isError: isUserRegionsError} = useUserRegions();

  const handleEdit = (id: string) => history.push(routes.paths.mainCategoriesEditDetailsPage.replace(':id', id));

  const handleDelete = async (mainCategory: IMainCategory) => {
    try {
      await onDelete(mainCategory.id);
      Toast.success('Success', `'${mainCategory.name}' has been removed.`);
    } catch (e: any) {
      if (e instanceof MainCategoriesHasAssociatedEntitiesDeleteError) {
        Toast.error('Remove all associated channels or VOD collections from this main category to delete.');
      } else {
        Toast.error('Error', `Failed to remove '${mainCategory.name}'. Please try again.`);
      }
    }
  };

  const handleApplyOrder = async (region: string, items: IMainCategory[]) => {
    try {
      await reorderMutation({
        region: region.toLowerCase(),
        'main-categories': items.map(({id, order}) => ({id, order})),
      });
      Toast.success('Success', 'Order saved.');
      setReorderOpen(false);
    } catch (e) {
      Toast.error('Error', 'Failed to save order, please try again.');
    }
  };

  const getMainCategories = React.useCallback(
    (params: IMainCategorySearch) => {
      searchMainCategories(params);
    },
    [searchMainCategories],
  );

  const handleSearch = (search: IMainCategorySearch) => {
    setIsSearchActive(true);
    const params = {...INIT_PARAMS, ...search};
    searchMainCategories(params);
  };

  const handleClear = () => {
    if (isSearchActive) {
      setIsSearchActive(false);
      reset(true);
    }
    searchMainCategories(INIT_PARAMS);
  };

  const handleSort = (search: IMainCategorySearch) => {
    const params = {...(searchParams || {}), ...search};
    getMainCategories(params);
  };

  React.useEffect(() => {
    if (activeRegions?.length === 0) return;

    searchMainCategories({...INIT_PARAMS, region: activeRegions?.map(ar => ar.code.toLowerCase())});
  }, [activeRegions, searchMainCategories]);

  React.useEffect(() => {
    if (searchExpanded) {
      const categoryNameInput = document.getElementById(categoryNameId);
      categoryNameInput?.focus({
        preventScroll: true,
      });
    }
  }, [searchExpanded]);

  if (isUserRegionsFetching) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  const errorNotFound = error && 'status' in error && error.status === 404;
  if ((isError && !errorNotFound) || isUserRegionsError) {
    return <CrudError error='Error loading page data' />;
  }

  if (!ableTo('MAIN_CATEGORIES_VIEW')) {
    return <NotAuthorized />;
  }

  const handleCreate = async (newMainCategory: IMainCategoriesCreate, navigateTo = false) => {
    let mainCategoryId: string | undefined;

    try {
      setIsCreating(true);
      const postModel: Partial<IMainCategory> = {
        ...newMainCategory,
        plutoOfficeOnly: true,
        hideMainCategory: false,
        pngImageUrl: {path: DEFAULT_PNG_IMAGE},
        svgImageUrl: {path: DEFAULT_SVG_IMAGE},
      };

      const newMainCategoryCreated = await insertMainCategory(trimModel(postModel, 'name')).unwrap();
      mainCategoryId = newMainCategoryCreated.id!;

      const toastMsg = navigateTo ? (
        'Main Category Created'
      ) : (
        <Stack space='xxsmall'>
          <Paragraph>Main Category Created</Paragraph>
          <Click
            underline={true}
            hoverColor='white'
            onClick={() =>
              history.push(routes.paths.mainCategoriesEditDetailsPage.replace(':id', mainCategoryId as string))
            }
          >
            View Category: {postModel.name}
          </Click>
        </Stack>
      );
      Toast.success('Success', toastMsg, 8000);

      if (navigateTo) {
        history.push(routes.paths.mainCategoriesEditDetailsPage.replace(':id', mainCategoryId as string));
      } else {
        setCreateOpen(false);
        getMainCategories(searchParams);
      }
    } catch (e) {
      Toast.error('Error', (e as any).data.message);
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <>
      <Sidebar fullHeight={true}>
        <Expand width='18.75rem' height='100%' fullHeightContainer={true} isExpanded={searchExpanded}>
          <Template label='expandable'>
            <Box background='pewter' paddingY={'medium'} paddingRight='medium' paddingLeft={'medium'} fullHeight={true}>
              <MainCategoriesSearch setIsExpanded={setSearchExpanded} onSearch={handleSearch} onClear={handleClear} />
            </Box>
          </Template>
        </Expand>
        <Cover
          scrolling={true}
          gutter='medium'
          coverTemplateHeight='100%'
          overflow='auto'
          padding={{mobile: 'medium', wide: 'large'}}
        >
          <Template label='header'>
            <Cluster justify='space-between' align='center' space='medium'>
              <Stack space='xxxsmall'>
                <Cluster align='end' space='small'>
                  <Box paddingBottom='xxxsmall'>
                    <Heading level='h1'>Main Categories</Heading>
                  </Box>
                  <Box paddingBottom='xxxsmall'>
                    <Cluster space='xxsmall' align='center'>
                      <Icon
                        id='expandFilters'
                        icon='tune'
                        space='xxxsmall'
                        verticalAlign='bottom'
                        lineHeight='0px'
                        onClick={() => setSearchExpanded(!searchExpanded)}
                      >
                        {`${totalCount} Items`}
                      </Icon>
                    </Cluster>
                  </Box>
                </Cluster>
              </Stack>
              <Cluster space='small' align='center'>
                <Button
                  id='reorderBtn'
                  onClick={() => setReorderOpen(true)}
                  permission={ableTo('MAIN_CATEGORIES_EDIT') ? '' : 'disabled'}
                >
                  REORDER
                </Button>
                {reorderOpen && (
                  <MainCategoriesReorderPanel onClose={() => setReorderOpen(false)} onApplyOrder={handleApplyOrder} />
                )}
                <Button
                  id='AddMainCatBtn'
                  type='primary'
                  onClick={() => setCreateOpen(true)}
                  permission={ableTo('MAIN_CATEGORIES_CREATE') ? '' : 'disabled'}
                >
                  + ADD MAIN CATEGORY
                </Button>
                {createOpen && (
                  <MainCategoriesCreateForm
                    onCancel={() => setCreateOpen(false)}
                    onCreate={handleCreate}
                    canCreate={true}
                    isCreating={isCreating}
                  />
                )}
              </Cluster>
            </Cluster>
          </Template>
          <Template label='cover'>
            <Cover gutter='medium' coverTemplateHeight='100%' scrolling={true}>
              <Template label='header'>
                <div></div>
              </Template>
              <Template label='cover'>
                <ContentBoxes layout='cover'>
                  <Template label='contentBoxesCover'>
                    <ContentBox>
                      <Template label='content'>
                        <MainCategoriesTable
                          searchParams={searchParams}
                          lazyLoad={lazyLoad}
                          isLazyLoading={isLazyLoading}
                          isError={isError && !errorNotFound}
                          isLoading={isLoading}
                          items={items}
                          onSearch={handleSort}
                          isSearchActive={isSearchActive}
                          onEdit={handleEdit}
                          onDelete={handleDelete}
                        />
                      </Template>
                    </ContentBox>
                  </Template>
                </ContentBoxes>
              </Template>
            </Cover>
          </Template>
        </Cover>
      </Sidebar>
    </>
  );
});

MainCategoriesList.displayName = 'MainCategoriesList';
export default MainCategoriesList;
