import * as React from 'react';

import {
  Box,
  Copy,
  ContentBoxes,
  ContentBox,
  ContentBoxColumn,
  FormItem,
  Grid,
  Paragraph,
  Select,
  Spinner,
  Stack,
  Textarea,
  TextInput,
  Toggle,
  ISelectOption,
} from '@pluto-tv/assemble';
import {orderBy, sortBy} from 'lodash-es';

// Keep compatibility with CMS Classic.
// See https://plutotv.atlassian.net/browse/CMS-2275
import {default as isoLanguages} from 'iso-639-1';

import {useAppPermissions} from 'app/permissions';
import {useFindQuery as useFindMainCategoriesQuery} from 'features/mainCategories/mainCategoriesApi';
import {useFindQuery as useFindLegacyCategoriesQuery} from 'features/channelCategories/channelCategoriesApi';

import CrudError from 'components/crudError';

import {INestedChannelProps} from '../nestedPropsInterface';
import {IMainCategory} from 'models/mainCategories';
import {useLanguageList} from 'helpers/useLanguageList';
interface IChannelProps extends INestedChannelProps {
  dirtyFields: any;
}

export default ({model, setFields, onBlur, onChange, form, dirtyFields}: IChannelProps): JSX.Element => {
  const {permissions} = useAppPermissions();

  const {
    data: mainCategories,
    isError: isMainCategoriesError,
    error: mainCategoriesError,
    isFetching: isMainCategoriesFetching,
  } = useFindMainCategoriesQuery();

  const {
    data: legacyCategories,
    isError: isLegacyCategoriesError,
    error: legacyCategoriesError,
    isFetching: isLegacyCategoriesFetching,
  } = useFindLegacyCategoriesQuery();

  const {languageList, isLanguagesError, languagesError, isLanguagesFetching, searchLanguages} = useLanguageList();

  const [mainCategoriesList, setMainCategoriesList] = React.useState<ISelectOption[]>([]);

  function handleFilteredList(mainCategoriesArray: IMainCategory[], activeRegion: string) {
    const list = mainCategoriesArray
      .filter((mc: IMainCategory) => mc.activeRegion === activeRegion)
      .map((mc: IMainCategory) => ({label: mc.name, value: mc.id, order: mc.order}));

    return sortBy(list, 'label');
  }

  React.useEffect(() => {
    const list: ISelectOption[] = handleFilteredList(
      mainCategories?.data || [],
      model.activeRegion?.toLowerCase() as string,
    );
    setMainCategoriesList(list);
  }, [mainCategories, model.activeRegion]);

  if (isMainCategoriesError || isLegacyCategoriesError || isLanguagesError) {
    const err = mainCategoriesError || legacyCategoriesError || languagesError;
    return <CrudError error={err} />;
  }

  if (isMainCategoriesFetching || isLegacyCategoriesFetching || isLanguagesFetching) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} minHeight='9.375rem' size='xlarge' />
      </Box>
    );
  }

  return (
    <ContentBoxes layout='columns'>
      <ContentBoxColumn>
        <ContentBox title='About'>
          <Stack space='medium'>
            <FormItem {...form.name} onBlur={() => onBlur('name')} permission={permissions.CHANNEL_EDIT}>
              <TextInput onChange={value => onChange('name', value)} value={model.name} id='name' />
            </FormItem>
            <FormItem {...form.number} onBlur={() => onBlur('number')} permission={permissions.CHANNEL_EDIT}>
              <TextInput type='number' onChange={value => onChange('number', value)} value={model.number} id='number' />
            </FormItem>
            <FormItem {...form.summary} onBlur={() => onBlur('summary')} permission={permissions.CHANNEL_EDIT}>
              <Textarea
                onChange={value => onChange('summary', value)}
                value={model.summary}
                id='summary'
                minHeight='6.25rem'
              />
            </FormItem>
            <FormItem {...form.description} onBlur={() => onBlur('description')} permission={permissions.CHANNEL_EDIT}>
              <Textarea
                onChange={value => onChange('description', value)}
                value={model.description}
                id='description'
                minHeight='6.25rem'
              />
            </FormItem>
            <FormItem {...form.categories} permission={permissions.CHANNEL_EDIT}>
              <Select
                onChange={value =>
                  setFields({
                    categories: (value || []).map(v => {
                      return {
                        catId: v.value,
                        order: v.order,
                        name: v.label,
                      };
                    }),
                  })
                }
                value={model.categories?.map(d => ({label: d.name || '', value: d.catId, order: d.order}))}
                id='categories'
                multiselect={true}
                clearable={true}
                addAll={true}
                predicate='value'
                searchable={true}
                onSearch={val =>
                  orderBy(
                    (mainCategoriesList || [])
                      .filter(mc => mc.label.toLowerCase().indexOf(val.toLowerCase()) > -1)
                      .map(mc => ({label: mc.label, value: mc.value, order: mc.order}), 'label'),
                  ) || []
                }
                options={mainCategoriesList}
              />
            </FormItem>
            <Grid rowGap='small' columnGap='xlarge'>
              <FormItem {...form.category} permission={permissions.CHANNEL_EDIT}>
                <Select
                  onChange={value => setFields({category: value.label})}
                  value={{label: model.category || ''}}
                  id='category'
                  searchable={true}
                  searchPlaceholder='Search for category'
                  onSearch={val =>
                    orderBy(
                      (legacyCategories || [])
                        .filter(lc => lc.name.toLowerCase().startsWith(val.toLowerCase()))
                        .map(lc => ({label: lc.name})),
                      'label',
                    ) || []
                  }
                  options={orderBy(
                    legacyCategories?.map(lc => ({label: lc.name})),
                    'label',
                  )}
                />
              </FormItem>
              <FormItem {...form.visibility} permission={permissions.CHANNEL_EDIT}>
                <Select
                  onChange={value => setFields({visibility: value.value})}
                  value={{label: model.visibility || '', value: model.visibility}}
                  id='visibility'
                  options={[
                    {label: 'Hidden', value: 'hidden'},
                    {label: 'Registered', value: 'registered'},
                    {label: 'Everyone', value: 'everyone'},
                  ]}
                  predicate={data => data.label.toLowerCase()}
                />
              </FormItem>
            </Grid>
            <FormItem {...form.supportedChannelCaptionLanguages} permission={permissions.CHANNEL_EDIT}>
              <Select
                onChange={value =>
                  setFields({
                    supportedChannelCaptionLanguages: (value || [])?.map(
                      v => `${v.value}:${isoLanguages.getName(v.value)}`,
                    ),
                  })
                }
                value={model.supportedChannelCaptionLanguages?.map(d => ({
                  label: d.split(':')[0],
                  value: d.split(':')[0],
                }))}
                id='supportedChannelCaptionLanguages'
                predicate='value'
                multiselect={true}
                searchable={true}
                searchPlaceholder='Search for language'
                onSearch={searchLanguages}
                options={languageList}
              />
            </FormItem>
            <FormItem {...form.metadataLanguage} permission={permissions.CHANNEL_EDIT}>
              <Select
                onChange={value => setFields({metadataLanguage: value.value})}
                value={{label: model.metadataLanguage || '', value: model.metadataLanguage}}
                id='metadataLanguage'
                predicate={d => d.value?.toLowerCase() || ''}
                searchable={true}
                searchPlaceholder='Search for language'
                onSearch={searchLanguages}
                options={languageList}
              />
            </FormItem>
            <Grid gap='xxxlarge'>
              <FormItem
                label='Kids Content'
                child='Toggle'
                helpText='Not supported in all active regions.'
                helpTextColor='info'
                permission={permissions.CHANNEL_EDIT}
              >
                <Toggle label='Yes' onChange={value => onChange('kidsMode', value)} value={model.kidsMode} />
              </FormItem>
              <FormItem label='Office Only' child='Toggle' permission={permissions.CHANNEL_EDIT}>
                <Toggle
                  label='Yes'
                  onChange={value => onChange('plutoOfficeOnly', value)}
                  value={model.plutoOfficeOnly}
                />
              </FormItem>
            </Grid>
          </Stack>
        </ContentBox>
      </ContentBoxColumn>
      <ContentBoxColumn>
        <ContentBox title='Channel Information'>
          <Stack space='xxlarge'>
            <Grid rowGap='small' columnGap='xlarge' minimum='16.25rem'>
              <FormItem label='Created At' child='Copy'>
                <Copy
                  text={new Date(model.createdAt!).toLocaleString()}
                  toCopy={new Date(model.createdAt!).toLocaleString()}
                />
              </FormItem>
              <FormItem label='Updated At' child='Copy'>
                <Copy
                  text={model.updatedAt ? new Date(model.updatedAt).toLocaleString() : 'N/A'}
                  toCopy={model.updatedAt ? new Date(model.updatedAt).toLocaleString() : 'N/A'}
                />
              </FormItem>
              <FormItem label='Channel ID' child='Copy'>
                <Copy text={model.id} toCopy={model.id} />
              </FormItem>
              <FormItem label='Featured' child='Paragraph'>
                <Paragraph>{model.featured! > 0 ? 'Yes' : 'No'}</Paragraph>
              </FormItem>
            </Grid>
          </Stack>
        </ContentBox>
        <ContentBox title='Identification'>
          <Stack space='medium'>
            <FormItem
              {...form.slug}
              onBlur={() => onBlur('slug')}
              permission={permissions.CHANNEL_EDIT}
              state={form.slug?.state ? form.slug.state : dirtyFields.slug ? 'warning' : ''}
              helpText={
                form.slug?.helpText
                  ? form.slug.helpText
                  : dirtyFields.slug
                  ? 'Changing the slug can break old references to this channel. Proceed with caution.'
                  : ''
              }
            >
              <TextInput onChange={value => onChange('slug', value)} value={model.slug} id='slug' />
            </FormItem>
          </Stack>
        </ContentBox>
        <ContentBox title='Additional Details'>
          <Stack space='medium'>
            <FormItem {...form.notes} onBlur={() => onBlur('notes')} permission={permissions.CHANNEL_EDIT}>
              <Textarea
                onChange={value => onChange('notes', value)}
                value={model.notes}
                id='notes'
                minHeight='6.25rem'
              />
            </FormItem>
          </Stack>
        </ContentBox>
      </ContentBoxColumn>
    </ContentBoxes>
  );
};
