export const vodCollectionListMock = {
  metadata: {
    offset: 0,
    limit: 25,
    sort: 'order:asc',
    totalCount: 1171,
  },
  data: [
    {
      id: '5c48d00d69dac82bf954ab8c',
      name: 'Movies: Winter Sports',
      displayName: 'Winter Sports',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '2019-01-23T21:47:47.075Z',
      description: '',
      regionFilter: {
        include: ['US'],
        exclude: ['GB'],
      },
      distribution: {
        include: [
          'desktop',
          'ctv',
          'livectv',
          'my5',
          'tvos',
          'chromecast',
          'ps3',
          'xbox360',
          'viziowatchfree',
          'skyticket',
          'virginmedia',
          'sky-q',
          'vizio',
          'rokuchannel',
          'samsung-tizen',
          'xboxone',
          'gom',
          'ios',
          'samsung-tvplus',
          'msn',
          'now-tv',
          'samsung-orsay',
          'android',
          'tivo',
          'web',
          'lgwebos',
          'hisense',
          'comcastx1',
          'km',
          'viziovia',
          'contour',
          'mobile',
          'opera-other',
          'roku',
          'ps4',
          'stbverizon',
          'androidmobileverizon',
          'androidtvverizon',
          'androidtvliveverizon',
          'firetvverizon',
          'firetvliveverizon',
          'androidmobiletelcel',
          'ps5',
          'xboxseriesx',
          'slingtv',
          'windows',
          'tclchannel',
          'marriott',
          'oculus',
          'androidtvhilton',
          'androidcharterav',
          'androidtvdirectv',
          'comcastxclass',
          'androidtvdirectvhome',
          'androidautomotiveos',
        ],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://s3.amazonaws.com/pluto.tv/vodcategories/5c48d00d69dac82bf954ab8c/iconSvg-1576093549259.svg',
      },
      iconPng: {
        path: 'https://s3.amazonaws.com/pluto.tv/vodcategories/5c48d00d69dac82bf954ab8c/iconPng-1576093552851.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2019-01-23T20:35:25.291Z',
      updatedAt: '2025-02-17T13:50:25.265Z',
      disabledAt: '2023-09-18T19:40:21.949Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '618d720c4b1ec000075cbc27',
          catId: '618c44169541940007cebf59',
          order: 1,
          name: 'Sports',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 100,
      settings: null,
    },
    {
      id: '5dd25b93c01bce000920ee33',
      name: 'Pluto TV Retro Drama (Samsung)',
      displayName: 'Pluto TV Retro Drama',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '2019-11-18T08:51:31.435Z',
      description: '',
      regionFilter: {
        include: ['GB'],
        exclude: ['US'],
      },
      distribution: {
        include: [
          'samsung-tvplus',
          'virginmedia360',
          'youviewstb',
          'freeviewstb',
          'tivovestelweb',
          'firetvkepler',
          'lg',
          'hisensetv',
          'firetv',
          'philips',
          'androidtv',
          'visionos',
        ],
        exclude: ['android', 'tclchannel', 'lgchannels', 'ctv', 'ps4'],
      },
      intileChannel: '5c5c3195f21b553c1f673faf',
      intileOrder: 1,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2019-11-18T08:51:31.437Z',
      updatedAt: '2024-11-18T08:40:19.836Z',
      disabledAt: '2022-11-16T10:57:36.331Z',
      activeRegion: 'gb',
      kidsMode: false,
      categories: [
        {
          id: '61d777ca2c8e9400077e1b60',
          catId: '61d6282a70add10007bd486d',
          order: 66,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 5,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '596fddcfea9ea3c65fbf70a6',
      name: 'German Test',
      displayName: '',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '',
      description: '',
      regionFilter: {
        include: ['US'],
        exclude: null,
      },
      distribution: {
        include: ['roku', 'android'],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2017-07-19T22:31:43.552Z',
      updatedAt: '2025-02-17T13:50:24.552Z',
      disabledAt: '2017-07-19T22:31:43.552Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '61d7375a6c4bf100072f0b1c',
          catId: '61d62705dd139500071324ef',
          order: 82,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 0,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '59398ad1ef3e9bdb74ae30f7',
      name: 'Brain Test',
      displayName: 'displayName',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '',
      description: '',
      regionFilter: {
        include: ['US'],
        exclude: null,
      },
      distribution: {
        include: ['roku', 'android'],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2017-06-08T17:35:13.444Z',
      updatedAt: '2025-02-17T13:50:23.817Z',
      disabledAt: '2017-06-08T17:35:13.444Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '61d778684f6ad900074a58c3',
          catId: '61d62705dd139500071324ef',
          order: 203,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 0,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '5975387e27c1a1836e49ab23',
      name: 'Get Drunk',
      displayName: '',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '',
      description: '',
      regionFilter: {
        include: ['US'],
        exclude: null,
      },
      distribution: {
        include: ['roku', 'android'],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2017-07-23T23:59:58.749Z',
      updatedAt: '2025-02-17T13:50:24.568Z',
      disabledAt: '2017-07-23T23:59:58.749Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '61d7441f31746f0007c9739c',
          catId: '61d62705dd139500071324ef',
          order: 129,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 1,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '59498742ccbc19294e99a4ca',
      name: 'Action Movies: September',
      displayName: 'Punch, Kick, Ka-Boom!',
      order: 0,
      enabled: true,
      plutoOfficeOnly: true,
      enabledAt: '2024-01-22T22:51:25.341Z',
      description: '',
      regionFilter: {
        include: ['US'],
        exclude: null,
      },
      distribution: {
        include: [
          'ctv',
          'chromecast',
          'vizio',
          'desktop',
          'ios',
          'tvos',
          'android',
          'km',
          'roku',
          'ps3',
          'ps4',
          'xbox360',
          'xboxone',
          'samsung-orsay',
          'web',
          'mobile',
        ],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2017-06-20T20:36:18.512Z',
      updatedAt: '2025-02-17T13:50:24.075Z',
      disabledAt: '2024-01-22T22:51:29.349Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '61d768a0dde9d0000737e84a',
          catId: '61d62705dd139500071324ef',
          order: 159,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 4,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '5d324d01e8b937d43756ec77',
      name: 'Empty',
      displayName: 'Empty',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '',
      description: '',
      regionFilter: {
        include: ['US'],
        exclude: null,
      },
      distribution: {
        include: [
          'ctv',
          'chromecast',
          'vizio',
          'samsung-tizen',
          'desktop',
          'ios',
          'tvos',
          'android',
          'km',
          'roku',
          'ps3',
          'ps4',
          'xbox360',
          'xboxone',
          'samsung-orsay',
          'web',
          'mobile',
          'viziovia',
          'hisense',
          'sky-q',
          'gom',
          'tivo',
          'opera-other',
          'samsung-tvplus',
          'msn',
          'viziowatchfree',
          'livectv',
          'skyticket',
          'comcastx1',
          'lgwebos',
        ],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2019-07-19T23:06:41.395Z',
      updatedAt: '2025-02-17T13:50:25.463Z',
      disabledAt: '2019-07-19T23:06:41.395Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '61d8a7c643149a0007dcab0d',
          catId: '61d62705dd139500071324ef',
          order: 264,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 0,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '5b7f2b048528ef4597790a5b',
      name: 'Action Fighting',
      displayName: '',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '',
      description: '',
      regionFilter: {
        include: ['US'],
        exclude: null,
      },
      distribution: {
        include: [
          'ctv',
          'chromecast',
          'vizio',
          'samsung-tizen',
          'desktop',
          'ios',
          'tvos',
          'android',
          'km',
          'roku',
          'ps3',
          'ps4',
          'xbox360',
          'xboxone',
          'samsung-orsay',
          'web',
          'mobile',
          'viziovia',
          'hisense',
          'sky-q',
          'now-tv',
          'gom',
          'tivo',
          'opera-other',
          'samsung-tvplus',
          'msn',
          'viziowatchfree',
          'lgwebos',
        ],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2018-08-23T21:45:40.622Z',
      updatedAt: '2025-02-17T13:50:24.999Z',
      disabledAt: '2018-08-23T21:45:40.622Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '61d7318fb7cd540008c747a5',
          catId: '61d62705dd139500071324ef',
          order: 41,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 0,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '5b83e2b357525462651b188c',
      name: 'Pluto TV Sports UK (VOD)',
      displayName: 'Pluto TV Sports',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '2020-02-29T08:18:01.811Z',
      description: '',
      regionFilter: {
        include: ['GB'],
        exclude: ['KP', 'KR', 'US'],
      },
      distribution: {
        include: [
          'ctv',
          'tvos',
          'xboxone',
          'ios',
          'now-tv',
          'android',
          'roku',
          'xboxseriesx',
          'livectv',
          'tclchannel',
          'philipstv',
          'virginmedia360',
          'youviewstb',
          'freeviewstb',
          'tivovestelweb',
          'firetvkepler',
          'lg',
          'hisensetv',
          'firetv',
          'philips',
          'androidtv',
          'visionos',
        ],
        exclude: ['ps4'],
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2018-08-27T11:38:27.009Z',
      updatedAt: '2024-11-18T08:40:19.689Z',
      disabledAt: '2020-03-02T08:12:15.995Z',
      activeRegion: 'gb',
      kidsMode: false,
      categories: [
        {
          id: '61d737a6f49b68000721615a',
          catId: '61d6282a70add10007bd486d',
          order: 22,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 13,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '5d700b7e7847a2cfd289414e',
      name: 'TV: Fuse',
      displayName: 'Fuse',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '2019-09-04T19:11:35.400Z',
      description: 'desc',
      regionFilter: {
        include: ['US'],
        exclude: ['GB'],
      },
      distribution: {
        include: [
          'desktop',
          'ctv',
          'livectv',
          'my5',
          'tvos',
          'chromecast',
          'ps3',
          'xbox360',
          'viziowatchfree',
          'skyticket',
          'virginmedia',
          'sky-q',
          'vizio',
          'rokuchannel',
          'samsung-tizen',
          'xboxone',
          'gom',
          'ios',
          'samsung-tvplus',
          'msn',
          'now-tv',
          'samsung-orsay',
          'android',
          'tivo',
          'web',
          'lgwebos',
          'hisense',
          'comcastx1',
          'km',
          'viziovia',
          'contour',
          'mobile',
          'opera-other',
          'roku',
          'ps4',
          'stbverizon',
          'androidmobileverizon',
          'androidtvverizon',
          'androidtvliveverizon',
          'firetvverizon',
          'firetvliveverizon',
          'androidmobiletelcel',
          'ps5',
          'xboxseriesx',
          'slingtv',
          'windows',
          'tclchannel',
          'marriott',
          'oculus',
          'androidtvhilton',
          'androidcharterav',
          'androidtvdirectv',
          'comcastxclass',
          'androidtvdirectvhome',
          'androidautomotiveos',
          'lgchannels',
          'firetvkepler',
          'visionos',
        ],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://s3.amazonaws.com/pluto.tv/vodcategories/5d700b7e7847a2cfd289414e/iconSvg-1576102816005.svg',
      },
      iconPng: {
        path: 'https://s3.amazonaws.com/pluto.tv/vodcategories/5d700b7e7847a2cfd289414e/iconPng-1576102819863.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2019-09-04T19:07:42.875Z',
      updatedAt: '2025-02-17T13:50:25.524Z',
      disabledAt: '2024-09-03T19:06:02.680Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '618c9b8506d23b000734e83a',
          catId: '618c5e361add6600071be7f8',
          order: 11,
          name: 'Reality',
        },
        {
          id: '679b4febf96632793d3b9ac5',
          catId: '627eb2bbaf840a000704fa18',
          order: 368,
          name: 'STE Testing',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 1,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '594abeb0ccbc19294e99d170',
      name: 'Family Movies',
      displayName: 'Animation NATION',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '2017-06-21T22:04:09.612Z',
      description: '',
      regionFilter: {
        include: ['US'],
        exclude: null,
      },
      distribution: {
        include: ['roku', 'android'],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2017-06-21T18:45:04.067Z',
      updatedAt: '2025-02-17T13:50:24.090Z',
      disabledAt: '2017-06-21T22:07:44.111Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '61d74aec0ac00600075fe73b',
          catId: '61d62705dd139500071324ef',
          order: 143,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 4,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '5b630ea18cfa8667545817c6',
      name: 'Xive TV EU Series and Documentary Highlights',
      displayName: 'Highlights: Xive TV Series and Documentaries',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '2018-08-02T14:10:12.211Z',
      description: '',
      regionFilter: {
        include: ['GB'],
        exclude: null,
      },
      distribution: {
        include: ['roku', 'now-tv'],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2018-08-02T14:01:05.607Z',
      updatedAt: '2025-02-17T13:50:24.938Z',
      disabledAt: '2018-08-24T15:16:44.826Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '61d74452b025610007731bee',
          catId: '61d62705dd139500071324ef',
          order: 133,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 2,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '59b6be3d5169d2404c1c880e',
      name: 'Hive',
      displayName: 'Superheroic Nostalgia',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '2017-11-30T00:23:16.105Z',
      description: '',
      regionFilter: {
        include: ['US'],
        exclude: null,
      },
      distribution: {
        include: [
          'ctv',
          'chromecast',
          'vizio',
          'samsung-tizen',
          'desktop',
          'ios',
          'tvos',
          'android',
          'km',
          'roku',
          'ps3',
          'ps4',
          'xbox360',
          'xboxone',
          'samsung-orsay',
          'web',
          'mobile',
          'viziovia',
        ],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2017-09-11T16:47:57.586Z',
      updatedAt: '2025-02-17T13:50:24.728Z',
      disabledAt: '2017-12-02T23:58:42.444Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '61d738f0ca7a8100072e2e8d',
          catId: '61d62705dd139500071324ef',
          order: 97,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 1,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '596e65f9aafa4de53720a5ad',
      name: 'Action, Adventure.',
      displayName: 'Movies To Leave On For Your Pets.',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '2017-08-01T05:55:00.283Z',
      description: '',
      regionFilter: {
        include: ['US'],
        exclude: null,
      },
      distribution: {
        include: [
          'ctv',
          'chromecast',
          'vizio',
          'desktop',
          'ios',
          'tvos',
          'android',
          'km',
          'roku',
          'ps3',
          'ps4',
          'xbox360',
          'xboxone',
          'samsung-orsay',
          'web',
          'mobile',
        ],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2017-07-18T19:48:09.194Z',
      updatedAt: '2025-02-17T13:50:24.520Z',
      disabledAt: '2017-08-12T17:17:41.568Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '61d8814ff41c9000086c7d1a',
          catId: '61d62705dd139500071324ef',
          order: 214,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 2,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '59e134ba2f50533acfe620e2',
      name: 'ROKU - Thriller',
      displayName: 'THRILLER',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '2017-10-13T21:52:56.264Z',
      description: '',
      regionFilter: {
        include: ['US'],
        exclude: null,
      },
      distribution: {
        include: ['roku', 'android'],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2017-10-13T21:48:42.086Z',
      updatedAt: '2025-02-17T13:50:24.845Z',
      disabledAt: '2017-10-27T21:00:19.102Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '61d73098023c2c0007300543',
          catId: '61d62705dd139500071324ef',
          order: 38,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 2,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '5b7fb6e732f76d45a36a8216',
      name: 'Glory Kickboxing DE.',
      displayName: 'Highlights: Glory Kickboxing.',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '2018-08-24T07:42:31.771Z',
      description: '',
      regionFilter: {
        include: ['AT', 'DE', 'CH'],
        exclude: null,
      },
      distribution: {
        include: [
          'skyticket',
          'tvos',
          'ctv',
          'roku',
          'android',
          'ios',
          'samsung-tvplus',
          'livectv',
          'tclchannel',
          'androidtvdeutschetelekom',
          'philipstv',
          'tivovestelweb',
          'firetvkepler',
          'visionos',
        ],
        exclude: null,
      },
      intileChannel: '5ad9be1be738977e2c312134',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2018-08-24T07:42:31.774Z',
      updatedAt: '2025-02-18T19:19:24.418Z',
      disabledAt: '2019-07-11T19:11:38.011Z',
      activeRegion: 'de',
      kidsMode: false,
      categories: [
        {
          id: '61d881818be12d0007e43502',
          catId: '61d6297d2b73dd0007180f84',
          order: 22,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: false,
      heroCarousel: false,
      titleCount: 4,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '5b80190c33ebef459de0e472',
      name: 'MST3K UK Samsung',
      displayName: 'Highlights: Mystery Science Theater 3000',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '2018-08-24T14:41:16.453Z',
      description: '',
      regionFilter: {
        include: ['GB'],
        exclude: null,
      },
      distribution: {
        include: [
          'samsung-tvplus',
          'android',
          'virginmedia360',
          'youviewstb',
          'freeviewstb',
          'tivovestelweb',
          'firetvkepler',
          'lg',
          'hisensetv',
          'firetv',
          'philips',
          'androidtv',
          'visionos',
        ],
        exclude: ['tclchannel', 'lgchannels', 'ctv', 'ps4'],
      },
      intileChannel: '5ad8d44cfd87eb3a2717afc5',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2018-08-24T14:41:16.456Z',
      updatedAt: '2025-02-18T19:19:56.320Z',
      disabledAt: '2018-11-14T10:03:16.900Z',
      activeRegion: 'gb',
      kidsMode: false,
      categories: [
        {
          id: '61d73ea6b1b78d0007160ca2',
          catId: '61d6282a70add10007bd486d',
          order: 27,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 7,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '5ca3f06d593a5d78f0e82cfa',
      name: 'Samsung: Wipeout',
      displayName: 'Samsung Wipeout',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '2019-04-02T23:33:40.906Z',
      description: '',
      regionFilter: {
        include: ['US'],
        exclude: [
          'AF',
          'AL',
          'DZ',
          'AS',
          'AD',
          'AO',
          'AI',
          'AQ',
          'AG',
          'AR',
          'AM',
          'AW',
          'AU',
          'AT',
          'AZ',
          'BS',
          'BH',
          'BD',
          'BB',
          'BY',
          'BE',
          'BZ',
          'BJ',
          'BM',
          'BT',
          'BO',
          'BA',
          'BW',
          'BV',
          'BR',
          'IO',
          'BN',
          'BG',
          'BF',
          'BI',
          'KH',
          'CM',
          'CA',
          'CV',
          'KY',
          'CF',
          'TD',
          'CL',
          'CN',
          'CX',
          'CC',
          'CO',
          'KM',
          'CG',
          'CD',
          'CK',
          'CR',
          'CI',
          'HR',
          'CU',
          'CY',
          'CZ',
          'DK',
          'DJ',
          'DM',
          'DO',
          'EC',
          'EG',
          'SV',
          'GQ',
          'ER',
          'EE',
          'ET',
          'FK',
          'FO',
          'FJ',
          'FI',
          'FR',
          'GF',
          'PF',
          'TF',
          'GA',
          'GM',
          'GE',
          'DE',
          'GH',
          'GI',
          'GR',
          'GL',
          'GD',
          'GP',
          'GU',
          'GT',
          'GN',
          'GW',
          'GY',
          'HT',
          'HM',
          'VA',
          'HN',
          'HK',
          'HU',
          'IS',
          'IN',
          'ID',
          'IR',
          'IQ',
          'IE',
          'IL',
          'IT',
          'JM',
          'JP',
          'JO',
          'KZ',
          'KE',
          'KI',
          'KP',
          'KR',
          'KW',
          'KG',
          'LA',
          'LV',
          'LB',
          'LS',
          'LR',
          'LY',
          'LI',
          'LT',
          'LU',
          'MO',
          'MK',
          'MG',
          'MW',
          'MY',
          'MV',
          'ML',
          'MT',
          'MH',
          'MQ',
          'MR',
          'MU',
          'YT',
          'MX',
          'FM',
          'MD',
          'MC',
          'MN',
          'MS',
          'MA',
          'MZ',
          'MM',
          'NA',
          'NR',
          'NP',
          'NL',
          'NC',
          'NZ',
          'NI',
          'NE',
          'NG',
          'NU',
          'NF',
          'MP',
          'NO',
          'OM',
          'PK',
          'PW',
          'PS',
          'PA',
          'PG',
          'PY',
          'PE',
          'PH',
          'PN',
          'PL',
          'PT',
          'PR',
          'QA',
          'RE',
          'RO',
          'RU',
          'RW',
          'SH',
          'KN',
          'LC',
          'PM',
          'VC',
          'WS',
          'SM',
          'ST',
          'SA',
          'SN',
          'SC',
          'SL',
          'SG',
          'SK',
          'SI',
          'SB',
          'SO',
          'ZA',
          'GS',
          'ES',
          'LK',
          'SD',
          'SR',
          'SJ',
          'SZ',
          'SE',
          'CH',
          'SY',
          'TW',
          'TJ',
          'TZ',
          'TH',
          'TL',
          'TG',
          'TK',
          'TO',
          'TT',
          'TN',
          'TR',
          'TM',
          'TC',
          'TV',
          'UG',
          'UA',
          'AE',
          'GB',
          'UM',
          'UY',
          'UZ',
          'VU',
          'VE',
          'VN',
          'VG',
          'VI',
          'WF',
          'EH',
          'YE',
          'ZM',
          'ZW',
          'AX',
          'BQ',
          'CW',
          'GG',
          'IM',
          'JE',
          'ME',
          'BL',
          'MF',
          'RS',
          'SX',
          'SS',
          'XK',
          'A1',
          'A2',
          'O1',
        ],
      },
      distribution: {
        include: null,
        exclude: [
          'desktop',
          'ctv',
          'livectv',
          'my5',
          'tvos',
          'chromecast',
          'ps3',
          'xbox360',
          'viziowatchfree',
          'skyticket',
          'virginmedia',
          'sky-q',
          'vizio',
          'rokuchannel',
          'samsung-tizen',
          'xboxone',
          'gom',
          'ios',
          'samsung-tvplus',
          'msn',
          'now-tv',
          'samsung-orsay',
          'tivo',
          'web',
          'lgwebos',
          'hisense',
          'comcastx1',
          'km',
          'viziovia',
          'contour',
          'lgchannels',
          'mobile',
          'opera-other',
          'macos',
          'ps4',
          'catalyst',
          'stbverizon',
          'androidmobileverizon',
          'androidtvverizon',
          'androidtvliveverizon',
          'firetvverizon',
          'firetvliveverizon',
          'androidmobiletelcel',
          'ps5',
          'xboxseriesx',
          'slingtv',
        ],
      },
      intileChannel: '5ab3e1242be690697279c75d',
      intileOrder: 1,
      iconSvg: {
        path: 'https://s3.amazonaws.com/pluto.tv/vodcategories/5ca3f06d593a5d78f0e82cfa/iconSvg-1576094183484.svg',
      },
      iconPng: {
        path: 'https://s3.amazonaws.com/pluto.tv/vodcategories/5ca3f06d593a5d78f0e82cfa/iconPng-1576094187303.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2019-04-02T23:29:49.760Z',
      updatedAt: '2025-02-17T13:50:25.322Z',
      disabledAt: '2020-09-16T21:28:47.114Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '61d7777332bd0a00073fa2d9',
          catId: '61d62705dd139500071324ef',
          order: 195,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 0,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '58cc5e4f1a6ab5881d2aff1b',
      name: 'Nikkis Faves',
      displayName: '',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '2017-03-17T22:09:14.802Z',
      description: '',
      regionFilter: {
        include: ['US'],
        exclude: null,
      },
      distribution: {
        include: ['roku', 'android'],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2017-03-17T22:08:15.860Z',
      updatedAt: '2025-02-17T13:50:23.352Z',
      disabledAt: '2017-04-01T01:12:11.415Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '61d73306df6ab20007746b1b',
          catId: '61d62705dd139500071324ef',
          order: 54,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 0,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '595eb98f491f7ff12f5a20cd',
      name: 'FrontDoor',
      displayName: 'Home Improvement Shows',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '2018-04-17T23:47:10.819Z',
      description: '',
      regionFilter: {
        include: ['US'],
        exclude: null,
      },
      distribution: {
        include: [
          'ctv',
          'chromecast',
          'vizio',
          'samsung-tizen',
          'desktop',
          'ios',
          'tvos',
          'android',
          'km',
          'roku',
          'ps3',
          'ps4',
          'xbox360',
          'xboxone',
          'samsung-orsay',
          'web',
          'mobile',
        ],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2017-07-06T22:28:31.466Z',
      updatedAt: '2025-02-17T13:50:24.305Z',
      disabledAt: '2018-08-01T18:12:10.136Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '61d768c597a55200074a049d',
          catId: '61d62705dd139500071324ef',
          order: 162,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 10,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '5b7ec8a446f34845b1ab7726',
      name: 'TV+ Intiles Pluto TV Kids UK VOD Tab 2',
      displayName: 'Pluto TV Kids Most Watched',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '2018-08-23T14:52:13.907Z',
      description: '',
      regionFilter: {
        include: ['GB'],
        exclude: null,
      },
      distribution: {
        include: [
          'tvos',
          'ios',
          'samsung-tvplus',
          'livectv',
          'tclchannel',
          'philipstv',
          'virginmedia360',
          'youviewstb',
          'freeviewstb',
          'tivovestelweb',
          'firetvkepler',
          'lg',
          'hisensetv',
          'firetv',
          'philips',
          'androidtv',
          'visionos',
        ],
        exclude: ['ctv', 'ps4'],
      },
      intileChannel: '5ad8d54be738977e2c310940',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2018-08-23T14:45:56.995Z',
      updatedAt: '2024-11-18T08:40:19.591Z',
      disabledAt: '2018-08-24T12:19:39.092Z',
      activeRegion: 'gb',
      kidsMode: true,
      categories: [
        {
          id: '61d73e737a440e0008d3353b',
          catId: '61d6282a70add10007bd486d',
          order: 16,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: false,
      heroCarousel: false,
      titleCount: 5,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '594dc558ccbc19294e9a2db2',
      name: 'Anime',
      displayName: 'Anime That Kicks Butt',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '2017-10-04T00:46:18.796Z',
      description: '',
      regionFilter: {
        include: ['US'],
        exclude: null,
      },
      distribution: {
        include: [
          'ctv',
          'chromecast',
          'vizio',
          'desktop',
          'ios',
          'tvos',
          'android',
          'km',
          'roku',
          'ps3',
          'ps4',
          'xbox360',
          'xboxone',
          'samsung-orsay',
          'web',
          'mobile',
          'viziovia',
        ],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2017-06-24T01:50:16.573Z',
      updatedAt: '2025-02-17T13:50:24.154Z',
      disabledAt: '2018-05-01T16:17:39.128Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '61d73a62dd13950007133efc',
          catId: '61d62705dd139500071324ef',
          order: 109,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 17,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '5a231b16630a8b68166dd1f0',
      name: 'Pluto TV Movies',
      displayName: 'Wonderful Holiday Movies',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '2017-12-02T21:49:43.007Z',
      description: '',
      regionFilter: {
        include: ['US'],
        exclude: null,
      },
      distribution: {
        include: [
          'ctv',
          'chromecast',
          'vizio',
          'samsung-tizen',
          'desktop',
          'ios',
          'tvos',
          'android',
          'km',
          'roku',
          'ps3',
          'ps4',
          'xbox360',
          'xboxone',
          'samsung-orsay',
          'web',
          'mobile',
          'viziovia',
        ],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2017-12-02T21:28:54.760Z',
      updatedAt: '2025-02-17T13:50:24.876Z',
      disabledAt: '2017-12-31T15:56:53.229Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '61d7316170add10007bd5489',
          catId: '61d62705dd139500071324ef',
          order: 39,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 7,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '59c1abbc40e64939daad7114',
      name: 'Trending Now Clips',
      displayName: '',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '',
      description: '',
      regionFilter: {
        include: ['US'],
        exclude: null,
      },
      distribution: {
        include: [
          'ctv',
          'chromecast',
          'vizio',
          'desktop',
          'ios',
          'tvos',
          'android',
          'km',
          'roku',
          'ps3',
          'ps4',
          'xbox360',
          'xboxone',
          'samsung-orsay',
          'web',
          'mobile',
          'viziovia',
        ],
        exclude: null,
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2017-09-19T23:43:56.863Z',
      updatedAt: '2025-02-17T13:50:24.747Z',
      disabledAt: '2017-09-19T23:43:56.863Z',
      activeRegion: 'us',
      kidsMode: false,
      categories: [
        {
          id: '61d768df501b460007c59dbc',
          catId: '61d62705dd139500071324ef',
          order: 164,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: true,
      heroCarousel: false,
      titleCount: 0,
      settings: {
        enabled: false,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
    {
      id: '5bf80a041843b56328bef871',
      name: 'Pluto TV Xmas (UK).',
      displayName: 'Pluto TV XMAS.',
      order: 0,
      enabled: true,
      plutoOfficeOnly: false,
      enabledAt: '2024-01-22T23:15:57.135Z',
      description: '',
      regionFilter: {
        include: ['GB'],
        exclude: null,
      },
      distribution: {
        include: [
          'ctv',
          'tvos',
          'ios',
          'now-tv',
          'android',
          'roku',
          'androidmobilehuawei',
          'livectv',
          'tclchannel',
          'philipstv',
          'virginmedia360',
          'youviewstb',
          'freeviewstb',
          'tivovestelweb',
          'firetvkepler',
          'lg',
          'hisensetv',
          'firetv',
          'philips',
          'androidtv',
          'visionos',
        ],
        exclude: ['ps4'],
      },
      intileChannel: '',
      intileOrder: 0,
      iconSvg: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg',
      },
      iconPng: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png',
      },
      imageFeatured: {
        path: 'https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg',
      },
      createdAt: '2018-11-23T14:09:08.674Z',
      updatedAt: '2025-02-11T18:55:09.333Z',
      disabledAt: '2024-01-22T23:16:02.838Z',
      activeRegion: 'gb',
      kidsMode: false,
      categories: [
        {
          id: '61d73f456c4bf100072f0efe',
          catId: '61d6282a70add10007bd486d',
          order: 57,
          name: 'Test',
        },
      ],
      customReferences: undefined,
      archived: false,
      heroCarousel: false,
      titleCount: 13,
      settings: {
        enabled: true,
        autoPublishStartPosition: 10,
        totalCount: 1,
        sort: {
          by: 'createdAt',
          order: 'desc',
        },
        filters: {
          genre: null,
          partner: null,
          seriesType: null,
          ageRange: null,
          rating: null,
        },
      },
    },
  ],
};
