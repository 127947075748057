import * as React from 'react';
import {
  Box,
  Cluster,
  ContentBoxes,
  ContentBox,
  ContentBoxColumn,
  Copy,
  FormItem,
  Grid,
  Icon,
  Popover,
  Button,
  Table,
  ITableCol,
  TIcons,
  Template,
  Toast,
  TdLink,
  Spinner,
  Stack,
  Textarea,
  TextInput,
  Toggle,
} from '@pluto-tv/assemble';
import {sortBy, cloneDeep} from 'lodash-es';
import {TableActions} from 'components/tableActions';
import {useAppPermissions} from 'app/permissions';
import {useFindQuery as useFindMainCategoriesQuery} from 'features/mainCategories/mainCategoriesApi';
import routes from 'routes/programming.routes';

import CrudError from 'components/crudError';

// import {INestedSeriesProps} from '../nestedPropsInterface';
import {IMainCategory} from 'models/mainCategories';
import {IMainCategorySimple} from 'models/vodCollections';
import {INestedVodCollectionProps} from '../nestedPropsInterface';

import MainCategoryForm from 'components/main-category-form';

interface IMainCategoryPopover {
  add?: boolean;
  [key: number]: boolean;
}

interface IVodCollectionProps extends INestedVodCollectionProps {
  dirtyFields: any;
}

export default ({model, setFields, onBlur, onChange, form}: IVodCollectionProps): JSX.Element => {
  const {ableTo, permissions} = useAppPermissions();
  const [mainCategoryPopoverOpen, setMainCategoryPopoverOpen] = React.useState<IMainCategoryPopover>({});

  const {
    data: mainCategories,
    isError: isMainCategoriesError,
    error: mainCategoriesError,
    isFetching: isMainCategoriesFetching,
  } = useFindMainCategoriesQuery();

  const [mainCategoriesList, setMainCategoriesList] = React.useState<IMainCategory[]>([]);

  function handleFilteredList(mainCategoriesArray: IMainCategory[], activeRegion: string) {
    const list = mainCategoriesArray.filter((mc: IMainCategory) => mc.activeRegion === activeRegion);
    return sortBy(list, 'name');
  }

  const handleMainCategoryClick = (icon: TIcons, index: number) => {
    if (icon === 'delete') {
      const cloned = cloneDeep(model.categories);

      if (!cloned) {
        return;
      }

      cloned.splice(index, 1);

      setFields({
        categories: cloned,
      });
    } else if (icon === 'edit') {
      setMainCategoryPopoverOpen({[index]: true});
    }
  };

  const handleMainCategoryUpdate = (category: IMainCategorySimple, index = -1) => {
    const cloned = cloneDeep(model.categories || []);
    const categoryExists = cloned.find((ref, i) => ref.catId === category.catId && i !== index);

    if (!categoryExists) {
      if (index >= 0) {
        cloned.splice(index, 1, category);
      } else {
        cloned.push(category);
      }
    } else {
      if (index < 0) {
        Toast.warning('The same main category type cannot be used more than once. Please update.');
      }
    }

    setFields({
      categories: cloned,
    });

    setMainCategoryPopoverOpen({});
  };

  React.useEffect(() => {
    const list: IMainCategory[] = handleFilteredList(
      mainCategories?.data || [],
      model.activeRegion?.toLowerCase() as string,
    );
    setMainCategoriesList(list);
  }, [mainCategories, model.activeRegion]);

  React.useEffect(() => {
    if (model.categories?.length) {
      model.categories = sortBy(model.categories, c => c.categoryDetails?.name);
    }
  }, [model]);

  if (isMainCategoriesError) {
    const err = mainCategoriesError;
    return <CrudError error={err} />;
  }

  if (isMainCategoriesFetching) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} minHeight='9.375rem' size='xlarge' />
      </Box>
    );
  }

  if (false) {
    return <CrudError error='Error loading page data' />;
  }

  if (false) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} minHeight='9.375rem' size='xlarge' />
      </Box>
    );
  }

  return (
    <ContentBoxes layout='columns'>
      <ContentBoxColumn>
        <ContentBox title='About'>
          <Stack space='medium'>
            <FormItem {...form.name} onBlur={() => onBlur('name')} permission={permissions.VOD_EDIT}>
              <TextInput onChange={value => onChange('name', value)} value={model.name} id='name' />
            </FormItem>
            <FormItem {...form.displayName} onBlur={() => onBlur('displayName')} permission={permissions.VOD_EDIT}>
              <TextInput onChange={value => onChange('displayName', value)} value={model.displayName} id='name' />
            </FormItem>
            <FormItem {...form.description} onBlur={() => onBlur('description')} permission={permissions.VOD_EDIT}>
              <Textarea
                onChange={value => onChange('description', value)}
                value={model.description}
                id='description'
                minHeight='6.25rem'
              />
            </FormItem>
            <Grid gap='xxxlarge'>
              <FormItem
                permission={permissions.VOD_EDIT}
                label='Kids Content'
                child='Toggle'
                helpText='Not supported in all active regions.'
                helpTextColor='info'
              >
                <Toggle label='Yes' onChange={value => onChange('kidsMode', value)} value={model.kidsMode} />
              </FormItem>
              <FormItem label='Office Only' child='Toggle' permission={permissions.VOD_EDIT}>
                <Toggle
                  label='Yes'
                  onChange={value => onChange('plutoOfficeOnly', value)}
                  value={model.plutoOfficeOnly}
                />
              </FormItem>
              <FormItem
                label='Hero Carousel'
                child='Toggle'
                helpText='Only one hero carousel per region.'
                helpTextColor='info'
                permission={permissions.VOD_EDIT}
              >
                <Toggle label='Yes' onChange={value => onChange('heroCarousel', value)} value={model.heroCarousel} />
              </FormItem>
            </Grid>
          </Stack>
        </ContentBox>
        <ContentBox title='Main Categories*'>
          <Stack space='medium'>
            <Table
              maxHeight='16.25rem'
              cols={[
                {
                  label: 'Category Name',
                  transform: row => (
                    <TdLink
                      row={row}
                      title={row.name || row.categoryDetails?.name || ''}
                      target='_blank'
                      url={routes.paths.mainCategoriesEditDetailsPage.replace(':id', row.catId)}
                    />
                  ),
                },
                {
                  label: 'Order Position',
                  transform: row => row.order,
                },
                ...(ableTo('VOD_EDIT')
                  ? [
                      {
                        label: 'Actions',
                        colWidth: '6.25rem',
                        transform: (row, _col, index) => (
                          <Cluster>
                            <TableActions
                              row={row}
                              icons={[]}
                              deleteOption={true}
                              altTitle={`category '${row.name || row.categoryDetails?.name}'`}
                              onClick={(row, icon) => handleMainCategoryClick(icon, index)}
                            >
                              <Popover
                                appendToBody={true}
                                manualTrigger={true}
                                visible={mainCategoryPopoverOpen[index]}
                                onClickOutside={() => setMainCategoryPopoverOpen({})}
                              >
                                <Template label='trigger'>
                                  <Icon
                                    space='small'
                                    icon='edit'
                                    onClick={() => handleMainCategoryClick('edit', index)}
                                  />
                                </Template>
                                <Template label='popover'>
                                  <MainCategoryForm
                                    categories={mainCategoriesList as any}
                                    visible={mainCategoryPopoverOpen[index]}
                                    onCancel={() => setMainCategoryPopoverOpen({})}
                                    value={row}
                                    onSave={val => handleMainCategoryUpdate(val, index)}
                                  />
                                </Template>
                              </Popover>
                            </TableActions>
                          </Cluster>
                        ),
                      } as ITableCol<IMainCategorySimple>,
                    ]
                  : []),
              ]}
              rows={model.categories || []}
            ></Table>
            <Cluster justify='space-between'>
              <div></div>
              {ableTo('VOD_EDIT') && (
                <Popover
                  manualTrigger={true}
                  visible={mainCategoryPopoverOpen.add}
                  onClickOutside={() => setMainCategoryPopoverOpen({})}
                >
                  <Template label='trigger'>
                    <Button type='primary' onClick={() => setMainCategoryPopoverOpen({add: true})}>
                      + Add
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <MainCategoryForm
                      categories={mainCategoriesList}
                      isNew={true}
                      visible={mainCategoryPopoverOpen.add}
                      value={{}}
                      onSave={val => handleMainCategoryUpdate(val)}
                      onCancel={() => setMainCategoryPopoverOpen({})}
                    />
                  </Template>
                </Popover>
              )}
            </Cluster>
          </Stack>
        </ContentBox>
      </ContentBoxColumn>
      <ContentBoxColumn>
        <ContentBox title='Collection Information'>
          <Stack space='xxlarge'>
            <Grid rowGap='small' columnGap='xlarge' minimum='16.25rem' maxCols={2}>
              <FormItem label='Created At' child='Copy'>
                <Copy
                  text={new Date(model.createdAt!).toLocaleString()}
                  toCopy={new Date(model.createdAt!).toLocaleString()}
                />
              </FormItem>
              <FormItem label='Updated At' child='Copy'>
                <Copy
                  text={model.updatedAt ? new Date(model.updatedAt).toLocaleString() : 'N/A'}
                  toCopy={model.updatedAt ? new Date(model.updatedAt).toLocaleString() : 'N/A'}
                />
              </FormItem>
              {model.enabledAt && (
                <FormItem label='Published At' child='Copy'>
                  <Copy
                    text={new Date(model.enabledAt!).toLocaleString()}
                    toCopy={new Date(model.enabledAt!).toLocaleString()}
                  />
                </FormItem>
              )}
              {model.disabledAt && (
                <FormItem label='Unpublished At' child='Copy'>
                  <Copy
                    text={new Date(model.disabledAt!).toLocaleString()}
                    toCopy={new Date(model.disabledAt!).toLocaleString()}
                  />
                </FormItem>
              )}
              <FormItem label='Collection ID' child='Copy'>
                <Copy text={model.id} toCopy={model.id} />
              </FormItem>
            </Grid>
          </Stack>
        </ContentBox>
      </ContentBoxColumn>
    </ContentBoxes>
  );
};
