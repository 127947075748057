import * as React from 'react';

import {cloneDeep, orderBy, sortBy, startCase} from 'lodash-es';
import {useHistory} from 'react-router-dom';
import {
  Box,
  Button,
  Click,
  Cluster,
  ContentBoxes,
  ContentBox,
  ContentBoxColumn,
  Dialog,
  FormItem,
  Grid,
  Icon,
  ITableCol,
  Heading,
  MultiselectDrag,
  Notification,
  Paragraph,
  Popover,
  Select,
  Spinner,
  Stack,
  Table,
  TextInput,
  Template,
  ISelectOption,
  TIcons,
  trimModel,
  States,
  Toast,
  useValidateForm,
} from '@pluto-tv/assemble';
import {omit} from 'lodash-es';

import collectionRoutes from 'routes/programming.routes';

import {vodCollectionCloneDetails} from '../../validators';
import {TableActions} from 'components/tableActions';
import {useFindQuery as useFindPartnersQuery} from 'features/partners/partnersApi';
import {useFindQuery as useFindDevices} from 'features/devices/devicesApi';
import {useFindLiteQuery as useFindChannelLiteQuery} from 'features/channels/channelsApi';
import {useAppPermissions} from 'app/permissions';
import {useUserRegions} from 'helpers/useUserRegions';

import CrudError from 'components/crudError';
import CustomReferenceForm from 'components/custom-reference-form';
import {IVodCollection, IVodCustomReference} from 'models/vodCollections';

import {useFindQuery as useFindMainCategoriesQuery} from 'features/mainCategories/mainCategoriesApi';

import {IMainCategory} from 'models/mainCategories';
import createDictionary from 'helpers/createDictionary';
import {useInsertMutation} from 'features/vodCollections/vodCollectionsApi';

interface ICustomReferencePopover {
  add?: boolean;
  [key: number]: boolean;
}

import {INestedVodCollectionProps} from '../nestedPropsInterface';

interface IVodCollectionProps extends INestedVodCollectionProps {
  dirtyFields: any;
}

interface IMultidragState {
  state: States;
  labelOne: string;
  labelTwo: string;
}

export default ({model, setFields, onBlur, onChange, form}: IVodCollectionProps): JSX.Element => {
  const {ableTo, permissions} = useAppPermissions();
  const [insertVodCollection] = useInsertMutation();
  const history = useHistory();

  const {data: deviceList, isFetching: devicesLoading} = useFindDevices({
    offset: 0,
    limit: 250,
    sort: 'name:asc',
  });

  const {activeRegions, territories, isFetching: isUserRegionsFetching, isError: isUserRegionsError} = useUserRegions();
  const devices = Array.from(new Set(deviceList?.data.map(d => d.platform)));

  const {data: partners, isFetching: isFetchingPartners} = useFindPartnersQuery();

  const [customReferencePopoverOpen, setCustomReferencePopoverOpen] = React.useState<ICustomReferencePopover>({});
  const [territoriesDictionary, setTerritoriesDictionary] = React.useState({});
  const [partnerDictionary, setPartnerDictionary] = React.useState({});
  const [isCloneChannelOpen, setIsCloneChannelOpen] = React.useState(false);
  const [isCreating, setIsCreating] = React.useState(false);

  const [mainCategoriesList, setMainCategoriesList] = React.useState<ISelectOption[]>([]);
  const [regionFilterState, setRegionFilterState] = React.useState<IMultidragState>();
  const [distributionState, setDistributionState] = React.useState<IMultidragState>();

  const {
    data: mainCategories,
    isError: isMainCategoriesError,
    error: mainCategoriesError,
    isFetching: isMainCategoriesFetching,
  } = useFindMainCategoriesQuery();

  const {
    data: channels,
    isError: isChannelError,
    error: channelError,
    isFetching: isChannelFetching,
  } = useFindChannelLiteQuery({
    activeRegions: [model.activeRegion!],
    ignoreArchiveFlag: true,
    limit: 4000,
  });

  const {
    model: cloneDetailsModel,
    onChange: cloneDetailsOnChange,
    form: cloneDetailsForm,
    setFields: setFieldsForCloneDetails,
    setModel: setCloneDetailsModel,
    state: cloneDetailsState,
    onBlur: cloneDetailsOnBlur,
    reset: resetCloneDetailsForm,
  } = useValidateForm<IVodCollection>(vodCollectionCloneDetails, 'onBlur');

  React.useEffect(() => {
    if (model) {
      const clonedCollection = cloneDeep(model);
      delete clonedCollection.name;
      delete clonedCollection.displayName;
      delete clonedCollection.disabledAt;
      delete clonedCollection.enabledAt;
      delete clonedCollection.updatedAt;
      setCloneDetailsModel(clonedCollection);
    }
  }, [model, setCloneDetailsModel]);

  const handleFilteredList = (mainCategoriesArray: IMainCategory[], activeRegion: string) => {
    const list = mainCategoriesArray
      .filter((mc: IMainCategory) => mc.activeRegion === activeRegion)
      .map((mc: IMainCategory) => ({label: mc.name, value: mc.id, order: mc.order}));

    return sortBy(list, 'label');
  };

  React.useEffect(() => {
    const list: ISelectOption[] = handleFilteredList(mainCategories?.data || [], model.activeRegion as string);
    setMainCategoriesList(list);
  }, [model.mainCategories, model.activeRegion, mainCategories]);

  const handleCustomReferenceClick = (icon: TIcons, index: number) => {
    if (icon === 'delete') {
      const cloned = cloneDeep(model.customReferences);

      if (!cloned) {
        return;
      }

      cloned.splice(index, 1);

      setFields({
        customReferences: !cloned.length ? undefined : cloned,
      });
    } else if (icon === 'edit') {
      setCustomReferencePopoverOpen({[index]: true});
    }
  };

  const handleCustomReferencesUpdate = (customReference: IVodCustomReference, index = -1) => {
    const cloned = cloneDeep(model.customReferences || []);
    const typeExists = cloned.find((ref, i) => ref.type === customReference.type && i !== index);

    if (!typeExists) {
      if (index >= 0) {
        cloned.splice(index, 1, customReference);
      } else {
        cloned.push(customReference);
      }
    } else {
      Toast.warning('The same Custom Reference type cannot be used more than once. Please update.');
    }

    setFields({
      customReferences: cloned,
    });

    setCustomReferencePopoverOpen({});
  };

  React.useEffect(() => {
    if (territories?.length) {
      setTerritoriesDictionary(createDictionary(territories));
    }
  }, [territories]);

  React.useEffect(() => {
    if (partners?.length) {
      setPartnerDictionary(createDictionary(partners));
    }
  }, [partners]);

  const getMultiSelectInfo = React.useCallback(
    (field: string): IMultidragState => {
      return {
        state: ableTo('VOD_EDIT') ? (form[field]?.state ? 'error' : '') : 'disabled',
        labelOne: `Include (${model[field]?.include?.length || 0})`,
        labelTwo: `Exclude (${model[field]?.exclude?.length || 0})`,
      };
    },
    [model, form, ableTo],
  );

  React.useEffect(() => {
    if (model.regionFilter) {
      setRegionFilterState(getMultiSelectInfo('regionFilter'));
    }

    if (model.distribution) {
      setDistributionState(getMultiSelectInfo('distribution'));
    }
  }, [model.regionFilter, model.distribution, getMultiSelectInfo]);

  if (isMainCategoriesError) {
    const err = mainCategoriesError;
    return <CrudError error={err} />;
  }

  if (isChannelError) {
    const err = channelError;
    return <CrudError error={err} />;
  }

  if (isUserRegionsError) {
    return <CrudError error='Error loading page data' />;
  }

  if (isUserRegionsFetching || isFetchingPartners || isMainCategoriesFetching || devicesLoading || isChannelFetching) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  const handleCloneCreate = async (navigateTo = true): Promise<string | undefined> => {
    let vodCollectionId: string | undefined;

    try {
      setIsCreating(true);

      const postModel: Partial<IVodCollection> = {
        ...omit(cloneDetailsModel, 'id'),
        description: undefined,
        imageFeatured: undefined,
        intileChannel: undefined,
        intileOrder: undefined,
        customReferences: undefined,
        name: cloneDetailsModel.name,
        displayName: cloneDetailsModel.displayName,
        categories: cloneDetailsModel.categories?.map(c => ({...c, order: 0})),
      };

      const newCollection = await insertVodCollection(trimModel(postModel, 'name')).unwrap();
      vodCollectionId = newCollection.id!;

      const toastMsg = !navigateTo ? (
        `You've Cloned ${model.name}`
      ) : (
        <Stack space='xxsmall'>
          <Paragraph>You&apos;ve cloned {model.name}</Paragraph>
          <Click
            underline={true}
            hoverColor='white'
            onClick={() =>
              history.push(
                collectionRoutes.paths.vodCollectionEditDetailsPage.replace(':id', vodCollectionId as string),
              )
            }
          >
            View vodCollection: {postModel.name}
          </Click>
        </Stack>
      );

      Toast.success('Success', toastMsg, 8000);

      setIsCloneChannelOpen(false);
    } catch (e) {
      Toast.error('Error', (e as any).data.message);
    } finally {
      setIsCloneChannelOpen(false);
      setIsCreating(false);
      resetCloneDetailsForm();
    }

    return vodCollectionId;
  };

  const handleClonedAndEdit = async () => {
    try {
      const vodCollectionId = await handleCloneCreate(false);
      // Timeout is required to remove unmount error and memory leak.
      setTimeout(() => {
        if (vodCollectionId) {
          history.push(collectionRoutes.paths.vodCollectionEditDetailsPage.replace(':id', vodCollectionId as string));
        }
      }, 800);
    } catch (e) {}
  };

  return (
    <ContentBoxes layout='columns'>
      <ContentBoxColumn>
        <ContentBox title='Distribution Details'>
          <Stack space='xxlarge'>
            <Stack space='xlarge'>
              <Heading level='h4' color='secondary'>
                Active Region
              </Heading>
              <FormItem
                {...form?.activeRegion}
                label='Active Region'
                state={model.activeRegion ? 'disabled' : ''}
                permission={permissions.VOD_EDIT}
              >
                <Select
                  permission={permissions.VOD_EDIT}
                  onChange={value => setFields({activeRegion: value.value})}
                  value={{label: model.activeRegion || '', value: model.activeRegion?.toLowerCase()}}
                  id='activeRegion'
                  predicate='value'
                  options={activeRegions.map(ar => ({
                    label: `${ar.name} (${ar.code})`,
                    value: ar.code.toLowerCase(),
                  }))}
                />
              </FormItem>
            </Stack>
            <Stack space='xlarge'>
              <Heading level='h4' color='secondary'>
                Territories
              </Heading>
              <MultiselectDrag
                clearable={ableTo('VOD_EDIT')}
                permission={permissions.VOD_EDIT}
                stateOne={regionFilterState?.state}
                stateTwo={regionFilterState?.state}
                labelOne={regionFilterState?.labelOne}
                labelTwo={regionFilterState?.labelTwo}
                options={
                  orderBy(
                    (territories || []).map(t => ({label: t.name, value: t.id.toUpperCase()})),
                    'label',
                  ) || []
                }
                valueOne={model.regionFilter?.include?.map(t => ({
                  label: `${territoriesDictionary[t]}`,
                  value: t.toUpperCase(),
                }))}
                valueTwo={model.regionFilter?.exclude?.map(t => ({
                  label: `${territoriesDictionary[t]}`,
                  value: t.toUpperCase(),
                }))}
                onChange={val => {
                  const include = (val?.one || []).map(v => v.value).sort();
                  const exclude = (val?.two || []).map(v => v.value).sort();
                  setFields({
                    regionFilter: {
                      include,
                      exclude,
                    },
                  });
                }}
                helpTextOne={form.regionFilter?.helpText || (form.regionFilter?.helpText as any)}
                searchable={true}
                onSearch={(opts, term) =>
                  orderBy(
                    (opts || [])
                      .filter(p => p.label.toLowerCase().startsWith(term.toLowerCase()))
                      .map(p => ({label: p.label, value: p.value}), 'label'),
                  ) || ([] as ISelectOption[])
                }
              />
            </Stack>
            <Stack space='xlarge'>
              <Heading level='h4' color='secondary'>
                Devices
              </Heading>
              <MultiselectDrag
                stateOne={distributionState?.state}
                stateTwo={distributionState?.state}
                clearable={ableTo('VOD_EDIT')}
                permission={permissions.VOD_EDIT}
                labelOne={distributionState?.labelOne}
                labelTwo={distributionState?.labelTwo}
                options={
                  orderBy(
                    (devices || []).map(t => ({label: t, value: t.toLowerCase()})),
                    'label',
                  ) || []
                }
                predicate='value'
                onChange={val => {
                  const include = (val?.one || []).map(v => v.value).sort();
                  const exclude = (val?.two || []).map(v => v.value).sort();
                  setFields({
                    distribution: {
                      include,
                      exclude,
                    },
                  });
                }}
                valueOne={model.distribution?.include?.map(d => ({label: d, value: d}))}
                valueTwo={model.distribution?.exclude?.map(d => ({label: d, value: d}))}
                helpTextOne={form.distribution?.helpText || (form.devicesExcluded?.helpText as any)}
                searchable={true}
                onSearch={(opts, term) =>
                  orderBy(
                    (opts || [])
                      .filter(p => p.label.toLowerCase().startsWith(term.toLowerCase()))
                      .map(p => ({label: p.label, value: p.value}), 'label'),
                  ) || ([] as ISelectOption[])
                }
              />
            </Stack>
            <Cluster justify='space-between' space='xxlarge' align='center'>
              <div></div>
              <Button type='primary' onClick={() => setIsCloneChannelOpen(true)} permission={permissions.VOD_EDIT}>
                Clone Distribution Details
              </Button>
            </Cluster>
          </Stack>
          <Dialog
            isOpen={isCloneChannelOpen}
            onClose={() => setIsCloneChannelOpen(false)}
            width='45.8125rem'
            height='38.2rem'
          >
            <Template label='header'>
              <Heading level='h2'>Clone Collection</Heading>
            </Template>
            <Template label='body'>
              <Stack space='xxlarge'>
                <Notification type='info'>
                  Cloning this collection includes copying its Active Region, Territories, and Devices.
                </Notification>

                <Stack space='small'>
                  <FormItem {...cloneDetailsForm.name} onBlur={() => cloneDetailsOnBlur('name')}>
                    <TextInput onChange={value => cloneDetailsOnChange('name', value)} id='title' />
                  </FormItem>
                  <FormItem {...cloneDetailsForm.displayName} onBlur={() => cloneDetailsOnBlur('displayName')}>
                    <TextInput onChange={value => cloneDetailsOnChange('displayName', value)} id='displayName' />
                  </FormItem>
                  <FormItem {...cloneDetailsForm?.categories} onBlur={() => cloneDetailsOnBlur('categories')}>
                    <Select
                      searchPlaceholder='Search for main category'
                      onChange={value =>
                        setFieldsForCloneDetails({
                          categories: (value || []).map(v => {
                            return {
                              catId: v.value,
                              order: v.order,
                            };
                          }),
                        })
                      }
                      value={
                        cloneDetailsModel.categories?.map(d => ({
                          label: d.name || '',
                          value: d.catId,
                          order: d.order,
                        })) as ISelectOption[]
                      }
                      id='categories'
                      appendToBody={true}
                      multiselect={true}
                      clearable={true}
                      addAll={true}
                      predicate='value'
                      searchable={true}
                      onSearch={val =>
                        orderBy(
                          (mainCategoriesList || [])
                            .filter(mc => mc.label.toLowerCase().startsWith(val.toLowerCase()))
                            .map(mc => ({label: mc.label, value: mc.value, order: mc.order}), 'label'),
                        ) || []
                      }
                      options={mainCategoriesList}
                    />
                  </FormItem>
                </Stack>
              </Stack>
            </Template>
            <Template label='footer'>
              <Cluster justify='space-between'>
                <div></div>
                <Cluster space='small'>
                  <Button ghost={true} onClick={() => setIsCloneChannelOpen(false)} id='cancelButton'>
                    Cancel
                  </Button>
                  <Button
                    type='primary'
                    state={
                      !cloneDetailsState.isValid || !cloneDetailsState.isDirty
                        ? 'disabled'
                        : isCreating
                        ? 'thinking'
                        : ''
                    }
                    onClick={() => handleCloneCreate()}
                    id='cloneButton'
                  >
                    Clone
                  </Button>
                  <Button
                    type='primary'
                    state={
                      !cloneDetailsState.isValid || !cloneDetailsState.isDirty
                        ? 'disabled'
                        : isCreating
                        ? 'thinking'
                        : ''
                    }
                    onClick={() => handleClonedAndEdit()}
                    id='cloneEditButton'
                  >
                    Clone and Edit
                  </Button>
                </Cluster>
              </Cluster>
            </Template>
          </Dialog>
        </ContentBox>
      </ContentBoxColumn>
      <ContentBoxColumn>
        <ContentBox title='Configuration'>
          <Stack space='small'>
            <Table
              maxHeight='34.0625rem'
              flushTop={true}
              cols={[
                {
                  label: 'Custom Reference Type',
                  transform: row => startCase(row.type),
                },
                {
                  label: 'Partner',
                  transform: row => partnerDictionary[row.value] || row.value,
                },
                ...(ableTo('VOD_EDIT')
                  ? [
                      {
                        label: 'Actions',
                        colWidth: '6.25rem',
                        transform: (row, _col, index) => (
                          <Cluster>
                            <TableActions
                              row={row}
                              icons={[]}
                              deleteOption={true}
                              displayField='name'
                              altTitle='custom reference type'
                              onClick={(row, icon) => handleCustomReferenceClick(icon, index)}
                            >
                              <Popover
                                appendToBody={true}
                                manualTrigger={true}
                                visible={customReferencePopoverOpen[index]}
                                onClickOutside={() => setCustomReferencePopoverOpen({})}
                              >
                                <Template label='trigger'>
                                  <Icon
                                    space='small'
                                    icon='edit'
                                    onClick={() => handleCustomReferenceClick('edit', index)}
                                  />
                                </Template>
                                <Template label='popover'>
                                  <CustomReferenceForm
                                    partners={partners || []}
                                    visible={customReferencePopoverOpen[index]}
                                    onCancel={() => setCustomReferencePopoverOpen({})}
                                    value={row}
                                    onSave={val => handleCustomReferencesUpdate(val, index)}
                                  />
                                </Template>
                              </Popover>
                            </TableActions>
                          </Cluster>
                        ),
                      } as ITableCol<IVodCustomReference>,
                    ]
                  : []),
              ]}
              rows={model.customReferences || []}
            ></Table>
            <Cluster justify='space-between'>
              <div></div>
              {ableTo('VOD_EDIT') && (
                <Popover
                  manualTrigger={true}
                  visible={customReferencePopoverOpen.add}
                  onClickOutside={() => setCustomReferencePopoverOpen({})}
                >
                  <Template label='trigger'>
                    <Button type='primary' onClick={() => setCustomReferencePopoverOpen({add: true})}>
                      + Add
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <CustomReferenceForm
                      partners={partners || []}
                      isNew={true}
                      visible={customReferencePopoverOpen.add}
                      value={{}}
                      onSave={val => handleCustomReferencesUpdate(val)}
                      onCancel={() => setCustomReferencePopoverOpen({})}
                    />
                  </Template>
                </Popover>
              )}
            </Cluster>
          </Stack>
        </ContentBox>
        <ContentBox title='Samsung Intile'>
          <Grid rowGap='small' columnGap='xlarge' minimum='16.25rem' maxCols={2}>
            <FormItem {...form.intileChannel} permission={permissions.VOD_EDIT}>
              <Select
                onChange={value => {
                  const updates: any = {intileChannel: value?.value};
                  if (!value) {
                    updates.intileOrder = 0;
                  }
                  setFields(updates);
                }}
                value={{label: model.intileChannel || '', value: model.intileChannel}}
                id='intileChannel'
                clearable={true}
                predicate='value'
                options={
                  orderBy(
                    (channels?.data || []).map(c => ({label: c.name, value: c.id})),
                    'label',
                  ) || []
                }
              />
            </FormItem>
            <FormItem
              {...form.intileOrder}
              onBlur={() => onBlur('intileOrder')}
              helpText='When the Intile Order is 0, the intile is not displayed.'
              helpTextColor={model.intileChannel ? 'info' : 'disabled'}
              permission={permissions.VOD_EDIT}
              state={model.intileChannel ? 'normal' : 'disabled'}
            >
              <TextInput
                type='number'
                onChange={value => onChange('intileOrder', value)}
                value={model.intileOrder}
                id='intileOrder'
              />
            </FormItem>
          </Grid>
        </ContentBox>
      </ContentBoxColumn>
    </ContentBoxes>
  );
};
