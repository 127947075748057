import * as React from 'react';
import {
  Box,
  ContentBox,
  ContentBoxColumn,
  ContentBoxes,
  FormItem,
  Grid,
  Label,
  Select,
  Stack,
  TextInput,
  Toggle,
} from '@pluto-tv/assemble';
import {useUserRegions} from 'helpers/useUserRegions';
import {INestedMainCategoriesProps} from '../../nestedPropsInterface';
import {useFindQuery as useFindDevices} from 'features/devices/devicesApi';
import AssetForm from 'components/assetForm';

interface IMainCategoriesProps extends INestedMainCategoriesProps {
  dirtyFields: any;
}

export default ({model, onBlur, onChange, form, setFields, canEdit = false}: IMainCategoriesProps): JSX.Element => {
  const {activeRegions} = useUserRegions();

  const {data: deviceList} = useFindDevices({
    offset: 0,
    limit: 250,
    sort: 'name:asc',
  });

  const devices = React.useMemo(
    () => Array.from(new Set((deviceList?.data || []).map(d => ({label: d.name, value: d.platform})))),
    [deviceList?.data],
  );

  const activeRegionValue = React.useMemo(() => {
    const ar = activeRegions.find(ar => ar.code.toLowerCase() === model.activeRegion?.toLowerCase());

    return ar ? `${ar.name} (${ar.code})` : model.activeRegion?.toUpperCase();
  }, [activeRegions, model.activeRegion]);

  const handleDistributionChange = value => {
    setFields({
      distribution: {devices: value?.map(val => val.value) || null, appNames: model.distribution?.appNames || null},
    });
  };

  return (
    <form id='mainCategoryDetailsForm'>
      <ContentBoxes layout='columns'>
        <ContentBoxColumn>
          <ContentBox title='About'>
            <Stack space='medium'>
              <FormItem {...form.name} onBlur={() => onBlur('name')} permission={canEdit ? '' : 'disabled'}>
                <TextInput id='displayName' onChange={value => onChange('name', value)} value={model.name} />
              </FormItem>
              <FormItem {...form.slug} state='disabled'>
                <TextInput id='categorySlug' value={model.slug} />
              </FormItem>
              <FormItem {...form.activeRegion} state='disabled'>
                <TextInput id='activeRegion' value={activeRegionValue} />
              </FormItem>
              <FormItem
                {...form.distribution}
                onBlur={() => onBlur('distribution')}
                permission={canEdit ? '' : 'disabled'}
              >
                <Select
                  value={model.distribution?.devices?.map(d => ({label: d.toString(), value: d}))}
                  id='distributionList'
                  appendToBody={true}
                  predicate='value'
                  addAll={true}
                  multiselect={true}
                  options={devices}
                  searchable={true}
                  clearable={true}
                  onChange={value => handleDistributionChange(value)}
                />
              </FormItem>

              <Grid gap='xxxlarge'>
                <FormItem
                  {...form.plutoOfficeOnly}
                  onBlur={() => onBlur('plutoOfficeOnly')}
                  child='Toggle'
                  permission={canEdit ? '' : 'disabled'}
                >
                  <Toggle
                    id='officeOnly'
                    label='Yes'
                    onChange={value => setFields({plutoOfficeOnly: value})}
                    value={model.plutoOfficeOnly}
                  />
                </FormItem>
                <FormItem
                  {...form.hideMainCategory}
                  child='Toggle'
                  helpText='Hides display of Main Category to enable special filtering of its content by Content Services.'
                  helpTextColor='info'
                  onBlur={() => onBlur('hideMainCategory')}
                  permission={canEdit ? '' : 'disabled'}
                >
                  <Toggle
                    label='Yes'
                    id='hideMainCategory'
                    value={model.hideMainCategory}
                    onChange={value => setFields({hideMainCategory: value})}
                  />
                </FormItem>
              </Grid>
            </Stack>
          </ContentBox>
        </ContentBoxColumn>
        <ContentBoxColumn>
          <ContentBox title='Icons'>
            <Stack space='xxlarge'>
              <Grid rowGap='small' columnGap='xlarge' minimum='16.25rem' maxCols={2}>
                <Stack space='medium'>
                  <Label>{form.pngImageUrl?.label}</Label>
                  <Box width='15rem' height='15rem'>
                    <AssetForm
                      id='pngImage'
                      assetType='iconpng'
                      asset={model.pngImageUrl}
                      contentType='maincategories'
                      contentId={model.id || ''}
                      width='15rem'
                      height='15rem'
                      maxFileSizeKB={2048}
                      minResolutionWidth={120}
                      minResolutionHeight={120}
                      aspectRatioWidth={1}
                      aspectRatioHeight={1}
                      fileTypes={['image/png']}
                      defaultUrl='https://images.pluto.tv/assets/images/default/vodcategory.id-iconPng.png'
                      onChange={(_name: string, url: string) => {
                        setFields({pngImageUrl: {path: url}});
                      }}
                      showAltText={false}
                      permission={canEdit ? '' : 'disabled'}
                    />
                  </Box>
                </Stack>
                <Stack space='medium'>
                  <Label>{form.svgImageUrl?.label}</Label>
                  <Box width='15rem' height='15rem'>
                    <AssetForm
                      id='svgImage'
                      assetType='iconsvg'
                      asset={model.svgImageUrl}
                      contentType='maincategories'
                      contentId={model.id || ''}
                      width='15rem'
                      height='15rem'
                      maxFileSizeKB={2048}
                      minResolutionWidth={120}
                      minResolutionHeight={120}
                      aspectRatioWidth={1}
                      aspectRatioHeight={1}
                      fileTypes={['image/svg+xml']}
                      defaultUrl='https://images.pluto.tv/assets/images/default/vodcategory.id-iconSvg.svg'
                      onChange={(_name: string, url: string) => {
                        setFields({svgImageUrl: {path: url}});
                      }}
                      showAltText={false}
                      permission={canEdit ? '' : 'disabled'}
                    />
                  </Box>
                </Stack>
              </Grid>
            </Stack>
          </ContentBox>
        </ContentBoxColumn>
      </ContentBoxes>
    </form>
  );
};
